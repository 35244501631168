import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BookState, useBookContext } from "../../context/BookContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { urls } from "../../model/constants";

const VerifyPage = () => {
  const navigate = useNavigate();
  const { updateBook, updateMultiBookUids } = useBookContext();
  const [params] = useSearchParams();
  const imp_uid = params.get("imp_uid") || "";
  const merchant_uid = params.get("merchant_uid") || "";
  // const imp_success = (params.get("imp_success") as boolean | null) || false;
  const error_msg = params.get("error_msg") || null;

  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<
    | { type: "verify-error" | "payment-error" | "unknown-error"; error: any }
    | undefined
  >();

  useEffect(() => {
    if (params.size <= 0) navigate("/", { replace: true });
    else if (imp_uid === "") navigate("/", { replace: true });
    else checkPayment();
  }, []);

  useEffect(() => {
    if (error !== undefined) {
      navigate("/reservation/verifyWrong", {
        replace: true,
        state: { type: error.type, error: error.error },
      });
      // navigate("/reservation/verifyWrong", {
      //   replace: true,
      //   state: error,
      // });
    }
  }, [error]);

  const checkPayment = async () => {
    if (error_msg === null) {
      await axios({
        url: urls.payments,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          imp_uid: imp_uid,
          merchant_uid: merchant_uid,
        },
      })
        .then(async (data) => {
          const { status, bookUid } = data.data;

          // 가상계좌 생성 시
          if (status === "vbankIssued") {
            const response = await getDoc(
              doc(firestore, "books-payment-verify", merchant_uid)
            );
            if (response.exists()) {
              const book = response.data() as BookState;

              updateBook(book);

              if (book.type === "single") {
                navigate("../result", { replace: true });
              } else {
                // 다중 예약의 가상계좌 생성인 경우
                if (book.uid.multiId) {
                  const books = await getMultiBooks(book.uid.multiId);
                  updateMultiBookUids([], books);
                  navigate("../resultMulti", { replace: true });
                } else {
                  setError({
                    type: "unknown-error",
                    error: "알 수 없는 오류가 발생했습니다",
                  });
                }
              }
            }
          } else {
            // 결제 성공시
            const response = await getDoc(doc(firestore, "books", bookUid));
            if (response.exists()) {
              // 단일 예약
              const book = response.data() as BookState;
              updateBook(book);
              navigate("../result", { replace: true });
            } else {
              // 다중 예약
              const books = await getMultiBooks(bookUid);
              if (books.length > 0) {
                updateBook(books[0]);
                updateMultiBookUids([], books);
                navigate("../resultMulti", { replace: true });
              } else {
                setError({
                  type: "unknown-error",
                  error: "알 수 없는 오류가 발생했습니다",
                });
              }
            }
          }

          setLoading(false);
        })
        .catch((err: AxiosError) => {
          console.log(err.response);
          setError({
            type: "verify-error",
            error: err.response ? err.response.data : err.message,
          });
          setMessage("검증 실패");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log(error_msg);
      setError({ type: "payment-error", error: error_msg });
      setMessage(error_msg || "결제 실패");
      setLoading(false);
    }
  };

  const getMultiBooks = async (multiId: string) => {
    const qry = query(
      collection(firestore, "books"),
      where("uid.multiId", "==", multiId)
    );

    let books: BookState[] = [];
    const res = await getDocs(qry);
    let results = Array.from(res.docs);
    for (let item of results) {
      if (item.exists()) {
        books.push(item.data() as BookState);
      }
    }

    return books;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
    >
      <Backdrop
        open={loading}
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>결제중입니다...</Typography>
      </Backdrop>
      {message && <Typography sx={{ mt: 2 }}>{message}</Typography>}
    </Box>
  );
};

export default VerifyPage;
