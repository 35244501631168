import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  collection,
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  Timestamp,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { Coupon, MyCoupon } from "../../model/coupon";
import {
  Box,
  Chip,
  Pagination,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { getToday } from "../../components/function";
import { timestampToDayjs } from "../../components/dispatchFunctions";

type CouponForSorting = Coupon & {
  registeredTime: Timestamp;
};

export const CouponListPage = () => {
  const {
    isPC,
    state: { info },
  } = useAuth();

  const [lists, setLists] = useState<CouponForSorting[]>([]); // 조회된 모든 쿠폰목록 누적
  const [list, setList] = useState<CouponForSorting[]>([]); // 현재 페이지에 보이는 쿠폰들

  const [couponCount, setCouponCount] = useState(0);
  const couponsPerPage = 10;
  const [page, setPage] = useState(0);

  // sub-collection에서 마지막으로 읽은 위치의 커서
  const lastIndex = useRef<QueryDocumentSnapshot<DocumentData, DocumentData>>();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getMyCouponCount();
  }, []);

  useEffect(() => {
    if (couponCount > 0) {
      getCoupon(0, 1);
    }
  }, [couponCount]);

  useEffect(() => {
    if (page > 0) {
      setList(
        lists.filter((_, index) => {
          return (
            index >= couponsPerPage * (page - 1) &&
            index <= couponsPerPage * page - 1
          );
        })
      );
    }
  }, [lists, page]);

  // 등록된 총 쿠폰이 몇개인지 확인
  const getMyCouponCount = async () => {
    const collectionRef = collection(
      firestore,
      `user-info/${info.uid}/user-coupon`
    );
    const snapshot = await getCountFromServer(collectionRef);
    setCouponCount(snapshot.data().count);
  };

  // 페이지당 10개씩 가져오되, 이미 가져온 페이지라면 새로 불러오지 않음
  const getCoupon = async (prev: number, next: number) => {
    const nextLimit = next * couponsPerPage;

    // 이미 모든 쿠폰 불러옴. 혹은 이미 해당 page 불러왔음
    if (lists.length === couponCount || lists.length >= nextLimit) {
      setPage(next);
    } else {
      try {
        // 새 쿠폰 불러옴
        const collectionRef = collection(
          firestore,
          `user-info/${info.uid}/user-coupon`
        );

        const diff = (next - prev) * couponsPerPage;

        let myCoupons: MyCoupon[] = [];

        const res = await getDocs(
          lastIndex.current
            ? query(
                collectionRef,
                orderBy("registeredTime", "desc"),
                startAfter(lastIndex.current),
                limit(diff)
              )
            : query(
                collectionRef,
                orderBy("registeredTime", "desc"),
                limit(diff)
              )
        );
        lastIndex.current = res.docs[res.docs.length - 1];

        for (let result of res.docs) {
          if (result.exists()) {
            const data = result.data() as MyCoupon;
            myCoupons.push(data);
          }
        }

        const coupons = await getCoupons(myCoupons);

        let allLists: CouponForSorting[] = cloneDeep(lists);
        allLists.push(...coupons);
        allLists.sort(
          (a, b) => b.registeredTime.seconds - a.registeredTime.seconds
        );
        setLists(allLists);
        setPage(next);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getCoupons = (myCoupons: MyCoupon[]) => {
    return new Promise<CouponForSorting[]>((resolve, reject) => {
      Promise.all(
        myCoupons.map((myCoupon) =>
          getCouponInfo(myCoupon.uid, myCoupon.registeredTime)
        )
      )
        .then((coupons) => {
          resolve(coupons);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCouponInfo = (uid: string, registeredTime: Timestamp) => {
    return new Promise<CouponForSorting>((resolve, reject) => {
      getDoc(doc(firestore, "coupon", uid))
        .then((res) => {
          if (res.exists()) {
            const coupon = res.data() as Coupon;
            resolve({ ...coupon, registeredTime });
          } else {
            const now = Timestamp.now();
            const dummyCoupon: Coupon = {
              uid,
              code: "",
              organization: "",
              description: "",
              amount: 0,
              type: 0,
              publicationDate: now,
              startDate: now,
              endDate: now,
              reusable: 0,
              quantity: 0,
              activation: 0,
              numOfUsed: 0,
              whoUsed: [],
            };
            resolve({ ...dummyCoupon, registeredTime });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const onCopyCode = (text: string) => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography color={"primary"} fontSize={"0.9rem"}>
            * 사용 가능한 쿠폰을 클릭 시 쿠폰코드가 복사됩니다
          </Typography>
        </Box>
        {list.map((coupon) => (
          <CouponPaper
            key={coupon.uid}
            coupon={coupon}
            userUid={info.uid}
            onCopyCode={onCopyCode}
          />
        ))}
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={2}>
        <Pagination
          count={Math.ceil(couponCount / couponsPerPage)}
          color={"primary"}
          siblingCount={isPC ? 1 : 0}
          page={page}
          onChange={(_, value) => {
            const prev = page;
            getCoupon(prev, value);
          }}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="클립보드에 복사됨"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};

const CouponPaper = ({
  userUid,
  coupon,
  onCopyCode,
}: {
  userUid: string;
  coupon: CouponForSorting;
  onCopyCode: (code: string) => void;
}) => {
  const now = getToday();
  let usable: boolean = true;
  let message: string = "사용 가능";

  if (
    coupon.activation === 0 || // 쿠폰이 비활성화
    timestampToDayjs(coupon.startDate).isAfter(now, "day") || // 쿠폰 시작일이 안됨
    timestampToDayjs(coupon.endDate).isBefore(now, "day") || // 쿠폰 종료일이 지남
    coupon.quantity <= coupon.numOfUsed || // 쿠폰 사용횟수 초과
    (coupon.reusable !== 2 && coupon.whoUsed.includes(userUid)) // 쿠폰을 이미 사용함 (관리자용은 동일유저 다시 사용 가능)
  ) {
    usable = false;
  }

  if (coupon.quantity === 0) {
    message = "관리자에 의해 삭제된 쿠폰";
  } else if (coupon.reusable !== 2 && coupon.whoUsed.includes(userUid)) {
    message = "사용 완료";
  } else if (coupon.quantity <= coupon.numOfUsed) {
    message = "쿠폰 사용횟수가 초과됨";
  } else if (coupon.activation === 0) {
    message = "비활성화된 쿠폰";
  } else if (
    timestampToDayjs(coupon.startDate).isAfter(now, "day") ||
    timestampToDayjs(coupon.endDate).isBefore(now, "day")
  ) {
    message = "사용기간이 아님";
  }

  return (
    <Paper
      key={coupon.uid}
      elevation={2}
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: usable ? "primary" : "#ddd",
        justifyContent: "space-between",
        cursor: usable ? "pointer" : "default",
        py: 1,
        px: 2,
      }}
      onClick={() => {
        if (usable) {
          onCopyCode(coupon.code);
        }
      }}
    >
      <Box m={1}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography width={"80px"}>쿠폰코드</Typography>
          <Typography>{coupon.code}</Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography width={"80px"}>발급일자</Typography>
          <Typography fontSize={"0.9rem"}>
            {timestampToDayjs(coupon.registeredTime).format("YYYY년 M월 D일")}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography width={"80px"}>유효기간</Typography>
          <Typography fontSize={"0.9rem"}>{`${timestampToDayjs(
            coupon.startDate
          ).format("YYYY년 M월 D일")} ~ ${timestampToDayjs(
            coupon.endDate
          ).format("YYYY년 M월 D일")}`}</Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-end"}
        gap={1}
      >
        <Chip
          label={message}
          size={"small"}
          color={usable ? "primary" : "default"}
          variant={usable ? "filled" : "outlined"}
        />
        <Box flex={1}>
          <Typography fontSize={"1.1rem"}>
            {coupon.type === 0
              ? `${coupon.amount.toLocaleString()}원`
              : `${coupon.amount}% 할인`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
