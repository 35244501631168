import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography>잘못된 접근입니다.</Typography>
      <Button onClick={() => navigate("/", { replace: true, state: "signin" })}>
        메인으로 돌아가기
      </Button>
    </Container>
  );
};

export default NotFound;
