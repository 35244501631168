import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CollapseMessage } from "../../components/collapseMessage";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [prevPassword, setPrevPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [success, setSuccess] = useState(false);

  const onChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (newPassword.length < 6) {
      setErrorMessage("비밀번호는 6자 이상 입력해주세요.");
      return;
    }

    const auth = getAuth();
    if (auth.currentUser && auth.currentUser.email) {
      const authCredential = EmailAuthProvider.credential(
        auth.currentUser.email,
        prevPassword
      );
      // 로그인이 오래되었을 경우 auth 재인증을 해야 함
      await reauthenticateWithCredential(auth.currentUser, authCredential)
        .then(() => {
          if (auth.currentUser) {
            updatePassword(auth.currentUser, newPassword)
              .then(() => {
                setSuccess(true);
                signOut();
                navigate("/changePasswordDone", { replace: true });
              })
              .catch((error: FirebaseError) => {
                throw error;
              });
          }
        })
        .catch((error: FirebaseError) => {
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case "auth/user-not-found":
                setErrorMessage("등록되지 않은 아이디입니다.");
                break;
              case "auth/wrong-password":
              case "auth/requires-recent-login": // 여기서 auth 재인증이 떴다는건 입력한 기존 비밀번호가 틀렸다는 것
                setErrorMessage("기존 비밀번호가 틀렸습니다.");
                break;
              case "auth/invalid-email":
                setErrorMessage("잘못된 이메일 형식입니다.");
                break;
              case "auth/network-request-failed":
                setErrorMessage("네트워크 연결에 실패 하였습니다.");
                break;
              case "auth/internal-error":
                setErrorMessage("잘못된 요청입니다.");
                break;
              case "auth/too-many-requests":
                setErrorMessage(
                  "비밀번호 변경 요청을 너무 많이 하셨습니다. 잠시 후 다시 시도해주세요."
                );
                break;

              default:
                setErrorMessage(`비밀번호 변경에 실패했습니다: ${error.code}`);
            }
          }
        });
    }
  };

  return (
    <>
      <Box>
        <IconButton
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            fontSize: "1rem",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
          비밀번호 변경
        </IconButton>
      </Box>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography color={"primary"}>
          새로운 비밀번호를 입력해주세요
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} px={1}>
          <TextField
            variant="standard"
            type="password"
            value={prevPassword}
            label={"기존 비밀번호"}
            onChange={(event) => {
              setPrevPassword(event.target.value);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} px={1}>
          <TextField
            variant="standard"
            type="password"
            value={newPassword}
            label={"신규 비밀번호"}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} px={1}>
          <TextField
            variant="standard"
            type="password"
            value={confirmPassword}
            label={"신규 비밀번호 재확인"}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>

      <CollapseMessage
        type="error"
        open={errorMessage !== undefined}
        message={errorMessage || ""}
        onClose={() => setErrorMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={5000}
        open={success}
        onClose={() => setSuccess(false)}
        message="비밀번호 변경이 완료되었습니다. 다시 로그인해주세요."
      />

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => onChange()}
      >
        변경하기
      </Button>
    </>
  );
};

export default ChangePasswordPage;
