import {
  CheckboxProps,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  FieldValues,
  FieldPath,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { DialogAgreeAction } from "./dialog";

export const makeArray = (length: number) => new Array(length).fill(null);

export type LabelValues<T> = {
  label: string;
  isRequired: boolean;
  value: T;
};

export type LabelValue<T> = {
  label: string;
  isRequired: boolean;
  value: T;
  body?: string;
};

type MuiCheckboxProps<T> = {
  items: LabelValue<T>[];
  popupFullScreen?: boolean;
  useSelectAll?: boolean;
  checkboxProps?: CheckboxProps;
};

/**
 *
 * @type TValueType - checkbox의 value type
 * @type TFieldValues - useForm에 연결된 object type
 * @type TName - useForm에 연결된 input의 name
 *
 * @param items - checkbox에 들어갈 정보 list LabelValue<T>[]
 * @param useSelectAll - 전체 선택 기능 사용/미사용. 기본값 false
 * @param checkboxProps - 기타 MUI Checkbox props
 * @param ...props - useController props
 *
 */

export const LabelCheckboxes = <
  TValueType,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  items,
  useSelectAll = false,
  popupFullScreen = true,
  checkboxProps,
  ...props
}: MuiCheckboxProps<TValueType> & UseControllerProps<TFieldValues, TName>) => {
  const {
    field: { onChange },
  } = useController(props);

  const [checked, setChecked] = useState<boolean[]>(
    makeArray(items.length).fill(false)
  );
  const [value, setValue] = useState<(TValueType | null)[]>(
    makeArray(items.length).fill(null)
  );

  const [openDialog, setOpenDialog] = useState(false);
  const dialogContents = useRef<{
    title: string;
    body: string;
    index: number;
    value: TValueType;
  }>({
    title: "",
    body: "",
    index: -1,
    value: -1 as TValueType,
  });

  const onCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;

    // set checked of the checkbox
    setChecked(makeArray(items.length).fill(newState));

    // update value to react-hook-form
    const newValue = newState
      ? items.map((item) => item.value)
      : makeArray(items.length).fill(null);

    setValue(newValue);
    onChange(newValue.filter((item) => item !== null));
  };

  const onChecked = (
    // event: React.ChangeEvent<HTMLInputElement>,
    newState: boolean,
    targetValue: TValueType,
    index: number
  ) => {
    // const newState = event.target.checked;

    // set checked of the checkbox
    const newChecked = [...checked];
    newChecked[index] = newState;
    setChecked(newChecked);

    // update value to react-hook-form
    const newValue = [...value];
    newValue[index] = newState ? targetValue : null;
    setValue(newValue);
    onChange(newValue.filter((item) => item !== null));
  };

  const checkboxes = items.map(
    (item: LabelValue<TValueType>, index: number) => {
      return (
        <FormControlLabel
          key={item.label}
          label={item.label}
          control={
            <Checkbox
              size="small"
              value={item.value}
              checked={checked[index]}
              required={item.isRequired}
              onChange={(event) => {
                if (event.target.checked) {
                  if (item.body) {
                    dialogContents.current = {
                      title: item.label,
                      body: item.body,
                      index: index,
                      value: event.target.value as TValueType,
                    };
                    setOpenDialog(true);
                  } else {
                    onChecked(
                      event.target.checked,
                      event.target.value as TValueType,
                      index
                    );
                  }
                } else {
                  onChecked(
                    event.target.checked,
                    event.target.value as TValueType,
                    index
                  );
                }
                // dialogIndex.current = index
              }}
            />
          }
        />
      );
    }
  );

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={0}>
        {useSelectAll ? (
          <>
            <FormControlLabel
              label="모두 동의합니다"
              control={
                <Checkbox
                  size="small"
                  checked={!checked.includes(false)}
                  indeterminate={
                    checked.includes(true) && checked.includes(false)
                  }
                  onChange={(event) => onCheckedAll(event)}
                />
              }
              sx={{ py: 1, px: 2 }}
            />
            <Divider />
            <Box display="flex" flexDirection={"column"} py={1} px={2}>
              {checkboxes}
            </Box>
          </>
        ) : (
          <>{checkboxes}</>
        )}
      </Box>

      <DialogAgreeAction
        title={dialogContents.current.title}
        open={openDialog}
        fullScreen={popupFullScreen}
        onAgree={() => {
          onChecked(
            true,
            dialogContents.current.value as TValueType,
            dialogContents.current.index
          );
          setOpenDialog(false);
        }}
        onClose={() => {
          onChecked(
            false,
            dialogContents.current.value as TValueType,
            dialogContents.current.index
          );
          setOpenDialog(false);
        }}
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: dialogContents.current.body }}
          sx={{ "& > figure > img": { width: "100%" } }}
        />
      </DialogAgreeAction>
    </>
  );
};

export const LabelCheckboxess = <
  TValueType,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  items,
  useSelectAll = false,
  checkboxProps,
  ...props
}: MuiCheckboxProps<TValueType> & UseControllerProps<TFieldValues, TName>) => {
  const {
    field: { onChange },
  } = useController(props);

  const [checked, setChecked] = useState<boolean[]>(
    makeArray(items.length).fill(false)
  );
  const [value, setValue] = useState<(TValueType | null)[]>(
    makeArray(items.length).fill(null)
  );

  const onCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;

    // set checked of the checkbox
    setChecked(makeArray(items.length).fill(newState));

    // update value to react-hook-form
    const newValue = newState
      ? items.map((item) => item.value)
      : makeArray(items.length).fill(null);

    setValue(newValue);
    onChange(newValue.filter((item) => item !== null));
  };

  const onChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newState = event.target.checked;

    // set checked of the checkbox
    const newChecked = [...checked];
    newChecked[index] = newState;
    setChecked(newChecked);

    // update value to react-hook-form
    const newValue = [...value];
    newValue[index] = newState ? (event.target.value as TValueType) : null;
    setValue(newValue);
    onChange(newValue.filter((item) => item !== null));
  };

  const checkboxes = items.map(
    (item: LabelValue<TValueType>, index: number) => {
      return (
        <FormControlLabel
          key={item.label}
          label={item.label}
          control={
            <Checkbox
              size="small"
              value={item.value}
              checked={checked[index]}
              required={item.isRequired}
              onChange={(event) => {
                onChecked(event, index);
              }}
            />
          }
        />
      );
    }
  );

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={0}>
        {useSelectAll ? (
          <>
            <FormControlLabel
              label="모두 동의합니다"
              control={
                <Checkbox
                  size="small"
                  checked={!checked.includes(false)}
                  indeterminate={
                    checked.includes(true) && checked.includes(false)
                  }
                  onChange={(event) => onCheckedAll(event)}
                />
              }
              sx={{ py: 1, px: 2 }}
            />
            <Divider />
            <Box display="flex" flexDirection={"column"} py={1} px={2}>
              {checkboxes}
            </Box>
          </>
        ) : (
          <>{checkboxes}</>
        )}
      </Box>
    </>
  );
};
