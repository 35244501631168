import { createTheme } from "@mui/material/styles";
import {
  CustomPalette,
  VariantOverrides,
  Variants,
  VariantsOptions,
} from "./customTheme";
import { koKR } from "@mui/x-date-pickers/locales";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides extends VariantOverrides {}
}

declare module "@mui/material/styles" {
  interface Theme extends CustomPalette {}
  interface PaletteOptions extends Partial<CustomPalette> {}

  interface TypographyVariants extends Variants {}
  interface TypographyVariantsOptions extends VariantsOptions {}
}

declare module "@mui/system" {
  interface BreakpointOverrides {
    pc: true;
    mobile: true;
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
  }
}

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#19B7CD",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#80507e",
      },
      error: {
        main: "#B04D3A",
      },
      text: {
        primary: "#2D2D2D",
      },
      // background: {
      //   paper: "#50805200",
      // },
      commonColor: {
        main: "#19B7CD",
        warning: "#e7a65b",
        alert: "#b04d3a",
        alertHover: "#8d3a2b",
        grey: "#939393",
      },
    },

    components: {
      MuiAppBar: {
        defaultProps: {
          color: "default",
          sx: {
            transition: "all ease 0.3s",
          },
        },
      },
    },

    typography: {
      strongTitle: {
        fontSize: "2rem",
        fontWeight: 800,
      },
      title: {
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      text: {
        fontSize: "1.2rem",
      },
      infoText: {
        fontSize: "1.0rem",
      },
    },
    breakpoints: {
      values: {
        pc: 768,
        mobile: 0,
      },
    },
  },

  koKR
);

export default theme;
// primary?: PaletteColorOptions;
// secondary?: PaletteColorOptions;
// error?: PaletteColorOptions;
// warning?: PaletteColorOptions;
// info?: PaletteColorOptions;
// success?: PaletteColorOptions;
// mode?: PaletteMode;
// tonalOffset?: PaletteTonalOffset;
// contrastThreshold?: number;
// common?: Partial<CommonColors>;
// grey?: ColorPartial;
// text?: Partial<TypeText>;
// divider?: string;
// action?: Partial<TypeAction>;
// background?: Partial<TypeBackground>;
