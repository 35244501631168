import {
  Alert,
  AlertColor,
  AlertProps,
  Collapse,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type CollapseMessageProps = AlertProps & {
  open: boolean;
  type: AlertColor;
  message: string;
  onClose: () => void;
};

/**
 *
 * @param open - Collapse is opened when true
 * @param type - Alert type. 'success' | 'info' | 'warning' | 'error'
 * @param message - Alert message
 * @param onClose - callback when the close button has clicked
 * @param props - Alert props
 */
export const CollapseMessage = ({
  open,
  type,
  message,
  onClose,
  ...props
}: CollapseMessageProps) => {
  return (
    <Collapse in={open}>
      <Alert
        severity={type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        {...props}
      >
        {message}
      </Alert>
    </Collapse>
  );
};
