import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BookState, useBookContext } from "../../../context/BookContext";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { CollapseMessage } from "../../../components/collapseMessage";
import { getTimeString } from "../../../components/function";
import { PhoneNumberInput } from "../../../components/phoneNumberInput";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { Loading } from "../../../components/loading";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase";

export const Guardian = () => {
  const navigate = useNavigate();
  const {
    state: { info },
  } = useAuth();
  const {
    book,
    book: { type, nets, guardian },
    multiBookUids,
    updateGuardian,
    updateMultiBookUids,
  } = useBookContext();
  const [name, setName] = useState(
    guardian.name === "" ? info.customer!.name : guardian.name
  );
  const [phone, setPhone] = useState(
    guardian.phone === "" ? info.customer!.phone : guardian.phone
  );
  const [goWith, setGoWith] = useState(
    nets.type === "move" ? true : guardian.goWith
  );
  const [goWithName, setGoWithName] = useState(guardian.goWithName);
  const [goWithPhone, setGoWithPhone] = useState(guardian.goWithPhone);

  const [agreed, setAgreed] = useState(true);
  const [same, setSame] = useState(false);
  const [message, setMessage] = useState<string>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }

    // 다중예약인데 multiId 없으면 오류
    if (type === "multi" && !book.uid.multiId) {
      navigate("/", { replace: true });
    }

    onRenew();
  }, []);

  useEffect(() => {
    onResetThrottle();
    onRenewThrottle();
  }, [name, phone, goWith, agreed]);

  // creationDate 갱신. 2분마다 동작
  const onRenew = async () => {
    try {
      await onRenewBook(type === "single" ? [book.uid.id] : multiBookUids);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setLoading(true);

    try {
      await onRemoveBook(type === "single" ? [book.uid.id] : multiBookUids);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, onResetThrottle, onRenewThrottle } = useResetTimer(
    onRenew,
    onClear
  );

  const onNext = async () => {
    onResetThrottle();
    setMessage(undefined);
    if (nets.type === "move") {
      if (!agreed) {
        setMessage("네츠 무브는 반드시 보호자가 함께 탑승해야 합니다.");
        return;
      }
    }

    if (name === "") {
      setMessage("예약자 성함을 입력해주세요");
      return;
    } else if (phone === "") {
      setMessage("예약자 연락처를 입력해주세요");
      return;
    }

    if (goWith) {
      if (goWithName === "") {
        setMessage("동행인 성함를 입력해주세요");
        return;
      } else if (goWithPhone === "") {
        setMessage("동행인 연락처를 입력해주세요");
        return;
      }
    }

    updateGuardian({
      name,
      phone,
      goWith,
      goWithName,
      goWithPhone,
    });

    if (type === "multi") {
      setLoading(true);
      try {
        const books = await getMultiBooks(book.uid.multiId!);
        updateMultiBookUids(multiBookUids, books);
        setLoading(false);
        navigate("../checkReservationMulti");
      } catch (error) {
        console.log(error);
        setMessage("서버 연결 오류 발생. 다시 시도해주세요");
        setLoading(false);
      }
    } else {
      navigate("../checkReservation");
    }
  };

  const getMultiBooks = async (multiId: string) => {
    const qry = query(
      collection(firestore, "books"),
      where("uid.multiId", "==", multiId)
    );

    let books: BookState[] = [];
    const res = await getDocs(qry);
    let results = Array.from(res.docs);
    for (let item of results) {
      if (item.exists()) {
        books.push(item.data() as BookState);
      }
    }

    return books;
  };

  return (
    <Box p={1}>
      {loading && <Loading open={loading} />}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>예약자 정보 입력</Typography>
        <Typography color={"primary"} ml={1}>
          - 예약자 연락처로 알림톡/해피콜이 발송됩니다.
        </Typography>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>예약자 성함</Typography>
          <TextField
            placeholder="성함"
            variant="standard"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"} mt={2}>
          <Typography>예약자 연락처</Typography>
          <FormControl variant="standard">
            <Input
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              inputComponent={PhoneNumberInput as any}
            />
          </FormControl>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography color={"primary"}>
          보호자 동행 시 탑승은 1인으로 제한됩니다.
        </Typography>
        <Typography color={"primary"} mt={1} sx={{ wordBreak: "keep-all" }}>
          중등도 이상의 질환인 경우, 환자에게 인지장애가 있는 경우에는 보호자
          탑승을 권장하며 보호자 미탑승 시, 이로 인해 발생하는 문제에 대해서는
          책임지지 않습니다.
        </Typography>
        <Typography color={"primary"} mt={1}>
          상담 070-5088-2000
        </Typography>
        {nets.type === "move" && (
          <Typography
            color={"primary"}
            mt={1}
            sx={{ fontWeight: "600", wordBreak: "keep-all" }}
          >
            네츠 무브 서비스를 신쳥하신 경우 보호자 1인이 반드시 함께 탑승해야
            합니다.
          </Typography>
        )}
      </Paper>

      {nets.type === "move" ? (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography>보호자 1인 탑승 필수</Typography>
          <FormControl sx={{ pl: 1, pt: 1 }} error={!agreed}>
            <FormGroup>
              <FormControlLabel
                checked={agreed}
                control={
                  <Checkbox
                    size="small"
                    onChange={(_, checked) => {
                      setAgreed(checked);
                      setMessage(undefined);
                    }}
                  />
                }
                label={"보호자가 함께 탑승함을 확인합니다."}
              />
            </FormGroup>
          </FormControl>
        </Paper>
      ) : (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>보호자 동행 여부</Typography>
            <FormControl sx={{ pl: 1, pt: 1 }}>
              <RadioGroup
                value={goWith}
                onChange={(_, value) => setGoWith(value === "true")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="동행"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="동행하지 않음"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </>
      )}

      {goWith && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Box display={"flex"} gap={4} alignItems={"center"}>
            <Typography>당일 동행 보호자</Typography>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  checked={same}
                  control={
                    <Checkbox
                      size="small"
                      onChange={(_, checked) => {
                        setSame(checked);
                        setGoWithName(checked ? name : "");
                        setGoWithPhone(checked ? phone : "");
                      }}
                    />
                  }
                  label="예약자와 동일"
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box display={"flex"} gap={2} alignItems={"center"} mt={2}>
            <Typography>동행인 성함</Typography>
            <TextField
              placeholder="성함"
              variant="standard"
              value={goWithName}
              onChange={(event) => setGoWithName(event.target.value)}
            />
          </Box>
          <Box display={"flex"} gap={2} alignItems={"center"} mt={2}>
            <Typography>동행인 연락처</Typography>
            <FormControl variant="standard">
              <Input
                value={goWithPhone}
                onChange={(event) => setGoWithPhone(event.target.value)}
                inputComponent={PhoneNumberInput as any}
              />
            </FormControl>
          </Box>
        </Paper>
      )}

      <CollapseMessage
        type="error"
        message={message || ""}
        open={message !== undefined}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={6}
        nextButton={
          <Button size="small" onClick={() => onNext()}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => navigate("../patient", { replace: true })}
          >
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
