import axios from "axios";
import { urls } from "../model/constants";

export const onPaymentCancel = async (
  imp_uid: string,
  cancel_request_amount: number
) => {
  return new Promise<string>((resolve, reject) => {
    axios({
      url: urls.onPaymentCancel,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        imp_uid,
        cancel_request_amount,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          if (data.status !== "success") throw "error";

          resolve(data.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
