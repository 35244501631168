import { Box, Button, Divider, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Loading } from "../../components/loading";
import { Input } from "../../components/muiInput";
import { CollapseMessage } from "../../components/collapseMessage";
import { signInWithEmailAndPassword } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { ShowRegionDialog } from "../../components/dialog";

type SignInInfo = {
  id: string;
  password: string;
};

export const SignIn = () => {
  const navigate = useNavigate();
  const {
    state: { state, auth },
    signOut: signOut,
  } = useAuth();
  const [message, setMessage] = useState<string>();
  const [showRegion, setShowRegion] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInInfo>({
    defaultValues: {
      id: "",
      password: "",
    },
  });

  useEffect(() => {
    if (state === "permission-denied") {
      setMessage("고객 아이디가 아닙니다.");
      signOut();
    } else if (state === "signed-in") {
      navigate("/", { replace: true, state: auth!.uid });
      // if (auth !== undefined && auth !== null) {
      //   console.log("11");
      // }
    }
  }, [state]);

  const onSubmit = async ({ id, password }: SignInInfo) => {
    await signInWithEmailAndPassword(id, password)
      .then((result) => {
        // navigate("/", { replace: true, state: result.user.uid });
        // if (!result.user.emailVerified)
        //   navigate("verify", { replace: true, state: result.user.uid });
        // else {
        //   navigate("/", { replace: true, state: result.user.uid });
        // }
      })
      .catch((error) => {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case "auth/user-not-found":
              setMessage("등록되지 않은 아이디입니다");
              break;
            case "auth/wrong-password":
              setMessage("비밀번호가 틀렸습니다");
              break;

            case "auth/internal-error":
              setMessage("서버에 오류가 있습니다. 잠시 후 다시 시도해주세요");
              break;
            case "auth/too-many-requests":
              setMessage(
                "로그인 시도를 너무 많이 하셨습니다. 잠시 후 다시 시도해주세요"
              );
              break;
            case "auth/user-disabled":
              setMessage("탈퇴한 계정입니다");
              break;

            default:
              setMessage(`로그인에 실패했습니다: ${error.code}`);
          }
        } else setMessage(`로그인에 실패했습니다: ${(error as any).message}`);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading open={isSubmitting} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={"/images/header_logo.png"} alt="logo" />
          <Typography variant="infoText" sx={{ mt: 2 }}>
            더욱 안전하게 병원가는 방법
          </Typography>
          <Typography variant="infoText" sx={{ mb: 1 }}>
            네츠모빌리티
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "20rem",
            "& > hr": { width: "30rem" },
          }}
        >
          <Input
            name="id"
            control={control}
            textFieldProps={{
              label: "아이디 (이메일) *",
              fullWidth: true,
              placeholder: "netsmobility@gmail.com",
            }}
            rules={{
              required: { value: true, message: "이메일 주소를 입력해주세요." },
              pattern: {
                value:
                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                message: "이메일 주소를 입력해주세요.",
              },
            }}
          />
          <Input
            name="password"
            control={control}
            textFieldProps={{
              label: "비밀번호 *",
              type: "password",
              fullWidth: true,
              autoComplete: "",
            }}
            rules={{
              required: { value: true, message: "비밀번호를 입력해주세요" },
              minLength: {
                value: 6,
                message: "비밀번호를 입력해주세요",
              },
            }}
          />
          <CollapseMessage
            open={message !== undefined}
            type={"warning"}
            message={message || ""}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 2, width: "20rem" }}
          />
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            disabled={isSubmitting}
          >
            로그인
          </Button>
          <Box
            sx={{
              width: "100%",
              textAlign: "end",
              "& > span > a": {
                textDecoration: "none",
                color: "commonColor.main",
                ":visited": {
                  color: "commonColor.main",
                },
                ":hover": {
                  fontWeight: "600",
                },
              },
            }}
          >
            <Typography variant="infoText" color={"primary"}>
              <Link to="forgot">비밀번호 찾기</Link>
            </Typography>
          </Box>
        </Box>

        <Divider
          sx={{
            border: "none",
            borderTop: "1px solid gray",
            borderColor: "primary.main",
            textAlign: "center",
            overflow: "visible",
            mt: 5,
            mb: 2,
            "&::after": {
              content: `"아직 회원이 아니신가요?"`,
              position: "relative",
              top: "-0.7rem",
              backgroundColor: "white",
              padding: "0 1rem",
              color: "primary.main",
            },
          }}
        />

        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={() => {
            navigate("signup");
          }}
          disabled={isSubmitting}
        >
          회원 가입하기
        </Button>

        <Box width={"100%"} textAlign={"end"} mt={1}>
          <Typography
            color={"primary"}
            sx={{
              cursor: "pointer",
              ":hover": {
                fontWeight: "600",
              },
            }}
            onClick={() => setShowRegion(true)}
          >
            서비스 가능 지역 확인하기
          </Typography>
        </Box>

        <Box width={"100%"} textAlign={"center"} mt={3}>
          <Typography>⁕ 병원 이동시에만 이용 가능합니다</Typography>
        </Box>
      </form>
      <ShowRegionDialog showRegion={showRegion} setShowRegion={setShowRegion} />
    </Box>
  );
};
