import dayjs, { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";

export const dayjsToTimestamp = (time: Dayjs) =>
  Timestamp.fromDate(time.toDate());

export const timestampToDayjs = (time: Timestamp) => {
  try {
    return dayjs(time.toDate());
  } catch (e) {
    return dayjs(Timestamp.fromMillis(time.seconds * 1000).toDate());
  }
};
