import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { createUserWithEmailAndPassword, firestore } from "../../firebase";
import { LabelValue, LabelCheckboxes } from "../../components/checkboxes";
import { CollapseMessage } from "../../components/collapseMessage";
import { Loading } from "../../components/loading";
import { Input, InputPhoneNumber } from "../../components/muiInput";

import {
  Timestamp,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { BoardInfo } from "../../context/BoardContext";
import { FirebaseError } from "firebase/app";
import { UserInfo } from "../../model/userTypes";

type SignUpInfo = {
  id: string;
  password: string;
  password_confirm: string;
  name: string;
  phone: string;
  agrees: LabelValue<number>[];
};

export const SignUp = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [termsOfUse, setTermsOfUse] = useState<string>("");
  const [privatePolicy, setPrivatePolicy] = useState<string>("");

  useEffect(() => {
    getTermsOfUse();
    getPrivatePolicy();
  }, []);

  const getTermsOfUse = async () => {
    const res = await getDoc(doc(firestore, "boards-notice", "termsOfUse"));
    if (res.exists()) {
      const data = res.data() as BoardInfo;
      setTermsOfUse(data.description);
    }
  };

  const getPrivatePolicy = async () => {
    const res = await getDoc(doc(firestore, "boards-notice", "privatePolicy"));
    if (res.exists()) {
      const data = res.data() as BoardInfo;
      setPrivatePolicy(data.description);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<SignUpInfo>({
    defaultValues: {
      id: "",
      password: "",
      password_confirm: "",
      name: "",
      phone: "",
      agrees: [],
    },
  });

  const onSubmit = async (info: SignUpInfo) => {
    if (watch("agrees").length !== 2) {
      setMessage("모든 약관에 동의해주세요");
      return;
    }

    setMessage(undefined);
    setLoading(true);
    // create user
    await createUserWithEmailAndPassword(info.id, info.password)
      .then(async (res) => {
        const user = res.user;
        const customerRef = doc(firestore, "user-info", user.uid);

        const customer: UserInfo = {
          uid: user.uid,
          type: "customer",
          regDate: Timestamp.now(),
          customer: {
            uid: user.uid,
            name: info.name,
            email: user.email || "",
            phone: info.phone,
            point: 0,
            pointGets: 0,
            pointUsed: 0,
            cashReceipts: "",
          },
        };
        // upload to firestore
        await setDoc(customerRef, { ...customer, regDate: serverTimestamp() });
        // await runTransaction(firestore, async (transaction) => {
        //   transaction.set(customerRef, customer);
        // });

        navigate("../signupDone", { replace: true });
        // verifyEmail()
        // .then((_) => {
        //   navigate("../verify", { replace: true, state: info.id });
        // })
        // .catch((error) => {
        //   throw Error(error);
        // });
      })
      .catch((error) => {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case "auth/email-already-in-use":
              setMessage("이미 등록된 아이디입니다");
              break;
            case "auth/invalid-password":
              setMessage("비밀번호가 너무 짧습니다");
              break;
            case "auth/invalid-email":
              setMessage("유효한 이메일 형식이 아닙니다");
              break;
            case "auth/user-disabled":
              setMessage("탈퇴한 계정입니다");
              break;
            case "auth/too-many-requests":
              setMessage(
                "회원가입 시도를 너무 많이 하셨습니다. 잠시 후 다시 시도해주세요"
              );
              break;
            case "auth/internal-error":
              setMessage("서버에 오류가 있습니다. 잠시 후 다시 시도해주세요");
              break;
            case "auth/timeout":
              setMessage(
                "인터넷 연결에 문제가 있습니다. 확인 후 다시 시도해주세요"
              );
              break;

            default:
              setMessage(`회원가입에 실패했습니다: ${error.code}`);
          }
        } else setMessage(`회원가입에 실패했습니다: ${(error as any).message}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loading open={loading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          // scrollMarginTop: 75,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "20rem",
          }}
        >
          <Box width={"100%"} display={"flex"} justifyContent={"center"} mb={2}>
            <img src={"/images/header_logo.png"} alt="logo" />
          </Box>
          <Typography
            color={"primary"}
            fontSize={"1.5rem"}
            fontWeight={"600"}
            sx={{ textAlign: "center" }}
          >
            회원가입
          </Typography>
          <Input
            name="id"
            control={control}
            textFieldProps={{
              label: "아이디 (이메일) *",
              fullWidth: true,
              placeholder: "netsmobility@gmail.com",
            }}
            rules={{
              required: { value: true, message: "이메일 주소를 입력해주세요." },
              pattern: {
                value:
                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                message: "이메일 주소를 입력해주세요.",
              },
            }}
          />
          <Input
            name="password"
            control={control}
            textFieldProps={{
              label: "비밀번호 (6자리 이상) *",
              type: "password",
              // required: true,
              fullWidth: true,
              autoComplete: "",
            }}
            rules={{
              required: {
                value: true,
                message: "비밀번호는 6글자 이상 입력해주세요.",
              },
              minLength: {
                value: 6,
                message: "비밀번호는 6글자 이상 입력해주세요",
              },
            }}
          />
          <Input
            name="password_confirm"
            control={control}
            textFieldProps={{
              label: "비밀번호 확인 *",
              type: "password",
              // required: true,
              fullWidth: true,
              autoComplete: "",
            }}
            rules={{
              required: {
                value: true,
                message: "동일한 비밀번호를 입력해주세요.",
              },
              validate: (value: string) => {
                if (watch("password") !== value) {
                  return "동일한 비밀번호를 입력해주세요.";
                }
              },
            }}
          />

          <Input
            name="name"
            control={control}
            textFieldProps={{
              label: "고객 성함 *",
              fullWidth: true,
              autoComplete: "",
            }}
            rules={{
              required: {
                value: true,
                message: "성함을 입력해주세요.",
              },
            }}
          />

          <InputPhoneNumber
            name="phone"
            control={control}
            textFieldProps={{
              label: "고객 연락처 (휴대전화) *",
              fullWidth: true,
              autoComplete: "",
            }}
            rules={{
              required: {
                value: true,
                message: "휴대폰 번호를 입력해주세요.",
              },
              minLength: {
                value: 10,
                message: "휴대폰 번호를 입력해주세요.",
              },
            }}
          />

          <Box border={"1px solid rgba(0, 0, 0, 0.23)"} borderRadius={1} mt={2}>
            <LabelCheckboxes
              items={[
                {
                  label: "서비스 이용약관 동의 (필수)",
                  isRequired: false,
                  value: 1,
                  body: termsOfUse,
                },
                {
                  label: "개인정보 수집 및 이용 동의 (필수)",
                  isRequired: false,
                  value: 2,
                  body: privatePolicy,
                },
              ]}
              useSelectAll
              name="agrees"
              control={control}
            />
          </Box>

          <CollapseMessage
            open={message !== undefined}
            type={"warning"}
            message={message || ""}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 2 }}
          />

          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ my: 2 }}
            disabled={isSubmitting}
          >
            가입신청
          </Button>
        </Box>
      </Box>
    </form>
  );
};
