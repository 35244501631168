import {
  Timestamp,
  doc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  Box,
  TextField,
  Button,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextEditor } from "../../components/ckeditor5";
import { BoardInfo, useBoardContext } from "../../context/BoardContext";
import { useAuth } from "../../context/AuthContext";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { FirebaseError } from "firebase/app";
import { CollapseMessage } from "../../components/collapseMessage";
import { DialogYesNoAction } from "../../components/dialog";

export const VocWritePage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const {
    board: { lists, index },
  } = useBoardContext();
  const [title, setTitle] = useState(index === -1 ? "" : lists[index].title);
  const [description, setDescription] = useState(
    index === -1 ? "" : lists[index].description
  );
  const [writer] = useState(
    index === -1 ? info.customer!.name : lists[index].writer
  );
  const [date] = useState(index === -1 ? Timestamp.now() : lists[index].date);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    if (title.length <= 0) {
      setMessage("제목을 적어주세요.");
      setLoading(false);
      return;
    }

    if (description.length <= 0) {
      setMessage("내용을 작성해주세요.");
      setLoading(false);
      return;
    }

    const isNew: boolean = index === -1;
    const key = isNew
      ? timestampToDayjs(date).format("YYYYMMDDHHmmss")
      : lists[index].key;

    // 최초 생성될 때만 비공개 처리. 이후에 게시글이 수정되어도 비공개로 전환하지 않음
    let newItem: BoardInfo = isNew
      ? {
          key,
          writer: info.customer!.name,
          writerUid: info.uid,
          title,
          description,
          date,
          visible: "비공개",
        }
      : { ...lists[index], title, description, date };

    await runTransaction(firestore, async (transaction) => {
      // 최초 생성되었을 때 관리자에게 notify
      if (isNew) {
        transaction.set(doc(firestore, "voc-waiting", key), {
          key,
          writer: info.customer!.name,
          date: serverTimestamp(),
        });
      }

      transaction.set(doc(firestore, "boards-voc", key), {
        ...newItem,
        date: serverTimestamp(),
      });
    })
      .then(() => {
        setLoading(false);
        navigate("/board", { replace: true });
      })
      .catch((error: FirebaseError) => {
        console.log(error);
        setMessage(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Paper elevation={2}>
        {isPC ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 2,
              px: 2,
              gap: 2,
            }}
          >
            <TextField
              label={"제목"}
              value={title}
              onChange={(event) => setTitle(event.currentTarget.value)}
              sx={{ flex: 1 }}
            />

            <Box
              display={"flex"}
              gap={2}
              sx={{ "& > p": { fontSize: "0.9rem" } }}
            >
              <Typography>{writer}</Typography>
              <Typography>|</Typography>
              <Typography>
                {timestampToDayjs(date).format("YYYY.MM.DD")}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              py: 2,
              px: 2,
            }}
          >
            <TextField
              label={"제목"}
              fullWidth
              value={title}
              onChange={(event) => setTitle(event.currentTarget.value)}
            />

            <Box
              display={"flex"}
              gap={2}
              sx={{ "& > p": { fontSize: "0.9rem" } }}
            >
              <Typography>{writer}</Typography>
              <Typography>|</Typography>
              <Typography>
                {timestampToDayjs(date).format("YYYY.MM.DD")}
              </Typography>
            </Box>
          </Box>
        )}
        <Divider sx={{ mx: 1 }} />
        <Box px={2} py={2}>
          <TextEditor
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </Box>
      </Paper>

      <CollapseMessage
        type="info"
        open={message !== undefined}
        message={message || ""}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 2, mb: 1 }}
      />

      <DialogYesNoAction
        title={"게시글 작성"}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={onSubmit}
      >
        {index === -1 ? (
          <>
            <Typography>작성하신 글을 게시하시겠습니까?</Typography>
            <Typography>
              최초 등록시에는 관리자의 승인 후 공개됩니다.
            </Typography>
          </>
        ) : (
          <Typography>게시글을 수정하시겠습니까?</Typography>
        )}
      </DialogYesNoAction>

      <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
        <Button
          variant="outlined"
          disabled={loading}
          onClick={() => navigate(-1)}
        >
          작성 취소
        </Button>

        <Button
          variant="contained"
          disabled={loading}
          onClick={() => setOpenDialog(true)}
        >
          게시하기
        </Button>
      </Box>
    </>
  );
};
