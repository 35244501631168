import { Box, Fab, Tabs, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Header } from "./header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "./footer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useAuth } from "../context/AuthContext";
import { BoardProvider } from "../context/BoardContext";
import { usePreventRefresh } from "../hooks/usePreventRefresh";

export const MainContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isPC,
    state: { state },
  } = useAuth();

  const ref = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const [fabRight, setFabRight] = useState("0px");
  const [fabBotttom, setFabBottom] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // useEffect(() => {
  //   if (location.pathname !== "/" && state === "signed-out") {
  //     navigate("/", { replace: true });
  //   }
  // }, [location.pathname, state]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current && isPC) {
        const w = ref.current.offsetWidth;
        const windowWidth = window.innerWidth;
        setFabPosition(w, windowWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    const w = ref.current?.offsetWidth || 960;
    const windowWidth = window.innerWidth;
    setFabPosition(w, windowWidth);

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  const setFabPosition = (w: number, windowWidth: number) => {
    if (windowWidth >= 1024) {
      setFabRight(`calc(50% - ${w / 2}px + 144px)`);
    } else if (w >= 691) {
      setFabRight(`calc(50% - ${w / 2}px)`);
    } else {
      setFabRight("24px");
    }
  };

  const scrollCallback = ([footer]: IntersectionObserverEntry[]) => {
    if (footer.isIntersecting) {
      setFabBottom(footer.boundingClientRect.height);
    } else {
      setFabBottom(0);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(scrollCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });
    if (footerRef.current) observer.observe(footerRef.current);

    return () => {
      if (footerRef.current) observer.unobserve(footerRef.current);
    };
  }, [footerRef]);

  return (
    <>
      <Box
        ref={ref}
        // minWidth={isPC ? "691px" : "90%"}
        // width={isPC ? "60%" : "90%"}
        // maxWidth={isPC ? "960px" : "690px"}
        display={"flex"}
        flexDirection={"column"}
        m={"0 auto"}
        pt={1}
      >
        <Header />
        {/* {location.pathname.includes("/infos") && <InfoMenus />} */}
        <Box
          minWidth={isPC ? "691px" : "90%"}
          width={isPC ? "60%" : "90%"}
          maxWidth={isPC ? "960px" : "690px"}
          display={"flex"}
          flexDirection={"column"}
          m={"0 auto"}
          mt={1}
          sx={{ minHeight: "100vh" }}
        >
          <Outlet />
        </Box>
      </Box>
      {!location.pathname.includes("/reservation") &&
        !location.pathname.includes("/auth") &&
        ref.current && (
          <Fab
            variant="extended"
            color="primary"
            sx={{
              position: "fixed",
              right: isPC ? fabRight : 24,
              bottom: `${(isPC ? 48 : 24) + fabBotttom}px`,
              m: 0,
            }}
            onClick={() =>
              navigate(state === "signed-in" ? "/reservation" : "/auth")
            }
          >
            <DirectionsCarIcon sx={{ mr: 1 }} />
            예약하기
          </Fab>
        )}
      {!location.pathname.includes("/reservation") && (
        <Footer ref={footerRef} />
      )}
    </>
  );
};

export const BookContainer = () => {
  usePreventRefresh();

  return <Outlet />;
  // return (
  //   <BookProvider>
  //     <Outlet />
  //   </BookProvider>
  // );
};

export const InfoContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState("price");

  useEffect(() => {
    const path = location.pathname.split("/");
    setMenu(path[2]);
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setMenu(newValue);
    navigate(`/infos/${newValue}`);
  };

  return (
    <>
      <Box my={3} ml={2}>
        <Tabs value={menu} onChange={handleChange}>
          <Tab label="요금 안내" value="price" />
          <Tab label="탑승 정책" value="terms" />
          <Tab label="약관 및 정책" value="notice" />
        </Tabs>
      </Box>
      <Outlet />
    </>
  );
};

export const InfoNoticeContainer = () => {
  return (
    <BoardProvider>
      <Outlet />
    </BoardProvider>
  );
};

export const VocContainer = () => {
  return (
    <>
      <Box my={3} ml={2}>
        <Tabs value={0}>
          <Tab label="고객의 소리" />
        </Tabs>
      </Box>

      <BoardProvider>
        <Outlet />
      </BoardProvider>
    </>
  );
};
