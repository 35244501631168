import React from "react";
import { IMaskInput } from "react-imask";

interface OnChangeProps {
  onChange: (event: { target: { value: string } }) => void;
}

export const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  OnChangeProps
>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[{ mask: "000-000-0000" }, { mask: "000-0000-0000" }]}
      inputRef={ref}
      onAccept={(_, mask) =>
        onChange({ target: { value: mask.unmaskedValue } })
      }
      placeholder="010-1234-5678"
      overwrite
    />
  );
});

export const getPhoneNumberFormat = (phoneNumber: string) => {
  // 공백 제거
  if (
    phoneNumber === undefined ||
    phoneNumber === null ||
    phoneNumber.length <= 0
  )
    return phoneNumber;

  const phone = phoneNumber.replace(" ", "");

  // 모르는 번호타입
  if (phone.length < 9) {
    return phone;
    // 02-000-0000
  } else if (phone.length === 9) {
    return `${phone.substring(0, 2)}-${phone.substring(2, 5)}-${phone.substring(
      5
    )}`;
    // 010-000-0000
  } else if (phone.length === 10) {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(
      6
    )}`;
    // 010-0000-0000, 기타 모르는 번호타입
  } else {
    return `${phone.substring(0, 3)}-${phone.substring(3, 7)}-${phone.substring(
      7
    )}`;
  }
};
