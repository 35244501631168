// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword as createUser,
  signInWithEmailAndPassword as signIn,
  signInWithPopup as signInPopup,
  AuthProvider,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { firebaseConfig } from "./model/constants";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

/* auth */
const app = initializeApp(firebaseConfig);
export const authService = getAuth(app);

export const createUserWithEmailAndPassword = (
  email: string,
  password: string
) => createUser(authService, email, password);

export const signInWithEmailAndPassword = (email: string, password: string) =>
  signIn(authService, email, password);

export const signInWithPopup = (provider: AuthProvider) =>
  signInPopup(authService, provider);

export const verifyEmail = () =>
  sendEmailVerification(authService.currentUser!);

export const firebaseSignOut = () => signOut(authService);

/* firestore */
export const firestore = getFirestore(app);

/**
 *
 * @param collectionPath - document를 추가할 collection name. if not exists the collection, create it.
 * @param document - collection에 저장할 document object. document key는 자동 생성됨.
 *
 * @remarks create or update the document
 */
export const post = async (collectionPath: string, document: object) => {
  return await addDoc(collection(firestore, collectionPath), document);
};

/**
 *
 * @param collectionPath - document를 추가할 collection name. if not exists the collection, create it.
 * @param documentId - collection에 저장할 document key
 * @param document - collection에 저장할 document object
 *
 * @remarks create or update the document
 */
export const postWithId = async (
  collectionPath: string,
  documentId: string,
  document: object
) => {
  return await setDoc(doc(firestore, collectionPath, documentId), document);
};

/**
 *
 * @param collectionPath - document를 추가할 collection name.
 * @param documentId - collection에 저장할 document key
 * @param document - collection에 저장할 document object
 *
 * @remarks update the document. if the document is not exists, discard it.
 */
export const update = async (
  collectionPath: string,
  documentId: string,
  document: object
) => {
  return await updateDoc(doc(firestore, collectionPath, documentId), document);
};

/**
 *
 * @param collectionPath - document를 가져올 collection name.
 * @param documentId - collection에서 가져올 document key
 *
 * @remarks update the document. if the document is not exists, discard it.
 */
export const get = async (collectionPath: string, documentId: string) => {
  return await getDoc(doc(firestore, collectionPath, documentId));
};

// const analytics = getAnalytics(app);

/*****************************/
export const storage = getStorage(app);
