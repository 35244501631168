import axios from "axios";
import { urls } from "../model/constants";

export const onChangeUserInfo = async (
  uid: string,
  method: "password" | "leave" = "leave"
) => {
  return new Promise<void>((resolve, reject) => {
    axios({
      url: urls.onChangeUserInfo,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid,
        type: "customer",
        method,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          if (data.status !== "success") throw "error";

          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
