import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  BookState,
  PaymentType,
  netsTypes,
  useBookContext,
} from "../../../context/BookContext";
import { useMediaQuery } from "react-responsive";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { CollapseMessage } from "../../../components/collapseMessage";
import dayjs from "dayjs";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { timestampToDayjs } from "../../../components/dispatchFunctions";
import { cloneDeep } from "lodash";
import { Coupon, CouponWithRef } from "../../../model/coupon";
import { DialogYesNoAction } from "../../../components/dialog";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { getTimeString, getToday } from "../../../components/function";
import { Loading } from "../../../components/loading";
import { ExpandMore } from "@mui/icons-material";
import { PG_CARD, PG_VBANK, redirectUrl } from "../../../model/constants";

declare global {
  interface Window {
    IMP: any;
  }
}

export const CheckReservationMulti = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const {
    state: { info },
  } = useAuth();

  const {
    testMode,
    book,
    book: { nets, bookDrive, bookManager, companion, guardian, paymentInfo },
    multiBookUids,
    multiBooks,
    updateBook,
    updatePaymentInfo,
    updateBookManager,
  } = useBookContext();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [totalFee, setTotalFee] = useState(0);
  const [fee, setFee] = useState(0);
  const [couponCode, setCouponCode] = useState(paymentInfo.discount.couponCode);
  const [couponDescription, setCouponDescription] = useState(
    paymentInfo.discount.couponDescription
  );

  const [coupon, setCoupon] = useState(paymentInfo.discount.coupon);
  const couponRef = useRef<CouponWithRef>();
  const [couponMessage, setCouponMessage] = useState("");
  const [point, setPoint] = useState(paymentInfo.discount.point);
  const [myPoint, setMyPoint] = useState(info.customer?.point || 0);
  const [paymentType, setPaymentType] = useState(paymentInfo.paymentType);
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [availableLists, setAvailableLists] = useState<BookState[]>([]);
  const success = true;

  const pgTimerId = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }

    if (multiBooks.length <= 0) {
      navigate("/", { replace: true });
    }

    onRenew();

    return () => {
      if (pgTimerId.current) clearInterval(pgTimerId.current);
    };
  }, []);

  useEffect(() => {
    onResetThrottle();
    onRenewThrottle();
  }, [point, coupon, paymentType]);

  // PG 팝업이 떠있는 동안은 예약이 삭제되지 않도록 creationDate 시간 계속 갱신
  const startPgTimer = () => {
    // 기존 3분 timer 정지
    stopTimer();

    if (pgTimerId.current) clearInterval(pgTimerId.current);
    pgTimerId.current = setInterval(() => {
      onRenewThrottle();
    }, 1000);
  };

  // 결제 취소로 인해 PG 팝업이 닫힘
  const stopPgTimer = () => {
    // pg timer 정지
    if (pgTimerId.current) clearInterval(pgTimerId.current);
    // 3분 타이머 다시 시작
    resetTimer();
  };

  // creationDate 갱신. 2분마다 동작
  const onRenew = async () => {
    try {
      await onRenewBook(multiBookUids);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setClearLoading(true);

    try {
      await onRemoveBook(multiBookUids);
    } catch (error) {
      console.log(error);
    }

    setClearLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, stopTimer, resetTimer, onResetThrottle, onRenewThrottle } =
    useResetTimer(onRenew, onClear);

  useEffect(() => {
    if (multiBooks.length > 0) {
      let lists: BookState[] = [];
      let sumOfTotalFee = 0;

      for (let book of multiBooks) {
        lists.push(book);
        sumOfTotalFee += book.paymentInfo.totalFee;
      }

      lists.sort(
        (a, b) =>
          a.bookDrive.addrA.arrival.seconds - b.bookDrive.addrA.arrival.seconds
      );

      setAvailableLists(lists);
      setTotalFee(sumOfTotalFee);
      setFee(sumOfTotalFee);
    }
  }, [multiBooks]);

  const onStore = async () => {
    setOpenDialog(false);

    if (fee > 0 && fee < 100) {
      setAlertMessage(
        "PG결제 최소 금액제한으로 100원 미만은 결제하실 수 없습니다."
      );
      return;
    }

    setLoading(true);

    // 전체 결제금액 및 쿠폰, 포인트 사용정보 저장
    const bookForVerify = cloneDeep(book);

    // PG 팝업이 떠있는 동안은 예약이 삭제되지 않도록 creationDate 시간 계속 갱신
    startPgTimer();

    const merchant_uid = `nets_${dayjs()
      .locale("en")
      .format("YYMMDD_HHmmss_SSS")}`;
    const imp_uid = `imp_${dayjs().locale("en").format("YYMMDDHHmmssSSS")}`;

    if (fee === 0) {
      bookForVerify.paymentInfo.imp_uid = imp_uid;
    }
    bookForVerify.paymentInfo.merchant_uid = merchant_uid;
    bookForVerify.paymentInfo.fee = fee;

    let pp = Number(point);

    bookForVerify.paymentInfo.paymentType = paymentType;
    bookForVerify.paymentInfo.discount = couponRef.current
      ? {
          couponCode,
          couponDescription,
          couponRef: couponRef.current.docRef,
          coupon,
          point: pp,
        }
      : {
          couponCode,
          couponDescription,
          coupon,
          point: pp,
        };
    bookForVerify.paymentInfo.bookState = "waiting";
    bookForVerify.paymentInfo.isPaid = fee === 0 ? "ok" : "waiting";

    await setDoc(
      doc(firestore, "books-payment-verify", merchant_uid),
      bookForVerify
    );

    if (fee === 0) {
      let param: {
        imp_uid: string;
        merchant_uid: string;
        imp_success: string;
        error_code?: string;
        error_msg?: string;
      } = {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid,
        imp_success: "true",
      };

      navigate(
        { pathname: "../verify", search: `?${createSearchParams(param)}` },
        { replace: true }
      );
    } else {
      requestPay(merchant_uid);
    }
  };

  const requestPay = (merchant_uid: string) => {
    const due = getToday().add(1, "hour").format("YYYYMMDDHHmmss");

    let data: {} = {
      merchant_uid: merchant_uid, // 주문번호
      name: netsTypes[nets.type].label, // 주문명
      // amount: 100, // 결제금액
      amount: fee, // 결제금액
      buyer_email: "", // 구매자 이메일
      buyer_name: guardian.name, // 구매자 이름
      buyer_tel: guardian.phone, // 구매자 전화번호
      buyer_addr: "",
      buyer_postcode: "",
      m_redirect_url: redirectUrl,
    };

    if (paymentType === "account") {
      data = {
        ...data,
        // pg: "nice_v2.iamport03m", // PG사
        pg: PG_VBANK, // PG사
        pay_method: "vbank", // 결제수단
        vbank_due: due,
      };
    } else {
      data = {
        ...data,
        pg: `${
          paymentType === "kakaopay" ? "kakaopay.TC0ONETIME" : PG_CARD
          // "nice_v2.iamport00m"
        }`, // PG사
        pay_method: `${paymentType === "kakaopay" ? "card" : paymentType}`, // 결제수단
      };
    }

    // console.log(data);

    const { IMP }: any = window;
    IMP.init(process.env.REACT_APP_IMP_CODE);
    IMP.request_pay(data, onPaymentCallback);
  };

  const onPaymentCallback = async (response: any) => {
    stopPgTimer();

    if (response.error_msg === undefined) {
      let param: {
        imp_uid: string;
        merchant_uid: string;
        imp_success: string;
        error_code?: string;
        error_msg?: string;
      } = {
        imp_uid: response.imp_uid,
        merchant_uid: response.merchant_uid,
        imp_success: response.error_msg ? "false" : "true",
      };

      if (!param.imp_success) {
        param.error_code = response.error_code;
        param.error_msg = response.error_msg;
      }

      navigate(
        { pathname: "../verify", search: `?${createSearchParams(param)}` },
        { replace: true }
      );
    } else {
      const ref = doc(firestore, "books-payment-verify", response.merchant_uid);
      await deleteDoc(ref);

      setAlertMessage(response.error_msg);
      setLoading(false);
    }
  };

  const findCoupon = async (code: string) => {
    setFee(totalFee - point);
    setCoupon(0);
    setCouponDescription("");
    couponRef.current = undefined;
    setCouponMessage("");
    try {
      const ref = collection(firestore, "coupon");
      const qry = query(
        ref,
        where("code", "==", code)
        // where("endDate", ">=", Timestamp.now())
      );
      const response = await getDocs(qry);

      if (response.size <= 0) {
        setCouponMessage("등록되지 않은 쿠폰 번호입니다");
      } else {
        for (let snapshot of response.docs) {
          if (snapshot.exists()) {
            const data = snapshot.data() as Coupon;
            let coupon: CouponWithRef = {
              ...data,
              docRef: snapshot.ref,
            };

            const now = getToday();
            const startDate = timestampToDayjs(coupon.startDate);
            const endDate = timestampToDayjs(coupon.endDate);

            const startError =
              now.diff(startDate, "day") !== 0 || now.isBefore(startDate);
            const endError =
              now.diff(endDate, "day") !== 0 && now.isAfter(endDate);

            if (startError || endError) {
              setCouponMessage("사용 기한이 아닙니다.");
            } else if (
              coupon.activation === 0 ||
              coupon.numOfUsed >= coupon.quantity
            ) {
              setCouponMessage("만료된 쿠폰입니다.");
            } else if (
              coupon.reusable !== 2 &&
              coupon.whoUsed.includes(info.uid)
            ) {
              setCouponMessage("이미 사용된 쿠폰입니다.");
            } else {
              let amount =
                coupon.type === 0
                  ? coupon.amount
                  : totalFee * (coupon.amount / 100);

              // 쿠폰 금액이 총 결제금액보다 높은 경우
              if (totalFee <= amount) {
                amount = totalFee;
              }

              setCoupon(amount);
              setCouponDescription(coupon.description);
              couponRef.current = coupon;

              const fee = totalFee - point - amount;
              if (fee < 0) {
                const newPoint = point + fee;
                setPoint(newPoint);
                setFee(0);
              } else setFee(fee);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getBasicFee = (
    totalFee: number,
    surCharge: boolean,
    bedwheel: boolean
  ) => {
    if (surCharge) {
      return (bedwheel ? totalFee - 11000 : totalFee) * (100 / 130) + 11000;
    } else {
      return totalFee;
    }
  };

  return (
    <Box p={1}>
      <Loading open={clearLoading} />
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>예약 일정 및 결제 정보 확인</Typography>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={1} mt={1}>
          <Typography width={"150px"}>배차일자</Typography>
          <Box>
            {availableLists.map((book) => (
              <Typography key={book.uid.id} color={"primary"}>
                {`${dayjs(book.bookDrive.addrA.arrival.toDate()).format(
                  "ll (ddd)"
                )}${book.nets.surCharge ? " (할증 적용됨)" : ""}`}
              </Typography>
            ))}
          </Box>
        </Box>
        <Typography sx={{ mt: 1 }}>차량 도착예상시간</Typography>

        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>픽업지</Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrA.addr}, {bookDrive.addrA.addrDetail}
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>목적지</Typography>
            <Typography color={"primary"}>
              {`${dayjs(bookDrive.addrB.arrival.toDate()).format("LT")}`}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrB.addr}, {bookDrive.addrB.addrDetail}
          </Typography>
        </Box>
        {bookDrive.type === "round-trip" && (
          <Box
            sx={{
              mt: 1,
              py: 2,
              px: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "commonColor.main",
            }}
          >
            <Box display={"flex"} gap={2}>
              <Typography>귀가지</Typography>
            </Box>
            <Typography sx={{ mt: 0.5 }}>
              {bookDrive.addrC.addr}, {bookDrive.addrC.addrDetail}
            </Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography
          color={"primary"}
        >{`예약완료 후 마이페이지 > 예약내역 페이지에서 각 예약별 픽업지 도착 시간 및 상세한 내역을 확인해주세요`}</Typography>
      </Paper>

      <Paper elevation={3} sx={{ mt: 1 }}>
        <Accordion
          expanded={expanded === "price"}
          onChange={handleChange("price")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              display={"flex"}
              flex={1}
              justifyContent={"space-between"}
              sx={{ mr: 1, "& > p": { fontWeight: 600 } }}
            >
              <Typography>총 서비스 금액</Typography>
              <Typography>{`${totalFee.toLocaleString()}원`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {availableLists.map((book, index) => (
              <Paper
                key={book.uid.id + index}
                elevation={3}
                sx={{ py: 2, px: 2, mt: 1 }}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography key={book.uid.id} color={"primary"}>
                    {`${dayjs(book.bookDrive.addrA.arrival.toDate()).format(
                      "ll (ddd)"
                    )}${book.nets.surCharge ? " (할증 적용됨)" : ""}`}
                  </Typography>
                  <Typography>{`${book.paymentInfo.totalFee.toLocaleString()}원`}</Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                  <Typography>기본 서비스 금액</Typography>
                  <Typography>{`${getBasicFee(
                    book.paymentInfo.totalFee,
                    book.nets.surCharge,
                    book.nets.bedwheel
                  ).toLocaleString()}원`}</Typography>
                </Box>
                <Paper elevation={3} sx={{ py: 1, px: 1, mt: 1 }}>
                  <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        fontSize={isMobile ? "0.8rem" : "0.9rem"}
                        sx={{ ml: 2 }}
                      >
                        - {netsTypes[book.nets.type].label}
                      </Typography>
                      <Typography
                        fontSize={isMobile ? "0.8rem" : "0.9rem"}
                        sx={{ ml: 2 }}
                      >
                        {book.nets.type === "move"
                          ? netsTypes[book.nets.type].fee.toLocaleString()
                          : netsTypes[book.nets.type][
                              book.bookDrive.type
                            ].toLocaleString()}
                        원
                      </Typography>
                    </Box>

                    {book.nets.type === "wheel-plus" && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isMobile ? "0.8rem" : "0.9rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 계단 이동 : ${book.nets.floor.label} (+${
                            book.nets.floor.value
                          }) ${
                            book.bookDrive.type === "round-trip"
                              ? "(왕복시 x2)"
                              : ""
                          }`}
                        </Typography>
                        <Typography fontSize={isMobile ? "0.8rem" : "0.9rem"}>
                          {(book.bookDrive.type === "round-trip"
                            ? book.nets.floor.value * 2
                            : book.nets.floor.value
                          ).toLocaleString()}
                          원
                        </Typography>
                      </Box>
                    )}
                    {book.nets.bedwheel && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isMobile ? "0.8rem" : "0.9rem"}
                          sx={{ ml: 2 }}
                        >
                          - 침대형 거상 이용
                        </Typography>
                        <Typography fontSize={isMobile ? "0.8rem" : "0.9rem"}>
                          11,000원
                        </Typography>
                      </Box>
                    )}

                    {book.bookDrive.type === "round-trip" && (
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        ml={2}
                      >
                        <Box>
                          <Typography fontSize={isMobile ? "0.8rem" : "0.9rem"}>
                            - 목적지에 머무는 시간{" "}
                            {!isMobile && (
                              <Typography component="span" fontSize={"0.8rem"}>
                                (1시간 무료, 추가 20분당 8,000원)
                              </Typography>
                            )}
                          </Typography>
                          {isMobile && (
                            <Typography
                              fontSize={isMobile ? "0.7rem" : "0.8rem"}
                              sx={{ ml: 1 }}
                            >
                              (1시간 무료, 추가 20분당 8,000원)
                            </Typography>
                          )}
                          <Typography
                            fontSize={isMobile ? "0.8rem" : "0.9rem"}
                            sx={{ ml: 2 }}
                          >
                            {`- ${book.companion.stayHour}시간 ${book.companion.stayMin}분 `}
                          </Typography>
                        </Box>
                        <Typography>
                          {book.companion.companionFee.toLocaleString()}원
                        </Typography>
                      </Box>
                    )}

                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      ml={2}
                    >
                      <Box>
                        <Typography fontSize={isMobile ? "0.8rem" : "0.9rem"}>
                          {`- 총 이동거리: 
              ${(
                (book.bookDrive.addrA.distanceToNext +
                  book.bookDrive.addrB.distanceToNext) /
                1000
              ).toFixed(2)}km `}
                          {!isMobile && (
                            <Typography component="span" fontSize={"0.8rem"}>
                              (편도당 10km 무료, 추가 5km당 11,000원)
                            </Typography>
                          )}
                        </Typography>

                        {isMobile && (
                          <Typography
                            fontSize={isMobile ? "0.7rem" : "0.8rem"}
                            sx={{ ml: 1 }}
                          >
                            (편도당 10km 무료, 추가 5km당 11,000원)
                          </Typography>
                        )}
                      </Box>
                      <Typography minWidth={"80px"} align="right">
                        {(
                          book.bookDrive.addrA.feeToNext +
                          book.bookDrive.addrB.feeToNext
                        ).toLocaleString()}
                        원
                      </Typography>
                    </Box>
                    {book.bookDrive.addrA.feeToNext +
                      book.bookDrive.addrB.feeToNext >
                      0 && (
                      <>
                        {book.bookDrive.addrA.feeToNext > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={isMobile ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`- 픽업지 ➝ 목적지: ${(
                                book.bookDrive.addrA.distanceToNext / 1000
                              ).toFixed(2)}km`}
                            </Typography>

                            <Typography
                              fontSize={isMobile ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`${book.bookDrive.addrA.feeToNext.toLocaleString()}원`}
                            </Typography>
                          </Box>
                        )}
                        {book.bookDrive.addrB.feeToNext > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={isMobile ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`- 목적지 ➝ 귀가지: ${(
                                book.bookDrive.addrB.distanceToNext / 1000
                              ).toFixed(2)}km`}
                            </Typography>

                            <Typography
                              fontSize={isMobile ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`${book.bookDrive.addrB.feeToNext.toLocaleString()}원`}
                            </Typography>
                          </Box>
                        )}

                        {book.bookDrive.sameReturn && (
                          <Typography
                            fontSize={isMobile ? "0.8rem" : "0.9rem"}
                            sx={{ ml: 2 }}
                          >
                            * 왕복시 픽업지와 귀가지가 동일한 경우 짧은
                            이동거리에 맞춰 계산합니다
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                </Paper>
                {book.nets.surCharge && (
                  <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                    <Typography>할증 요금 (30%)</Typography>
                    <Typography>{`${(
                      book.paymentInfo.totalFee -
                      getBasicFee(
                        book.paymentInfo.totalFee,
                        true,
                        book.nets.bedwheel
                      )
                    ).toLocaleString()}원`}</Typography>
                  </Box>
                )}
              </Paper>
            ))}
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>쿠폰 등록</Typography>
          <Box display={"flex"} gap={2}>
            <TextField
              sx={{ width: "150px" }}
              placeholder="쿠폰번호 입력"
              variant="outlined"
              value={couponCode}
              onChange={(event) => setCouponCode(event.target.value)}
              size="small"
            />
            {coupon !== 0 ? (
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  setCoupon(0);
                  setCouponCode("");
                  setCouponDescription("");
                  setCouponMessage("");
                  couponRef.current = undefined;
                  setFee(totalFee - point);
                }}
              >
                등록 취소
              </Button>
            ) : (
              <Button
                disabled={couponCode.length !== 10}
                variant="contained"
                onClick={() => findCoupon(couponCode)}
              >
                쿠폰 확인
              </Button>
            )}
          </Box>
        </Box>
        {coupon > 0 && (
          <Box
            display={"flex"}
            gap={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={1}
            sx={{
              "& > p": {
                color: "commonColor.grey",
              },
            }}
          >
            <Typography>{couponDescription}</Typography>
            <Typography>{coupon.toLocaleString()}원</Typography>
          </Box>
        )}
        {couponMessage !== "" && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            mt={1}
            sx={{
              "& > p": {
                color: "commonColor.warning",
              },
            }}
          >
            <Typography>{couponMessage}</Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>포인트 사용</Typography>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <TextField
              sx={{ width: "150px" }}
              value={point.toLocaleString()}
              onChange={(event) => {
                const p =
                  event.target.value === ""
                    ? 0
                    : parseInt(
                        (event.target.value as string).replace(/\D/g, "")
                      );
                let newPoint = 0;
                const currentFee = totalFee - coupon;

                // 잔여포인트가 결제금액보다 큼. 사용 포인트 신경 안써도 됨
                if (myPoint >= currentFee) {
                  // 입력한 숫자가 결제 금액 이하
                  if (p < currentFee) {
                    // 포인트를 써도 0원이 안될 경우 결제금액 100원까지만 포인트 사용
                    newPoint = currentFee - p >= 100 ? p : currentFee - 100;
                  } else {
                    // 결제금액을 0원으로 만듬
                    newPoint = currentFee;
                  }

                  // 잔여포인트가 결제금액보다 작음. (다 사용해도 결제금액이 0원이 되지 않음)
                } else {
                  // 잔여포인트를 다 써도 결제금액이 100원 이상 남을 경우
                  if (currentFee - myPoint >= 100) {
                    newPoint = myPoint >= p ? p : myPoint;
                    // 잔여포인트를 다 쓰면 100원 미만이 되는 경우
                  } else {
                    newPoint = currentFee - p >= 100 ? p : currentFee - 100;
                  }
                }

                setPoint(newPoint);
                setFee(totalFee - newPoint - coupon);
              }}
              variant="outlined"
              size="small"
              inputProps={{ style: { textAlign: "end" } }}
            />
            <Button
              variant="contained"
              onClick={() => {
                const currentFee = totalFee - coupon;
                let newPoint = 0;
                newPoint =
                  myPoint >= currentFee ? currentFee : currentFee - 100;

                setPoint(newPoint);
                setFee(totalFee - newPoint - coupon);
              }}
            >
              전액 사용
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={1}
          sx={{
            "& > p": {
              color: "commonColor.grey",
            },
          }}
        >
          <Typography>사용 가능 포인트</Typography>
          <Typography>{(myPoint - point).toLocaleString()}포인트</Typography>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>총 결제금액</Typography>
          <Typography color={"primary"}>{fee.toLocaleString()}원</Typography>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>결제 방법 선택</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={paymentType}
            onChange={(_, value) =>
              setPaymentType(value as unknown as PaymentType)
            }
          >
            {/* <FormControlLabel
              value="kakaopay"
              control={<Radio size="small" />}
              label={
                <Box display={"flex"} height={"24px"} gap={1}>
                  <img src="/images/kakaopay_icon.png" alt="kakaopay" />
                  카카오페이
                </Box>
              }
            /> */}
            <FormControlLabel
              value="card"
              control={<Radio size="small" />}
              label="카드 결제"
            />
            <FormControlLabel
              value="account"
              control={<Radio size="small" />}
              label="가상 계좌"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <CollapseMessage
        type="error"
        message={alertMessage || ""}
        open={alertMessage !== undefined}
        onClose={() => setAlertMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <DialogYesNoAction
        title={"예약 확정"}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={onStore}
      >
        <Typography>결제하실 금액이 없습니다.</Typography>
        <Typography>예약을 확정하시겠습니까?</Typography>
      </DialogYesNoAction>

      <DialogYesNoAction
        title={"임시예약 취소"}
        color="alert"
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onAgree={() => {
          setOpenCancelDialog(false);
          onClear(false);
        }}
      >
        <Typography>작성중이던 예약을 취소하시겠습니까?</Typography>
      </DialogYesNoAction>

      <Button
        variant="contained"
        color="warning"
        fullWidth
        disabled={loading || clearLoading}
        sx={{ mt: 2, fontSize: "1.1rem" }}
        onClick={() => navigate(-1)}
      >
        {`수정하기`}
      </Button>
      <Box display={"flex"} width={"100%"} gap={1}>
        <Button
          variant="contained"
          color="error"
          disabled={loading || clearLoading}
          sx={{ mt: 1, mb: 2, fontSize: "1.1rem" }}
          onClick={() => setOpenCancelDialog(true)}
        >
          {`예약 취소`}
        </Button>
        <Box flex={1}>
          <Button
            variant="contained"
            fullWidth
            disabled={loading || clearLoading}
            sx={{
              mt: 1,
              mb: loading ? 0 : 2,
              fontWeight: "600",
              fontSize: "1.1rem",
            }}
            onClick={() => (fee === 0 ? setOpenDialog(true) : onStore())}
            // onClick={() => onNext()}
          >
            {loading ? "결제중" : "결제하기"}
          </Button>
        </Box>
      </Box>
      {loading && <LinearProgress sx={{ mt: -0.5, mb: 2, borderRadius: 24 }} />}
    </Box>
  );
};
