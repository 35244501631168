import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { useAuth } from "../../context/AuthContext";
import { useBoardContext } from "../../context/BoardContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const InfoNoticeDetailPage = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();
  const {
    board: { lists, index },
    updateIndex,
  } = useBoardContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [index]);

  return (
    <>
      {index !== -1 && (
        <>
          <Paper elevation={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                py: 3,
                pr: 4,
                pl: 2,
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <Box display={"flex"} gap={2}>
                <ArrowBackIosNewIcon />
                <Typography fontWeight={600} fontSize={"1.1rem"}>
                  {lists[index].title}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                sx={{ "& > p": { fontSize: "0.9rem" } }}
              >
                <Typography>{lists[index].writer}</Typography>
                <Typography>|</Typography>
                <Typography>
                  {timestampToDayjs(lists[index].date).format("YYYY.MM.DD")}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ mx: 1 }} />
            <Box px={4} py={2}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: lists[index].description,
                }}
                sx={{ "& > figure > img": { width: "100%" } }}
              ></Typography>
            </Box>
          </Paper>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
            <Button
              variant="outlined"
              disabled={index + 1 >= lists.length}
              onClick={() => updateIndex(index + 1)}
            >
              이전 글
            </Button>
            <Button
              variant="outlined"
              disabled={index <= 0}
              onClick={() => updateIndex(index - 1)}
            >
              다음 글
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              목록 보기
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
