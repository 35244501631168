import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MobileStepper,
  Paper,
  Typography,
} from "@mui/material";
import {
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useBookContext } from "../../../context/BookContext";

export const CheckInfo = () => {
  const navigate = useNavigate();
  const { book } = useBookContext();
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }
  }, []);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onNavigate = (navToNext: boolean = true) => {
    if (navToNext) {
      // navigate(book.type === "single" ? "../findcar" : "../patient");
      navigate(book.type === "single" ? "../findcar" : "../findcarMulti");
    } else {
      navigate(
        book.bookDrive.type === "one-way" ? "../address" : "../companion",
        {
          replace: true,
        }
      );
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>입력 정보 확인</Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Accordion
          expanded={expanded === "nets"}
          onChange={handleChange("nets")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ width: "33%", flexShrink: 0 }}
            >
              <Typography>네츠</Typography>
            </Box>
            <Box>
              <Typography color={"primary"}>
                {book.nets.type === "wheel-plus"
                  ? `휠체어플러스`
                  : book.nets.type === "wheel"
                  ? `휠체어`
                  : "무브"}
              </Typography>
              {book.nets.type === "wheel-plus" && (
                <Typography color={"primary"}>
                  {book.nets.floor.label}
                </Typography>
              )}
              {book.nets.bedwheel && (
                <Typography color={"primary"}>침대형 거상 휠체어</Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Box display={"flex"} gap={2}>
                <Typography sx={{ width: "40%", flexShrink: 0 }}>
                  네츠 서비스
                </Typography>
                <Typography>
                  {book.nets.type === "wheel-plus"
                    ? `휠체어플러스`
                    : book.nets.type === "wheel"
                    ? `휠체어`
                    : "무브"}
                </Typography>
              </Box>
              {book.nets.type === "wheel-plus" && (
                <Box display={"flex"} gap={2}>
                  <Typography
                    sx={{ width: "40%", flexShrink: 0, wordBreak: "keep-all" }}
                  >
                    계단리프트 필요한 층수
                  </Typography>
                  <Typography>
                    {book.nets.floor.label}
                    {/* {book.nets.elevator
                      ? "있음"
                      : `없음 / ${book.nets.floor.label}`} */}
                  </Typography>
                </Box>
              )}

              {book.nets.bedwheel && (
                <Box display={"flex"} gap={2}>
                  <Typography sx={{ width: "40%", flexShrink: 0 }}>
                    침대형 거상 휠체어
                  </Typography>
                  <Typography>신청</Typography>
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === "null"}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              왕복/편도 선택
            </Typography>
            <Typography color={"primary"}>
              {book.bookDrive.type === "one-way" ? "편도" : "왕복"}
            </Typography>
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={expanded === "null"}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>픽업지</Typography>
            <Typography
              color={"primary"}
            >{`${book.bookDrive.addrA.addr}, ${book.bookDrive.addrA.addrDetail}`}</Typography>
          </AccordionSummary>
        </Accordion>

        <Accordion expanded={expanded === "null"}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>목적지</Typography>
            <Typography
              color={"primary"}
            >{`${book.bookDrive.addrB.addr}, ${book.bookDrive.addrB.addrDetail}`}</Typography>
          </AccordionSummary>
        </Accordion>

        {book.bookDrive.type === "round-trip" && (
          <Accordion expanded={expanded === "null"}>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                귀가지
              </Typography>
              <Typography
                color={"primary"}
              >{`${book.bookDrive.addrC.addr}, ${book.bookDrive.addrC.addrDetail}`}</Typography>
            </AccordionSummary>
          </Accordion>
        )}

        {book.bookDrive.type === "round-trip" &&
          (book.companion.needHelp ? (
            <Accordion
              expanded={expanded === "stay"}
              onChange={handleChange("stay")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  목적지 대기시간
                </Typography>
                <Box>
                  <Typography color={"primary"}>
                    {`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}
                  </Typography>
                  <Typography color={"primary"}>원내 동행 신청</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Box display={"flex"} gap={2}>
                    <Typography sx={{ width: "40%", flexShrink: 0 }}>
                      목적지 대기시간
                    </Typography>
                    <Typography>
                      {`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}
                    </Typography>
                  </Box>

                  <Box display={"flex"} gap={2}>
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ width: "40%", flexShrink: 0 }}
                    >
                      동행 서비스
                    </Typography>
                    <Box>
                      {book.companion.helpOptions.map((option, index) => (
                        <Typography key={index}>{option}</Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box display={"flex"} gap={2}>
                    <Typography sx={{ width: "40%", flexShrink: 0 }}>
                      기타 요청사항
                    </Typography>
                    <Typography>{book.companion.helpExtra}</Typography>
                  </Box>
                  <Box display={"flex"} gap={2}>
                    <Typography sx={{ width: "40%", flexShrink: 0 }}>
                      병원 예약 시간과 내용
                    </Typography>
                    <Typography>{book.companion.helpDiagnosis}</Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Accordion expanded={expanded === "null"}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
              >
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  목적지 대기시간
                </Typography>
                <Box>
                  <Typography color={"primary"}>
                    {`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}
                  </Typography>
                </Box>
              </AccordionSummary>
            </Accordion>
          ))}
      </Paper>

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={3}
        nextButton={
          <Button size="small" onClick={() => onNavigate()}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={() => onNavigate(false)}>
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
