import { Box, Button, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuth } from "../../context/AuthContext";
import { Patient } from "../../context/BookContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

export type PatientBookmark = Patient & {
  index: number;
};

const PatientInfoPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();

  const [bookmark, setBookmark] = useState<PatientBookmark[]>([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    try {
      const ref = collection(
        firestore,
        "user-info",
        info.uid,
        "bookmark_patient"
      );
      const snapshot = getDocs(ref);

      const bookmarks: PatientBookmark[] = [];
      snapshot.then((data) => {
        data.forEach((data) => {
          if (data.exists()) {
            const p = data.data() as PatientBookmark;
            bookmarks.push(p);
            if (p.index >= index) setIndex(p.index + 1);
          }
        });
        setBookmark(bookmarks);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {bookmark.map((patient, index) => (
        <Paper
          key={index}
          elevation={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
            px: 4,
            cursor: "pointer",
          }}
          onClick={() => navigate("modify", { state: { patient } })}
        >
          <Typography>{patient.nickname}</Typography>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography sx={{ color: "commonColor.main" }}>
              {patient.name}
            </Typography>
            <NavigateNextIcon />
          </Box>
        </Paper>
      ))}
      {isPC ? (
        <Box alignSelf={"end"}>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() =>
              navigate("modify", { state: { newItem: true, index } })
            }
          >
            <AddCircleOutlineIcon />
            이용자 추가
          </Button>
        </Box>
      ) : (
        <>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() =>
              navigate("modify", { state: { newItem: true, index } })
            }
          >
            <AddCircleOutlineIcon />
            이용자 추가
          </Button>
        </>
      )}
    </Box>
  );
};

export default PatientInfoPage;
