import { Box, Button, Typography } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { authService } from "../../firebase";

export const Verify = () => {
  const uid: string = useLocation().state;
  const {
    state: { auth },
    signOut: signedOut,
  } = useAuth();

  const navigate = useNavigate();

  const onEmailVerified = async () => {
    if (authService.currentUser) {
      // navigate('/', { replace: true, state: 'signin' });
      console.log(authService);
      await authService.currentUser.reload();
      if (authService.currentUser.emailVerified) {
        // emailVerified(authService.currentUser);
        signedOut();
        navigate("/", { replace: true });
        // signOut().then(_ => {
        //   console.log('sign out done');
        //   navigate('/', { replace: true, state: 'signin' });
        // });
      }
    } else {
      console.log("auth is null");
      // navigate('/', { replace: true, state: 'signin' });
    }
  };

  // console.log(uid);
  // if (uid === undefined || uid === null)
  //   return <Navigate to="/unauthorizedAccess" replace />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        gap={1}
      >
        <Typography color={"primary.main"} fontSize={"1.2rem"}>
          회원 가입을 환영합니다!
        </Typography>
        <Typography mt={1}>
          가입하신 {auth?.email} 주소로 인증 메일이 발송되었습니다.
        </Typography>
        <Typography>이메일을 확인하시고 인증 링크를 눌러주세요.</Typography>
        <Typography>완료 후 인증 완료 버튼을 눌러주세요.</Typography>
        <Button variant="contained" onClick={onEmailVerified} sx={{ mt: 1 }}>
          인증 완료
        </Button>
      </Box>
    </Box>
  );
};
