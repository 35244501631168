import { useRef, useCallback } from "react";

// 연속된 호출 발생 시, delay 시간마다 한번씩만 실행함
export const useThrottle = (initWaiting: number) => {
  const init = useRef(false);
  const isWaiting = useRef(false);

  return useCallback(
    <T extends (...args: any[]) => void>(callback: T, delay: number) =>
      (...args: Parameters<T>) => {
        if (init.current) {
          if (!isWaiting.current) {
            callback(...args);
            isWaiting.current = true;
            setTimeout(() => {
              isWaiting.current = false;
            }, delay);
          }
        } else {
          // 처음 등록될 때 callback이 호출되는 것을 막음
          init.current = true;
          isWaiting.current = true;
          setTimeout(() => {
            isWaiting.current = false;
          }, initWaiting);
        }
      },
    []
  );
};
