import { Timestamp } from "firebase/firestore";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";

export type BoardInfo = {
  key: string;
  writer: string;
  writerUid: string;
  date: Timestamp;
  title: string;
  description: string;
  visible: "공개" | "비공개";
};

export type BoardListType = {
  lists: BoardInfo[];
  index: number;
};

type ContextType = {
  board: BoardListType;
  updateLists: (lists: BoardInfo[]) => void;
  updateIndex: (index: number) => void;
};

const initBoard = { lists: [], index: -1 };

const BoardContext = createContext<ContextType>({
  board: initBoard,
  updateLists: () => {},
  updateIndex: () => {},
});

export const useBoardContext = () => useContext(BoardContext);

type ActionType =
  | { type: "UPDATE-BOARDS"; lists: BoardInfo[] }
  | { type: "UPDATE-INDEX"; index: number };

const reducer = (board: BoardListType, action: ActionType): BoardListType => {
  switch (action.type) {
    case "UPDATE-INDEX":
      return { ...board, index: action.index };
    case "UPDATE-BOARDS":
      return { ...board, lists: action.lists };

    default:
      return board;
  }
};

export const BoardProvider = ({ children }: PropsWithChildren) => {
  const [board, dispatch] = useReducer(reducer, initBoard);

  const updateLists = (lists: BoardInfo[]) => {
    dispatch({ type: "UPDATE-BOARDS", lists });
  };

  const updateIndex = (index: number) => {
    dispatch({ type: "UPDATE-INDEX", index });
  };

  return (
    <BoardContext.Provider value={{ board, updateLists, updateIndex }}>
      {children}
    </BoardContext.Provider>
  );
};
