import { useLocation, useNavigate } from "react-router-dom";
import { BookState, useBookContext } from "../../../context/BookContext";
import { FindCarResult } from "../../../apiCallFunctions/onFindCar";
import { usePreventRefresh } from "../../../hooks/usePreventRefresh";
import { useEffect, useState } from "react";
import { Button, Grid, MobileStepper, Paper, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { DialogYesNoAction } from "../../../components/dialog";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { Loading } from "../../../components/loading";
import {
  getDayjsParseFormat,
  getTimeString,
} from "../../../components/function";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";

export const FindCarMultiResultPage = () => {
  const navigate = useNavigate();
  const { state }: { state?: { result: FindCarResult[]; bookDate: string[] } } =
    useLocation();
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { updateBook, updateMultiBookUids } = useBookContext();

  usePreventRefresh();

  useEffect(() => {
    if (state === null || state === undefined) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (state) {
      state.result.sort((a, b) => (a.date > b.date ? 1 : -1));

      let okDate: string[] = [];
      for (let item of state.result) {
        if (item.ok === "ok") okDate.push(item.uid);
      }

      setSelected(okDate);
    }
  }, [state]);

  useEffect(() => {
    onResetThrottle();
    onRenewThrottle();
  }, [selected]);

  // creationDate 갱신
  const onRenew = async () => {
    let uidList: string[] = [];
    for (let item of state!.result) {
      if (item.ok === "ok" || item.ok === "alter") {
        uidList.push(item.uid);
      }
    }

    try {
      await onRenewBook(uidList);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setLoading(true);

    try {
      await removeTempBooks([]);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, onResetThrottle, onRenewThrottle } = useResetTimer(
    onRenew,
    onClear
  );

  const getBook = (uid: string) => {
    return new Promise<BookState>((resolve, reject) => {
      // 다중 예약 중 하나만 우선 가져옴
      getDoc(doc(firestore, "books", uid))
        .then((value) => {
          if (value.exists()) resolve(value.data() as BookState);
          else reject("no data");
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // 선택되지 않은 모든 임시 예약 데이터 삭제
  const removeTempBooks = async (selected: string[]) => {
    // remove all temp books
    let uidList: string[] = [];
    for (let item of state!.result) {
      if (item.uid !== "" && !selected.includes(item.uid))
        uidList.push(item.uid);
    }

    if (uidList.length > 0) {
      return onRemoveBook(uidList);
    }
  };

  // 선택된 하나의 임시예약만 남기고 나머지는 삭제한 후 다음 페이지로 이동
  const onNext = async () => {
    setOpenDialog(false);
    if (selected.length > 0) {
      try {
        setLoading(true);
        await removeTempBooks(selected);
        const book = await getBook(selected[0]);
        updateMultiBookUids(selected, []);
        updateBook(book);
        setLoading(false);
        navigate("../patient", { replace: true });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const onPrev = async () => {
    setLoading(true);
    try {
      await removeTempBooks([]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    navigate("../findcarMulti", { replace: true });
  };

  const onToggle = (uid: string) => {
    let updateSet = [...selected];
    let index = selected.indexOf(uid);
    if (index === -1) updateSet.push(uid);
    else updateSet.splice(index, 1);

    setSelected(updateSet);
  };

  return state && state.result ? (
    <>
      {loading && <Loading open={loading} />}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>
          {`요청하신 ${state.bookDate.length}건에 대한 검색 결과입니다 (${selected.length}건 선택됨)`}
        </Typography>
        <Typography color={"primary"}>
          원하지 않는 날짜가 있다면 선택을 해제하신 후 다음 버튼을 눌러주세요
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Grid container spacing={2}>
          {state.result.map((data) => (
            <Grid key={data.uid + data.date} item pc={6} mobile={12}>
              <GridCell
                data={data}
                selected={selected.includes(data.uid)}
                onToggle={onToggle}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
      {state.result[0].ok === "alter" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"commonColor.alert"}>
            다른 시간에 예약을 원하시면 이전 화면에서 다른 시간을 검색하시거나,
            고객센터(070-5088-2000)로 문의주세요
          </Typography>
        </Paper>
      )}

      <DialogYesNoAction
        title={"이동 확인"}
        color="alert"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => onNext()}
      >
        <Typography>
          다음 단계로 이동 시, 더 이상 기존에 입력한 정보의 수정이 불가능합니다.
        </Typography>
        <Typography>수정이 필요할 경우 새로 예약해주세요</Typography>
      </DialogYesNoAction>

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={4}
        nextButton={
          <Button
            size="small"
            disabled={selected.length <= 0 || loading}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" disabled={loading} onClick={() => onPrev()}>
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </>
  ) : (
    <></>
  );
};

const GridCell = ({
  data,
  selected,
  onToggle,
}: {
  data: FindCarResult;
  selected: boolean;
  onToggle: (uid: string) => void;
}) => {
  if (data.ok === "ok") {
    return (
      <Paper
        elevation={3}
        sx={{
          py: 2,
          px: 2,
          textAlign: "center",
          cursor: "pointer",
          color: data.uid === "" ? "primary" : selected ? "white" : "primary",
          bgcolor:
            data.uid === ""
              ? "primary"
              : selected
              ? "commonColor.main"
              : "primary",
        }}
        onClick={() => onToggle(data.uid)}
      >
        <Typography
          fontWeight={data.uid === "" ? 400 : selected ? 600 : 400}
        >{`${getDayjsParseFormat(data.date, "YYMMDDHHmm").format(
          "ll(ddd) HH:mm"
        )}`}</Typography>
        <Typography
          fontWeight={data.uid === "" ? 400 : selected ? 600 : 400}
          mt={1}
        >
          예약 가능
        </Typography>
      </Paper>
    );
  } else if (data.ok === "none") {
    return (
      <Paper
        elevation={3}
        sx={{
          py: 2,
          px: 2,
          textAlign: "center",
          bgcolor: "#e2e2e2",
        }}
      >
        <Typography>{`${getDayjsParseFormat(data.date, "YYMMDDHHmm").format(
          "ll(ddd) HH:mm"
        )}`}</Typography>
        <Typography mt={1}>예약 불가능</Typography>
      </Paper>
    );
  } else {
    return (
      <Paper
        elevation={3}
        sx={{
          py: 2,
          px: 2,
          textAlign: "center",
          bgcolor: "#e2e2e2",
        }}
      >
        <Typography>{`${getDayjsParseFormat(data.date, "YYMMDDHHmm").format(
          "ll(ddd) HH:mm"
        )}`}</Typography>
        <Typography mt={1} sx={{ color: "commonColor.alert" }}>
          {data.message}
        </Typography>
      </Paper>
    );
  }
};
