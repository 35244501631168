import { TextField, TextFieldProps } from "@mui/material";

import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { PhoneNumberInput } from "./phoneNumberInput";

type BaseProps = {
  helpMessage?: string;
};

type MuiInputProps = BaseProps & {
  textFieldProps?: TextFieldProps;
};

/**
 *
 *   @type TFieldValues - useForm에 연결된 object type
 *   @type TName - useForm에 연결된 input의 name
 *
 *   @param textFieldProps - MUI-TextField의 props
 *   @param helpMessage - input 아래 기본으로 제공하는 안내 메세지 (옵션)
 *   @param ...props - react-hook-form에서 제공하는 useController의 props
 *
 */
export const Input = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  textFieldProps,
  helpMessage,
  ...props
}: MuiInputProps & UseControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController(props);

  return (
    <TextField
      {...textFieldProps}
      name={name}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={!!error ? error.message : !!helpMessage && helpMessage}
      margin="normal"
    />
  );
};

export const InputPhoneNumber = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  textFieldProps,
  helpMessage,
  ...props
}: MuiInputProps & UseControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController(props);

  return (
    <TextField
      {...textFieldProps}
      name={name}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={!!error ? error.message : !!helpMessage && helpMessage}
      margin="normal"
      InputProps={{
        inputComponent: PhoneNumberInput as any,
      }}
    />
  );
};
