import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  CheckboxOption,
  Companion as CompanionType,
  useBookContext,
  helpOptions as options,
} from "../../../context/BookContext";
import { useEffect, useState } from "react";
import {
  convertListsToObject,
  convertObjectToList,
} from "../../../components/function";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const Companion = () => {
  const navigate = useNavigate();
  const {
    book: { uid, companion },
    updateCompanion,
  } = useBookContext();
  const [stayHour, setStayHour] = useState(companion.stayHour);
  const [stayMin, setStayMin] = useState(companion.stayMin);
  const [companionFee, setCompanionFee] = useState(companion.companionFee);
  const [needHelp, setNeedHelp] = useState(companion.needHelp);
  const [helpOptions, setHelpOptions] = useState<CheckboxOption[]>(
    convertListsToObject(options, companion.helpOptions)
  );
  const [helpExtra, setHelpExtra] = useState(companion.helpExtra);

  useEffect(() => {
    if (uid.bookUser === "") {
      navigate("/", { replace: true });
    }
  }, []);

  const onNav = (navToPrev: boolean = false) => {
    // const companionFee =
    //   (stayHour <= 1 ? 0 : (stayHour - 1) * 24000) + stayMin * 400;
    const newCompanion: CompanionType = {
      ...companion,
      stayHour: Number(stayHour),
      stayMin: Number(stayMin),
      companionFee,
      needHelp,
      helpOptions: convertObjectToList(helpOptions),
      helpExtra,
    };

    updateCompanion(newCompanion);

    if (navToPrev) navigate("../address", { replace: true });
    else navigate("../checkinfo");
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>목적지에서 머무는 시간</Typography>
        <Typography color={"primary"} sx={{ ml: 1, whiteSpace: "pre-line" }}>
          {`- 왕복 서비스의 경우 기본 1시간 포함\n - 20분 단위 시간 추가 가능 (20분당 8,000원)`}
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography
          color={"primary"}
          sx={{ ml: 1, whiteSpace: "pre-line", wordBreak: "keep-all" }}
        >
          {`⚠️ 네츠는 완전 예약제로 운영됩니다.\n예약하지 않은 시간은 현장에서 임의로 대기해드릴 수 없으니 필요한 시간을 충분히 예약해주시기 바랍니다.`}
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>{`목적지에서 머무는 시간 ${
          companionFee > 0 ? `(+ ${companionFee.toLocaleString()}원)` : ""
        }`}</Typography>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          sx={{ pl: 1, pt: 1 }}
        >
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                let hour = stayHour <= 1 ? 1 : stayHour - 1;
                setStayHour(hour);
                setCompanionFee(
                  (hour <= 1 ? 0 : (hour - 1) * 24000) + stayMin * 400
                );
              }}
            >
              <RemoveCircleIcon color="primary" />
            </IconButton>
            <TextField
              variant="standard"
              inputProps={{
                // inputMode: "numeric",
                // pattern: "[0-9]*",
                style: { textAlign: "center", width: "40px" },
              }}
              value={stayHour}
              onChange={(event) => {
                const hour = event.target.value as unknown as number;
                setStayHour(hour <= 0 ? 0 : hour);
                setCompanionFee(
                  (hour <= 1 ? 0 : (hour - 1) * 24000) + stayMin * 400
                );
              }}
            />
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                let hour = Number(stayHour) + 1;
                setStayHour(hour);
                setCompanionFee(
                  (hour <= 1 ? 0 : (hour - 1) * 24000) + stayMin * 400
                );
              }}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
            시간
          </Box>
          <FormControl>
            <Select
              value={stayMin}
              sx={{ ".MuiInputBase-input": { px: 2, py: 1 } }}
              onChange={(event) => {
                const min = event.target.value as unknown as number;
                setStayMin(min);
                setCompanionFee(
                  (stayHour <= 1 ? 0 : (stayHour - 1) * 24000) + min * 400
                );
              }}
            >
              <MenuItem value={0}>00</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={40}>40</MenuItem>
            </Select>
          </FormControl>
          분
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>원내 동행 : 원내에서 도움이 필요하신 경우 신청</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={needHelp}
            onChange={(_, value) => setNeedHelp(value === "true")}
          >
            <FormControlLabel
              value="false"
              control={<Radio size="small" />}
              label="미신청"
            />
            <FormControlLabel
              value="true"
              control={<Radio size="small" />}
              label="신청 (무료)"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      {needHelp && (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>동행 서비스</Typography>
            <FormControl sx={{ pl: 1, pt: 1 }}>
              <FormGroup>
                {helpOptions.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    label={option.value}
                    value={option.value}
                    checked={option.checked}
                    control={
                      <Checkbox
                        size="small"
                        onChange={() => {
                          const temp = Array.from(helpOptions);
                          temp[index].checked = !option.checked;
                          console.log(temp);
                          setHelpOptions(temp);
                        }}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>기타 요청 사항</Typography>
            <Box display={"flex"} ml={1} mt={1}>
              <TextField
                variant="standard"
                value={helpExtra}
                onChange={(event) => setHelpExtra(event.target.value)}
                sx={{ flex: 1 }}
              />
            </Box>
          </Paper>
        </>
      )}

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={2}
        nextButton={
          <Button size="small" onClick={() => onNav()}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={() => onNav(true)}>
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
