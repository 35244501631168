import { Box, Paper, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyWrongPage = () => {
  const navigate = useNavigate();
  const state = useLocation().state;

  useEffect(() => {
    if (state === null) navigate("/", { replace: true });
    else {
      if (state.type) console.log(state.type);
      if (state.error) console.log(state.error);
    }
  }, [state]);

  return (
    state && (
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"90vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paper elevation={3} sx={{ py: 2, px: 2 }}>
          <Typography color={"primary"}>
            결제 중 오류가 발생했습니다.
          </Typography>
          {state.type && state.type === "payment-error" ? (
            <>
              <Typography mt={2}>[결제 오류]</Typography>
              {state.error && <Typography>{state.error}</Typography>}
            </>
          ) : state.type === "verify-error" ? (
            <>
              <Typography mt={2}>[검증 오류]</Typography>
              {state.error && (
                <Typography>{`${state.error.status} / ${state.error.error_msg}`}</Typography>
              )}
            </>
          ) : (
            <>
              <Typography mt={2}>[알 수 없는 오류]</Typography>
              {state.error && <Typography>{`${state.error}`}</Typography>}
            </>
          )}
        </Paper>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate("/", { replace: true })}
          >
            메인으로 이동하기
          </Button>
        </Box>
      </Box>
    )
  );
};

export default VerifyWrongPage;
