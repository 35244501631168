import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Link,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PostCodeButton } from "../../../components/postcodeButton";
import {
  BookDrive,
  BookDriveType,
  GeolocationType,
  UseLiftAt,
  useBookContext,
} from "../../../context/BookContext";
import dayjs from "dayjs";
import { Timestamp, collection, getDocs } from "firebase/firestore";
import { CollapseMessage } from "../../../components/collapseMessage";
import { useAuth } from "../../../context/AuthContext";
import { GeolocationBookmark } from "../../mypage/addressBook";
import { firestore } from "../../../firebase";
import { seoul, gyeonggi } from "../../../model/region";
import { cloneDeep } from "lodash";
import { ShowRegionDialog } from "../../../components/dialog";

export const StyledLink = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <Link
      component={"span"}
      underline="hover"
      color={"commonColor.grey"}
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {label}
    </Link>
  );
};

export const Address = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const {
    testMode,
    book: { uid, nets, bookDrive },
    updateBookDrive: updateAddr,
    updateNets,
  } = useBookContext();

  const [tripType, setTripType] = useState<BookDriveType>(
    bookDrive.type || "round-trip"
  );

  const [showRegion, setShowRegion] = useState(false);
  const [showBookmarkDialog, setShowBookmarkDialog] = useState<
    number | undefined
  >(undefined);
  const [inavailable1, setInavailable1] = useState<string | undefined>(
    undefined
  );
  const [inavailable2, setInavailable2] = useState<string | undefined>(
    undefined
  );
  const [inavailable3, setInavailable3] = useState<string | undefined>(
    undefined
  );

  const [useLiftAt, setUseLiftAt] = useState<UseLiftAt>(nets.useLiftAt);
  const [addr1, setAddr1] = useState<GeolocationType>(bookDrive.addrA);
  const [addr2, setAddr2] = useState<GeolocationType>(bookDrive.addrB);
  const [addr3, setAddr3] = useState<GeolocationType>(bookDrive.addrC);
  const [loading, setLoading] = useState(false);
  const [sameReturn, setSameReturn] = useState(bookDrive.sameReturn);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [bookmark, setBookmark] = useState<GeolocationBookmark[]>([]);

  useEffect(() => {
    if (uid.bookUser === "") {
      navigate("/", { replace: true });
    }

    try {
      const ref = collection(
        firestore,
        "user-info",
        info.uid,
        "bookmark_address"
      );
      const snapshot = getDocs(ref);

      const bookmarks: GeolocationBookmark[] = [];
      snapshot.then((data) => {
        data.forEach((data) => {
          if (data.exists()) {
            const p = data.data() as GeolocationBookmark;
            bookmarks.push(p);
          }
        });
        setBookmark(bookmarks);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const isAvailableRegion = (address: string) => {
    if (seoul.includes(address.split(" ")[0])) {
      return { ok: true, message: "" };
    } else {
      if (nets.type === "move")
        return { ok: false, message: "네츠 무브는 서울 지역만 운행합니다." };
      else {
        if (gyeonggi.includes(address.split(" ")[1]))
          return { ok: true, message: "" };
        else return { ok: false, message: "해당 지역은 운행이 불가능합니다." };
      }
    }
  };

  const updateAddr1 = (address: GeolocationType) => {
    setInavailable1(undefined);
    setErrorMessage(undefined);
    const response = isAvailableRegion(address.addr);
    if (response.ok) {
      const { addr, addrDetail, latitude, longitude } = address;
      const temp1 = {
        ...addr1,
        addr,
        addrDetail,
        latitude,
        longitude,
      };
      setAddr1(temp1);
      if (sameReturn) {
        const temp3 = { ...addr3, addr, addrDetail, latitude, longitude };
        setAddr3(temp3);
      }
    } else {
      setInavailable1(response.message);
    }
  };

  const updateAddr2 = (address: GeolocationType) => {
    setInavailable2(undefined);
    setErrorMessage(undefined);
    const response = isAvailableRegion(address.addr);
    if (response.ok) {
      const { addr, addrDetail, latitude, longitude } = address;
      const temp = {
        ...addr2,
        addr,
        addrDetail,
        latitude,
        longitude,
      };
      setAddr2(temp);
    } else {
      setInavailable2(response.message);
    }
  };

  const updateAddr3 = (address: GeolocationType) => {
    setInavailable3(undefined);
    setErrorMessage(undefined);
    const response = isAvailableRegion(address.addr);
    if (response.ok) {
      const { addr, addrDetail, latitude, longitude } = address;
      const temp = {
        ...addr3,
        addr,
        addrDetail,
        latitude,
        longitude,
      };
      setAddr3(temp);
    } else {
      setInavailable3(response.message);
    }
  };

  const setBookmarkAddress = ({ ...address }: GeolocationBookmark) => {
    switch (showBookmarkDialog) {
      case 1:
        updateAddr1(address);
        break;
      case 2:
        updateAddr2(address);
        break;
      case 3:
        updateAddr3(address);
        break;
      default:
        break;
    }
    setShowBookmarkDialog(undefined);
  };

  const onNavigate = async (navToNext: boolean = true) => {
    setErrorMessage(undefined);
    if (navToNext) {
      if (
        addr1.addr === "" ||
        addr1.addrDetail === "" ||
        addr2.addr === "" ||
        addr2.addrDetail === "" ||
        (bookDrive.type === "round-trip" &&
          (addr3.addr === "" || addr3.addrDetail === ""))
      ) {
        setErrorMessage("주소를 입력해주세요.");
        return;
      }

      const check1 = isAvailableRegion(addr1.addr);
      if (!check1.ok) {
        setErrorMessage(check1.message);
        return;
      }

      const check2 = isAvailableRegion(addr2.addr);
      if (!check2.ok) {
        setErrorMessage(check2.message);
        return;
      }

      if (bookDrive.type === "round-trip") {
        const check3 = isAvailableRegion(addr3.addr);
        if (!check3.ok) {
          setErrorMessage(check3.message);
          return;
        }
      }
    }

    const newBookDrive: BookDrive = cloneDeep(bookDrive);
    newBookDrive.type = tripType;
    newBookDrive.addrA = addr1;
    newBookDrive.addrB = addr2;
    newBookDrive.addrC = addr3;
    newBookDrive.sameReturn = sameReturn;

    updateNets({ ...nets, useLiftAt });

    if (!navToNext) {
      updateAddr(newBookDrive);
      navigate("../nets", { replace: true });
      return;
    }

    updateAddr(newBookDrive);

    navigate(bookDrive.type === "one-way" ? "../checkInfo" : "../companion");
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Box
          display={"flex"}
          flexDirection={isPC ? "row" : "column"}
          alignItems={isPC ? "center" : "start"}
          gap={isPC ? 2 : 0}
        >
          <Typography color={"primary"}>픽업 및 목적지 주소 입력</Typography>
          <Button
            variant="contained"
            onClick={() => setShowRegion(true)}
            sx={{
              mt: isPC ? 0 : 1,
              gap: 1,
            }}
          >
            <ErrorOutlineIcon />
            서비스 가능 지역 확인
          </Button>
        </Box>
      </Paper>

      {/* <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>왕복/편도 선택</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }} disabled={nets.type === "move"}>
          <RadioGroup
            value={tripType}
            onChange={(_, value) => setTripType(value as unknown as TripType)}
          >
            <FormControlLabel
              value="round-trip"
              control={<Radio size="small" />}
              label="왕복"
            />
            <FormControlLabel
              value="one-way"
              control={<Radio size="small" />}
              label="편도"
            />
          </RadioGroup>
        </FormControl>
      </Paper> */}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>픽업지 주소 입력</Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "commonColor.grey", borderRadius: 3 }}
            onClick={() => setShowBookmarkDialog(1)}
          >
            불러오기
          </Button>
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"} ml={1} mt={1}>
          <TextField
            label="주소"
            variant="standard"
            value={addr1.addr}
            disabled={true}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
          <PostCodeButton
            result={(address: GeolocationType) => updateAddr1(address)}
          />
        </Box>
        <Box display={"flex"} ml={1} mt={1}>
          <TextField
            label="상세 주소 (필수)"
            variant="standard"
            value={addr1.addrDetail}
            onChange={(event) => {
              const temp = { ...addr1 };
              temp.addrDetail = event.target.value;
              setAddr1(temp);
              if (sameReturn) {
                const t = { ...addr3 };
                t.addrDetail = event.target.value;
                setAddr3(t);
              }
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>

      <CollapseMessage
        type="warning"
        open={inavailable1 !== undefined}
        message={inavailable1 || ""}
        onClose={() => setInavailable1(undefined)}
        sx={{ mt: 2, mb: 1 }}
      />

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>목적지 주소 입력</Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "commonColor.grey", borderRadius: 3 }}
            onClick={() => setShowBookmarkDialog(2)}
          >
            불러오기
          </Button>
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"} ml={1} mt={1}>
          <TextField
            label="주소"
            variant="standard"
            value={addr2.addr}
            disabled={true}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />

          <PostCodeButton
            result={(address: GeolocationType) => {
              updateAddr2(address);
            }}
          />
        </Box>
        <Box display={"flex"} ml={1} mt={1}>
          <TextField
            label="상세 주소 (필수)"
            variant="standard"
            value={addr2.addrDetail}
            onChange={(event) => {
              const temp = { ...addr2 };
              temp.addrDetail = event.target.value;
              setAddr2(temp);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>

      <CollapseMessage
        type="warning"
        open={inavailable2 !== undefined}
        message={inavailable2 || ""}
        onClose={() => setInavailable2(undefined)}
        sx={{ mt: 2, mb: 1 }}
      />

      {tripType === "round-trip" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography>귀가지 주소 입력</Typography>
            {!sameReturn && (
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: "commonColor.grey", borderRadius: 3 }}
                onClick={() => setShowBookmarkDialog(3)}
              >
                불러오기
              </Button>
            )}

            <FormGroup sx={{ ml: 1 }}>
              <FormControlLabel
                label="출발지와 동일"
                control={
                  <Checkbox
                    checked={sameReturn}
                    onChange={(_, checked) => {
                      setSameReturn(checked);
                      setAddr3(
                        checked
                          ? cloneDeep(addr1)
                          : {
                              addr: "",
                              addrDetail: "",
                              nickname: "",
                              arrival: Timestamp.fromDate(
                                dayjs().locale("ko").toDate()
                              ),
                              latitude: 0.0,
                              longitude: 0.0,
                              distanceToNext: 0,
                              durationToNext: 0,
                              feeToNext: 0,
                            }
                      );
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Box display={"flex"} gap={2} alignItems={"center"} ml={1} mt={1}>
            <TextField
              label="주소"
              variant="standard"
              value={addr3.addr}
              disabled={true}
              InputLabelProps={{ sx: { color: "commonColor.grey" } }}
              sx={{ flex: 1 }}
            />
            {!sameReturn && (
              <PostCodeButton
                result={(address: GeolocationType) => {
                  updateAddr3(address);
                }}
              />
            )}
          </Box>
          <Box display={"flex"} ml={1} mt={1}>
            <TextField
              label="상세 주소 (필수)"
              variant="standard"
              value={addr3.addrDetail}
              onChange={(event) => {
                const temp = { ...addr3 };
                temp.addrDetail = event.target.value;
                setAddr3(temp);
              }}
              InputLabelProps={{ sx: { color: "commonColor.grey" } }}
              sx={{ flex: 1 }}
            />
          </Box>
        </Paper>
      )}
      {nets.type === "wheel-plus" && bookDrive.type === "one-way" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography>계단이동 리프트 사용 위치</Typography>
          <FormControl sx={{ pl: 1, pt: 1 }}>
            <RadioGroup
              value={useLiftAt}
              onChange={(_, value) => {
                setUseLiftAt(value as UseLiftAt);
              }}
            >
              <FormControlLabel
                value="begin"
                control={<Radio size="small" />}
                label="출발지에서 계단 이동"
              />
              <FormControlLabel
                value="end"
                control={<Radio size="small" />}
                label="목적지에서 계단 이동"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
      )}

      <CollapseMessage
        type="warning"
        open={inavailable3 !== undefined}
        message={inavailable3 || ""}
        onClose={() => setInavailable3(undefined)}
        sx={{ mt: 2 }}
      />

      <Dialog
        open={showBookmarkDialog !== undefined}
        onClose={() => setShowBookmarkDialog(undefined)}
        fullScreen={!isPC}
      >
        <DialogTitle
          color={"primary"}
          sx={{
            m: 0,
            py: 2,
            pl: 3,
            pr: 12,
            width: "480px",
            fontSize: "1.0rem",
            backgroundColor: "commonColor.main",
            color: "white",
          }}
        >
          주소록 불러오기
        </DialogTitle>
        <IconButton
          onClick={() => setShowBookmarkDialog(undefined)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers sx={{ py: 2 }}>
          {bookmark.map((addrBook, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 2,
                px: 2,
                mb: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                setBookmarkAddress(addrBook);
              }}
            >
              <Typography>{addrBook.nickname}</Typography>
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Typography
                  fontSize={"0.9rem"}
                  sx={{ color: "commonColor.main" }}
                >
                  {addrBook.addr}
                </Typography>
                <NavigateNextIcon />
              </Box>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowBookmarkDialog(undefined)}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <ShowRegionDialog showRegion={showRegion} setShowRegion={setShowRegion} />

      <CollapseMessage
        type="warning"
        open={errorMessage !== undefined}
        message={errorMessage || ""}
        onClose={() => setErrorMessage(undefined)}
        sx={{ mt: 2 }}
      />

      {/* <Collapse in={loading}>
        <Alert severity={"info"} sx={{ mt: 2 }}>
          거리를 계산중입니다
        </Alert>
      </Collapse> */}
      {loading && <LinearProgress sx={{ mt: 3, borderRadius: 24 }} />}

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={1}
        nextButton={
          <Button disabled={loading} size="small" onClick={() => onNavigate()}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            disabled={loading}
            size="small"
            onClick={() => onNavigate(false)}
          >
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
