import { FileLoader } from "@ckeditor/ckeditor5-upload";
import { Box } from "@mui/material";
import { storage } from "../firebase";
import dayjs from "dayjs";
import {
  StorageError,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./ckeditor.css";
import Editor from "ckeditor5-custom-build";

type Props = {
  value: string;
  onChange: (data: string) => void;
};

export const TextEditor = ({ value = "", onChange }: Props) => {
  return (
    <Box width={"100%"}>
      <CKEditor
        editor={Editor}
        data={value}
        config={{ extraPlugins: [uploadImagePlugin] }}
        onChange={(_, editor) => {
          onChange(editor.getData());
        }}
        onReady={(editor) => {
          onChange(value);
        }}
      />
    </Box>
  );
};

class CustomUploadAdapter {
  private loader: FileLoader;

  constructor(loader: FileLoader) {
    this.loader = loader;
  }

  async upload() {
    return this.loader.file.then((file) => {
      return new Promise((resolve, reject) => {
        const time = dayjs().unix() % 1000000;
        const imageRef = ref(
          storage,
          `nets-board-voc-images/${time}_${file?.name}`
        );
        uploadBytes(imageRef, file!!)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              resolve({ default: url });
            });
          })
          .catch((error: StorageError) => reject(new Error(error.message)));
      });
    });
  }
  abort?(): void {
    throw new Error("upload error 2");
  }
}

const uploadImagePlugin = function (editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (
    loader: FileLoader
  ) => {
    return new CustomUploadAdapter(loader);
  };
};
