import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import {
  initBook,
  NetsType,
  netsTypes,
  PatientType,
  useBookContext,
} from "../../context/BookContext";
import { ShowRegionDialog } from "../../components/dialog";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";

type Question = {
  title: string;
  img?: string;
  option1: string;
  option2: string;
  answer1: string;
  answer2: string;
  prev: number;
};

type NetsOption = {
  [nets in NetsType]: {
    img: string;
    description: string;
  };
};

export const FindService = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const { updateBook } = useBookContext();
  const width = isPC ? "360px" : "240px";
  const imgHeight = isPC ? "240px" : "160px";

  const [index, setIndex] = useState(-1);
  const [showRegion, setShowRegion] = useState(false);
  const [selected, setSelected] = useState<NetsType>();
  const [patientType, setPatientType] = useState<PatientType | "">("");

  useEffect(() => {
    if (info.uid === "") navigate("/", { replace: true });
  }, [info]);

  const questions: Question[] = [
    {
      title: "서비스 지역 (출발지와 귀가지)",
      option1: "서울",
      option2: "경기 일부 지역",
      answer1: "next#1",
      answer2: "next#2",
      prev: -1,
    },
    {
      title: "계단이동 서비스",
      img: "/images/check_book.png",
      option1: "필요",
      option2: "필요하지 않음",
      answer1: "ok#wheel-plus",
      answer2: "next#3",
      prev: 0,
    },
    {
      title: "계단이동 서비스",
      img: "/images/check_book.png",
      option1: "필요",
      option2: "필요하지 않음",
      answer1: "ok#wheel-plus",
      answer2: "ok#wheel",
      prev: 0,
    },
    {
      title: "보호자 동행",
      option1: "보호자 동행",
      option2: "보호자 동행하지 않음",
      answer1: "next#4",
      answer2: "ok#wheel",
      prev: 1,
    },
    {
      title: "픽업 장소",
      option1: "길/주차장에서 탑승",
      option2: "문 앞/집 안으로 방문픽업 요청 (bed to bed)",
      answer1: "next#5",
      answer2: "ok#wheel",
      prev: 3,
    },
    {
      title: "개인 휠체어 사용",
      option1: "개인 휠체어 사용",
      option2: "네츠 휠체어 사용",
      answer1: "next#6",
      answer2: "ok#wheel",
      prev: 4,
    },
    {
      title: "이동",
      option1: "편도",
      option2: "왕복",
      answer1: "ok#move",
      answer2: "ok#wheel",
      prev: 5,
    },
  ];

  const netsOption: NetsOption = {
    "wheel-plus": {
      img: "/images/nets_wheel_plus.png",
      description:
        "계단 이동이 필요한 경우, 계단이동 리프트와 휠체어 전용차량이 함께 제공되는 서비스",
    },
    wheel: {
      img: "/images/nets_wheel.png",
      description:
        "휠체어 전용차량이 필요한 경우, 방문 픽업하여 차량 이동을 제공하는 서비스",
    },
    move: {
      img: "/images/nets_move.png",
      description:
        "방문 픽업 없이 택시처럼 휠체어 전용차량 이동만 제공하는 서비스",
    },
  };

  const onNext = (answer: string) => {
    const [check, option] = answer.split("#");

    if (check === "next") {
      setIndex(Number(option));
    } else if (check === "ok") {
      setSelected(option as NetsType);
    }
  };

  const onNavigate = (nav: NetsType) => {
    if (patientType === "") return;

    let book = cloneDeep(initBook);
    book.nets.type = nav;
    book.patient.type = patientType;
    book.uid.bookUser = info.uid;
    book.guardian.name = info.customer!.name;
    book.guardian.phone = info.customer!.phone;

    if (nav === "move") {
      book.bookDrive.type = "one-way";
      book.nets.elevator = false;
      book.guardian.goWith = true;
    } else if (nav === "wheel") {
      book.nets.elevator = false;
    } else {
      book.nets.elevator = true;
    }

    updateBook(book);
    navigate("/reservation/nets");
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mt={2}
      >
        <Typography fontWeight={600} fontSize={"1.5rem"} color={"primary"}>
          나에게 맞는 서비스 찾기
        </Typography>
      </Box>

      {index === -1 && (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 4, mt: 2 }}>
            <Typography color={"primary"}>
              네츠모빌리티는 과기부 특례로 병원이동(내원, 입원 및 퇴원,
              요양(재활)병원 외출) 시에만 이용 가능한 예약제 서비스입니다
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ py: 1, px: 4, mt: 1 }}>
            <Typography mt={1} color={"primary"}>
              아래 이용대상에 해당되는 고객만 서비스 이용이 가능합니다
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <FormControl sx={{ pl: 1, pt: 1 }}>
                <RadioGroup
                  value={patientType}
                  onChange={(_, value) =>
                    setPatientType(value as unknown as PatientType)
                  }
                >
                  <FormControlLabel
                    value="over65"
                    control={<Radio size="small" />}
                    label="65세 이상 고령자"
                  />
                  <FormControlLabel
                    value="disabled"
                    control={<Radio size="small" />}
                    label="장애인"
                  />
                  <FormControlLabel
                    value="limited"
                    control={<Radio size="small" />}
                    label="거동 불편"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Paper>
          <Button
            variant="contained"
            disabled={patientType === ""}
            sx={{ mt: 2 }}
            onClick={() => setIndex(0)}
          >
            서비스 찾기 시작
          </Button>
        </>
      )}
      {index >= 0 && (
        <Paper elevation={3} sx={{ py: 4, px: 2, mt: 2 }}>
          <Box display={"flex"} alignItems={"center"} gap={isPC ? 2 : 1}>
            <ArrowBackIosNewIcon
              color={index === -1 ? "disabled" : "primary"}
              sx={{
                cursor: index === -1 ? "default" : "pointer",
                mr: "-24px",
                zIndex: 10,
              }}
              onClick={() => {
                if (selected) {
                  setSelected(undefined);
                } else {
                  if (index > -1) {
                    setSelected(undefined);
                    setIndex(questions[index].prev);
                  }
                }
              }}
            />

            {selected === undefined && (
              <Box
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                gap={1}
                alignItems={"center"}
              >
                <Typography fontWeight={600} color={"primary"}>
                  {questions[index].title}
                </Typography>
                {questions[index].img && (
                  <img
                    src={questions[index].img}
                    alt=""
                    width={width}
                    height={imgHeight}
                    style={{ marginTop: 8 }}
                  />
                )}

                <Button
                  disableRipple
                  variant="outlined"
                  sx={{ width: width, mt: 2 }}
                  onClick={() => onNext(questions[index].answer1)}
                >
                  {questions[index].option1}
                </Button>
                <Button
                  disableRipple
                  variant="outlined"
                  sx={{ width: width }}
                  onClick={() => onNext(questions[index].answer2)}
                >
                  {questions[index].option2}
                </Button>

                {index === 0 && (
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography
                      mt={3}
                      color={"primary"}
                      sx={{
                        textAlign: "center",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowRegion(true)}
                    >
                      서비스 가능한 경기지역 확인하기
                    </Typography>
                    <Typography
                      color={"commonColor.warning"}
                      sx={{ wordBreak: "keep-all" }}
                    >
                      기타 수도권 지역 서비스를 원하시는 경우, 고객센터에서
                      상담해 주세요. (070-5088-2000)
                    </Typography>
                  </Box>
                )}

                {index === 3 && (
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography
                      mt={3}
                      color={"commonColor.warning"}
                      sx={{ wordBreak: "keep-all" }}
                    >
                      중증도 이상의 질환인 경우, 환자에게 인지장애가 있는
                      경우에는 보호자 탑승을 권장하며 보호자 미탑승시 이로 인해
                      발생하는 문제에 대해서는 책임지지 않습니다.
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            {selected && (
              <Box
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                gap={1}
                alignItems={"center"}
              >
                <Typography sx={{ wordBreak: "keep-all" }}>
                  더욱 안전하게 병원가는 방법으로 해당 서비스를 추천합니다
                </Typography>

                <Typography
                  fontWeight={600}
                  color={"primary"}
                  mt={2}
                  sx={{ wordBreak: "keep-all" }}
                >
                  {netsTypes[selected].label}
                </Typography>
                <img
                  src={netsOption[selected].img}
                  alt=""
                  width={width}
                  height={imgHeight}
                  style={{ marginTop: 8 }}
                />
                <Typography>{netsOption[selected].description}</Typography>

                <Button
                  variant="contained"
                  disabled={patientType === ""}
                  sx={{ width: width, mt: 2 }}
                  onClick={() => onNavigate(selected)}
                >
                  예약하기
                </Button>
              </Box>
            )}

            <ShowRegionDialog
              showRegion={showRegion}
              setShowRegion={setShowRegion}
            />
          </Box>
        </Paper>
      )}
    </>
  );
};

const q1 = () => {
  return <></>;
};
