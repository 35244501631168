import { Box, Button, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuth } from "../../context/AuthContext";
import { GeolocationType } from "../../context/BookContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";

import { useEffect, useState } from "react";
import { firestore } from "../../firebase";

export type GeolocationBookmark = GeolocationType & {
  index: number;
};

const AddressBookPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const [bookmark, setBookmark] = useState<GeolocationType[]>([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    try {
      const ref = collection(
        firestore,
        "user-info",
        info.uid,
        "bookmark_address"
      );
      const snapshot = getDocs(ref);

      const bookmarks: GeolocationBookmark[] = [];
      let maxIndex = 0;
      snapshot.then((data) => {
        for (let d of data.docs) {
          if (d.exists()) {
            const p = d.data() as GeolocationBookmark;
            bookmarks.push(p);
            if (p.index >= maxIndex) {
              maxIndex = p.index + 1;
            }
          }
        }

        setIndex(maxIndex);
        setBookmark(bookmarks);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {bookmark.map((addrBook, index) => (
        <Paper
          key={index}
          elevation={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
            px: 4,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("modify", { state: { newItem: false, addrBook } })
          }
        >
          <Typography>{addrBook.nickname}</Typography>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography fontSize={"0.9rem"} sx={{ color: "commonColor.main" }}>
              {addrBook.addr}
            </Typography>
            <NavigateNextIcon />
          </Box>
        </Paper>
      ))}
      {isPC ? (
        <Box alignSelf={"end"}>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() =>
              navigate("modify", { state: { newItem: true, index } })
            }
          >
            <AddCircleOutlineIcon />
            주소록 추가
          </Button>
        </Box>
      ) : (
        <>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() =>
              navigate("modify", { state: { newItem: true, index } })
            }
          >
            <AddCircleOutlineIcon />
            주소록 추가
          </Button>
        </>
      )}
    </Box>
  );
};

export default AddressBookPage;
