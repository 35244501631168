import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  BookState,
  PaymentType,
  PaymentWaiting,
  VBankType,
  fallsOptions,
  netsTypes,
  paymentWaitingType,
  useBookContext,
} from "../../context/BookContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ExpandMore } from "@mui/icons-material";
import { convertListsToObject, getToday } from "../../components/function";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { Coupon } from "../../model/coupon";
import { getPhoneNumberFormat } from "../../components/phoneNumberInput";
import { Loading } from "../../components/loading";

const ManageBooksDetailPage = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();
  const { book } = useBookContext();
  const [expanded, setExpanded] = useState<string | false>(false);

  const [loading, setLoading] = useState(false);

  const [paymentWaitingList, setPaymentWaitingList] = useState<
    PaymentWaiting[]
  >([]);

  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [additionalList, setAdditionalList] = useState<PaymentWaiting[]>([]);

  const [cancelList, setCancelList] = useState<PaymentWaiting[]>([]);

  useEffect(() => {
    if (book && book.uid.id !== "") {
      getPaymentsWaitingList(book.uid.id);
    } else {
      navigate("/manageBooks", { replace: true });
    }
  }, [book]);

  const getPaymentsWaitingList = (uid: string) => {
    setLoading(true);
    const ref = collection(firestore, "payments-waiting");
    const qry = query(ref, where("uid", "==", uid));
    getDocs(qry)
      .then((res) => {
        // 추가 결제/환불 정보
        let allList: PaymentWaiting[] = [];
        let aList: PaymentWaiting[] = [];
        let aAmount = 0;

        // 예약 취소 정보
        let cList: PaymentWaiting[] = [];

        for (let result of res.docs) {
          if (result.exists()) {
            let paymentWaiting = result.data() as PaymentWaiting;
            allList.push(paymentWaiting);

            if (paymentWaiting.type === "payment-additional") {
              aAmount += Number(paymentWaiting.amount);
              aList.push(paymentWaiting);
            } else if (paymentWaiting.type === "refund-part") {
              aAmount -= Number(paymentWaiting.amount);
              aList.push(paymentWaiting);
            } else if (
              paymentWaiting.type === "refund-all" ||
              paymentWaiting.type === "refund-all-additional"
            ) {
              cList.push(paymentWaiting);
            }
          }
        }

        allList.sort((a, b) => b.creationDate.seconds - a.creationDate.seconds);
        aList.sort((a, b) => a.creationDate.seconds - b.creationDate.seconds);
        cList.sort((a, _) => (a.type === "refund-all" ? -1 : 1));

        setPaymentWaitingList(allList);
        setAdditionalList(aList);
        setAdditionalAmount(aAmount);
        setCancelList(cList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Loading open={loading} />
      {book && (
        <Box p={1}>
          <BookAbstract book={book} paymentWaitingList={paymentWaitingList} />

          {book.paymentInfo.isTemp === false && (
            <Accordion expanded={expanded === "null"}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  예약번호
                </Typography>
                <Typography color={"primary"}>{book.uid.id}</Typography>
              </AccordionSummary>
            </Accordion>
          )}

          <Accordion
            expanded={expanded === "customer"}
            onChange={handleChange("customer")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                예약자 정보
              </Typography>
              <Typography color={"primary"}>{book.guardian.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3} sx={{ py: 2, px: 2 }}>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography>성함</Typography>
                  <Typography flex={1}>{book.guardian.name}</Typography>
                </Box>
              </Paper>
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography>연락처</Typography>
                  <Typography flex={1}>{book.guardian.phone}</Typography>
                </Box>
              </Paper>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "patient"}
            onChange={handleChange("patient")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                이용자
              </Typography>
              <Typography color={"primary"}>{book.patient.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3} sx={{ py: 2, px: 2 }}>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography>성함</Typography>
                  <Typography flex={1}>{book.patient.name}</Typography>
                </Box>
              </Paper>
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography>연락처</Typography>
                  <Typography flex={1}>
                    {getPhoneNumberFormat(book.patient.phone)}
                  </Typography>
                </Box>
              </Paper>
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <FormControl sx={{ pl: 1 }}>
                  <RadioGroup value={book.patient.type}>
                    <FormControlLabel
                      value="over65"
                      disabled
                      control={<Radio size="small" />}
                      label="65세 이상 고령자"
                    />
                    <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio size="small" />}
                      label="장애인"
                    />
                    <FormControlLabel
                      value="limited"
                      disabled
                      control={<Radio size="small" />}
                      label="거동이 불편한 자"
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>

              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Typography>낙상 고위험군 해당 여부</Typography>
                <FormControl sx={{ pl: 1, pt: 1 }}>
                  <FormGroup>
                    {convertListsToObject(
                      fallsOptions,
                      book.patient.fallsOptions
                    ).map((option, index) => (
                      <FormControlLabel
                        key={index}
                        label={option.value}
                        value={option.value}
                        checked={option.checked}
                        disabled
                        control={<Checkbox size="small" />}
                      />
                    ))}
                  </FormGroup>
                  {book.patient.fallsExtra !== "" && (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                      <Typography>기타</Typography>

                      <Typography flex={1}>
                        {book.patient.fallsExtra}
                      </Typography>
                    </Box>
                  )}
                </FormControl>
              </Paper>
            </AccordionDetails>
          </Accordion>

          {book.nets.type === "move" ? (
            <Accordion expanded={expanded === "null"}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  네츠
                </Typography>
                <Typography color={"primary"}>무브 (편도)</Typography>
              </AccordionSummary>
            </Accordion>
          ) : (
            <Accordion expanded={expanded === "null"}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  네츠
                </Typography>
                <Box>
                  <Typography color={"primary"}>
                    {book.nets.type === "wheel-plus"
                      ? "휠체어플러스"
                      : "휠체어"}{" "}
                    ({book.bookDrive.type === "one-way" ? "편도" : "왕복"})
                  </Typography>
                  {book.nets.type === "wheel-plus" && book.nets.elevator && (
                    <Typography color={"primary"}>
                      {book.nets.floor.label}
                    </Typography>
                  )}
                  {book.nets.bedwheel && (
                    <Typography color={"primary"}>
                      침대형 거상 휠체어
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
            </Accordion>
          )}

          {book.bookDrive.type === "round-trip" && (
            <>
              <Accordion
                expanded={expanded === "companion"}
                onChange={handleChange("companion")}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    목적지 대기
                  </Typography>
                  <Typography
                    color={"primary"}
                  >{`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}</Typography>
                  {book.companion.needHelp && (
                    <Typography color={"primary"} ml={1}>
                      {`/ 원내 동행 신청`}
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                    <Box display={"flex"} gap={2}>
                      <Typography
                        display={"flex"}
                        alignItems={"center"}
                        sx={{ width: "40%", flexShrink: 0 }}
                      >
                        동행 서비스
                      </Typography>
                      <Box>
                        {book.companion.helpOptions.map((option, index) => (
                          <Typography key={index}>{option}</Typography>
                        ))}
                      </Box>
                    </Box>
                  </Paper>
                  <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                    <Box display={"flex"} gap={2}>
                      <Typography sx={{ width: "40%", flexShrink: 0 }}>
                        기타 요청사항
                      </Typography>
                      <Typography>{book.companion.helpExtra}</Typography>
                    </Box>
                  </Paper>
                  <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                    <Box display={"flex"} gap={2}>
                      <Typography sx={{ width: "40%", flexShrink: 0 }}>
                        예정된 진료/검사 시간과 내용
                      </Typography>
                      <Typography>{book.companion.helpDiagnosis}</Typography>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>

              {book.guardian.goWith ? (
                <Accordion
                  expanded={expanded === "guardian"}
                  onChange={handleChange("guardian")}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      병원 동행인
                    </Typography>
                    <Typography color={"primary"}>
                      {book.guardian.goWithName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper elevation={3} sx={{ py: 2, px: 2 }}>
                      <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Typography>성함</Typography>
                        <Typography flex={1}>
                          {book.guardian.goWithName}
                        </Typography>
                      </Box>
                    </Paper>
                    <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                      <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Typography>연락처</Typography>
                        <Typography flex={1}>
                          {getPhoneNumberFormat(book.guardian.goWithPhone)}
                        </Typography>
                      </Box>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion expanded={expanded === "null"}>
                  <AccordionSummary
                    expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      병원 동행인
                    </Typography>
                    <Typography color={"primary"}>동행하지 않음</Typography>
                  </AccordionSummary>
                </Accordion>
              )}
            </>
          )}
          <Accordion expanded={expanded === "null"}>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                픽업지
              </Typography>
              <Typography color={"primary"}>
                {book.bookDrive.addrA.nickname !== ""
                  ? book.bookDrive.addrA.nickname
                  : `${book.bookDrive.addrA.addr}, ${book.bookDrive.addrA.addrDetail}`}
              </Typography>
            </AccordionSummary>
          </Accordion>

          <Accordion expanded={expanded === "null"}>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                목적지
              </Typography>
              <Typography color={"primary"}>
                {book.bookDrive.addrB.nickname !== ""
                  ? book.bookDrive.addrB.nickname
                  : `${book.bookDrive.addrB.addr}, ${book.bookDrive.addrB.addrDetail}`}
              </Typography>
            </AccordionSummary>
          </Accordion>

          {book.bookDrive.type === "round-trip" && (
            <Accordion expanded={expanded === "null"}>
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  귀가지
                </Typography>
                <Typography color={"primary"}>
                  {book.bookDrive.addrC.nickname !== ""
                    ? book.bookDrive.addrC.nickname
                    : `${book.bookDrive.addrC.addr}, ${book.bookDrive.addrC.addrDetail}`}
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}

          <Accordion expanded={expanded === "null"}>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ visibility: "hidden" }} />}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>배차</Typography>
              <Box>
                <Typography color={"primary"}>
                  {`픽업지 차량 도착 : ${dayjs(
                    book.bookDrive.addrA.arrival.toDate()
                  ).format("LT")}`}
                </Typography>
                <Typography color={"primary"}>
                  {`목적지 차량 도착 : ${dayjs(
                    book.bookDrive.addrB.arrival.toDate()
                  ).format("LT")}`}
                </Typography>
                {book.bookDrive.type === "round-trip" && (
                  <Typography color={"primary"}>
                    {`목적지 차량 출발 : ${dayjs(
                      book.bookDrive.addrB.arrival.toDate()
                    )
                      .add(book.companion.stayHour, "hour")
                      .add(book.companion.stayMin, "minute")
                      .format("LT")}`}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
          </Accordion>

          <Accordion
            expanded={expanded === "payment"}
            onChange={handleChange("payment")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>결제</Typography>
              <Typography color={"primary"}>
                {additionalAmount === 0
                  ? `${Number(book.paymentInfo.fee).toLocaleString()}원`
                  : `${Number(book.paymentInfo.fee).toLocaleString()}원 ${
                      additionalAmount > 0 ? "+" : "-"
                    } ${Math.abs(additionalAmount).toLocaleString()}원`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography>네츠 서비스</Typography>
                  <Typography>
                    {book.nets.netsFee.toLocaleString()}원
                  </Typography>
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    fontSize={isPC ? "0.9rem" : "0.8rem"}
                    sx={{ ml: 2 }}
                  >
                    - {netsTypes[book.nets.type].label}
                  </Typography>
                  <Typography
                    fontSize={isPC ? "0.9rem" : "0.8rem"}
                    sx={{ ml: 2 }}
                  >
                    {`${(book.nets.type === "move"
                      ? netsTypes[book.nets.type].fee
                      : netsTypes[book.nets.type][book.bookDrive.type]
                    ).toLocaleString()}원`}
                  </Typography>
                </Box>
                {book.nets.type === "wheel-plus" && (
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <Typography
                        fontSize={isPC ? "0.9rem" : "0.8rem"}
                        sx={{ ml: 2 }}
                      >
                        {`- 계단 이동 : ${book.nets.floor.label} (+ ${
                          book.nets.floor.value
                        }) ${isPC ? "(왕복시 x2)" : ""} `}
                      </Typography>
                      {!isPC && book.bookDrive.type === "round-trip" && (
                        <Typography fontSize={"0.8rem"} ml={3}>
                          (왕복시 x2)
                        </Typography>
                      )}
                    </Box>
                    <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                      {(book.bookDrive.type === "round-trip"
                        ? book.nets.floor.value * 2
                        : book.nets.floor.value
                      ).toLocaleString()}
                      원
                    </Typography>
                  </Box>
                )}
                {book.nets.bedwheel && (
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      fontSize={isPC ? "0.9rem" : "0.8rem"}
                      sx={{ ml: 2 }}
                    >
                      - 침대형 거상 이용 (+ 11,000원)
                    </Typography>
                    <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                      11,000원
                    </Typography>
                  </Box>
                )}

                {book.bookDrive.type === "round-trip" &&
                  book.companion.companionFee > 0 && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mt={1}
                    >
                      <Box>
                        <Typography>
                          목적지 대기 추가요금{" "}
                          {isPC && (
                            <Typography component="span" fontSize={"0.8rem"}>
                              (1시간 무료, 추가 20분당 8,000원)
                            </Typography>
                          )}
                        </Typography>
                        {!isPC && (
                          <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                            (1시간 무료, 추가 20분당 8,000원)
                          </Typography>
                        )}
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- ${book.companion.stayHour}시간 ${book.companion.stayMin}분 `}
                        </Typography>
                      </Box>
                      <Typography>
                        {book.companion.companionFee.toLocaleString()}원
                      </Typography>
                    </Box>
                  )}

                <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                  <Box>
                    <Typography>
                      이동거리 추가요금
                      {/* {`이동거리 추가요금
              ${(
                (bookDrive.addrA.distanceToNext +
                  bookDrive.addrB.distanceToNext) /
                1000
              ).toFixed(2)}km `} */}
                      {isPC && (
                        <Typography component="span" fontSize={"0.8rem"} ml={1}>
                          (편도당 10km 무료, 추가 5km당 11,000원)
                        </Typography>
                      )}
                    </Typography>

                    {!isPC && (
                      <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                        (편도당 10km 무료, 추가 5km당 11,000원)
                      </Typography>
                    )}
                  </Box>
                  <Typography minWidth={"80px"} align="right">
                    {(
                      book.bookDrive.addrA.feeToNext +
                      book.bookDrive.addrB.feeToNext
                    ).toLocaleString()}
                    원
                  </Typography>
                </Box>
                {book.bookDrive.addrA.feeToNext +
                  book.bookDrive.addrB.feeToNext >
                  0 && (
                  <>
                    {book.bookDrive.addrA.feeToNext > 0 && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 픽업지 ➝ 목적지: ${(
                            book.bookDrive.addrA.distanceToNext / 1000
                          ).toFixed(2)}km`}
                        </Typography>

                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`${book.bookDrive.addrA.feeToNext.toLocaleString()}원`}
                        </Typography>
                      </Box>
                    )}
                    {book.bookDrive.addrB.feeToNext > 0 && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 목적지 ➝ 귀가지: ${(
                            book.bookDrive.addrB.distanceToNext / 1000
                          ).toFixed(2)}km`}
                        </Typography>

                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`${book.bookDrive.addrB.feeToNext.toLocaleString()}원`}
                        </Typography>
                      </Box>
                    )}

                    {book.bookDrive.type === "round-trip" &&
                      book.bookDrive.sameReturn && (
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{
                            ml: 2,
                            wordBreak: "keep-all",
                            color: "commonColor.warning",
                          }}
                        >
                          * 왕복시 픽업지와 귀가지가 동일한 경우 짧은 이동거리에
                          맞춰 계산합니다
                        </Typography>
                      )}
                  </>
                )}

                {book.nets.surCharge && (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mt={1}
                    >
                      <Typography>할증 요금 (30%)</Typography>
                      <Typography>
                        {`${(
                          ((book.nets.type === "move"
                            ? netsTypes[book.nets.type].fee
                            : netsTypes[book.nets.type][book.bookDrive.type]) +
                            (book.bookDrive.type === "round-trip"
                              ? book.nets.floor.value * 2 +
                                book.companion.companionFee +
                                book.bookDrive.addrA.feeToNext +
                                book.bookDrive.addrB.feeToNext
                              : book.nets.floor.value +
                                book.bookDrive.addrA.feeToNext)) *
                          0.3
                        ).toLocaleString()}`}
                        원
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        fontSize={isPC ? "0.9rem" : "0.8rem"}
                        sx={{ ml: 2 }}
                      >
                        - {netsTypes[book.nets.type].label}
                      </Typography>
                      <Typography
                        fontSize={isPC ? "0.9rem" : "0.8rem"}
                        sx={{ ml: 2 }}
                      >
                        {`${(
                          (book.nets.type === "move"
                            ? netsTypes[book.nets.type].fee
                            : netsTypes[book.nets.type][book.bookDrive.type]) *
                          0.3
                        ).toLocaleString()}원`}
                      </Typography>
                    </Box>
                    {book.nets.type === "wheel-plus" && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                          <Typography
                            fontSize={isPC ? "0.9rem" : "0.8rem"}
                            sx={{ ml: 2 }}
                          >
                            {`- 계단 이동 : ${book.nets.floor.label} (+ ${
                              book.nets.floor.value
                            }) ${isPC ? "(왕복시 x2)" : ""} `}
                          </Typography>
                          {!isPC && book.bookDrive.type === "round-trip" && (
                            <Typography fontSize={"0.8rem"} ml={3}>
                              (왕복시 x2)
                            </Typography>
                          )}
                        </Box>
                        <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                          {(
                            (book.bookDrive.type === "round-trip"
                              ? book.nets.floor.value * 2
                              : book.nets.floor.value) * 0.3
                          ).toLocaleString()}
                          원
                        </Typography>
                      </Box>
                    )}
                    {book.bookDrive.type === "round-trip" && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 목적지에 머무는 시간 : ${book.companion.stayHour}시간 ${book.companion.stayMin}분`}
                        </Typography>
                        <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                          {(book.companion.companionFee * 0.3).toLocaleString()}
                          원
                        </Typography>
                      </Box>
                    )}

                    {book.bookDrive.type === "round-trip" ? (
                      book.bookDrive.addrA.feeToNext +
                        book.bookDrive.addrB.feeToNext >
                      0 ? (
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Box>
                            <Typography
                              fontSize={isPC ? "0.9rem" : "0.8rem"}
                              sx={{ ml: 2 }}
                            >
                              {isPC
                                ? `- 이동거리 추가요금 : ${(
                                    book.bookDrive.addrA.distanceToNext / 1000
                                  ).toFixed(2)}km + ${(
                                    book.bookDrive.addrB.distanceToNext / 1000
                                  ).toFixed(2)}km`
                                : "- 이동거리 추가요금 : "}
                            </Typography>
                            {!isPC && (
                              <Typography fontSize={"0.8rem"} ml={3}>{`${(
                                book.bookDrive.addrA.distanceToNext / 1000
                              ).toFixed(2)}km + ${(
                                book.bookDrive.addrB.distanceToNext / 1000
                              ).toFixed(2)}km`}</Typography>
                            )}
                          </Box>
                          <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                            {(
                              (book.bookDrive.addrA.feeToNext +
                                book.bookDrive.addrB.feeToNext) *
                              0.3
                            ).toLocaleString()}
                            원
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )
                    ) : book.bookDrive.addrA.feeToNext > 0 ? (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={isPC ? "0.9rem" : "0.8rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 이동거리 추가요금 : ${(
                            book.bookDrive.addrA.distanceToNext / 1000
                          ).toFixed(2)}km`}
                        </Typography>
                        <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                          {(
                            book.bookDrive.addrA.feeToNext * 0.3
                          ).toLocaleString()}
                          원
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {book.paymentInfo.discount.couponCode !== "" && (
                  <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                    <Typography>쿠폰 할인</Typography>
                    <Typography>
                      -{book.paymentInfo.discount.coupon.toLocaleString()}원
                    </Typography>
                  </Box>
                )}
                {book.paymentInfo.discount.point !== 0 && (
                  <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                    <Typography>포인트 사용</Typography>
                    <Typography>
                      -{book.paymentInfo.discount.point.toLocaleString()}원
                    </Typography>
                  </Box>
                )}
              </Paper>
              {additionalList.map((paymentWaiting, index) => (
                <Paper
                  key={paymentWaiting.creationDate.seconds + index}
                  elevation={3}
                  sx={{ py: 2, px: 2, mt: 1 }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography>
                      {paymentWaitingType[paymentWaiting.type]}
                    </Typography>
                    <Typography>
                      {`${
                        paymentWaiting.type === "refund-part" ? "-" : ""
                      }${paymentWaiting.amount.toLocaleString()}원`}
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography
                      fontSize={isPC ? "0.9rem" : "0.8rem"}
                      sx={{ ml: 2 }}
                    >
                      {`- ${timestampToDayjs(
                        paymentWaiting.creationDate
                      ).format("M월 D일")}, ${paymentWaiting.reason}`}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </AccordionDetails>
          </Accordion>

          {cancelList.length > 0 && (
            <Accordion
              expanded={expanded === "paymentCancel"}
              onChange={handleChange("paymentCancel")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  환불 금액
                </Typography>
                <Typography color={"primary"}>
                  {cancelList.length === 1
                    ? `${Number(cancelList[0].amount).toLocaleString()}원`
                    : `${Number(
                        cancelList[0].amount
                      ).toLocaleString()}원 + ${Math.abs(
                        cancelList[1].amount
                      ).toLocaleString()}원`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {cancelList.map((paymentWaiting, index) => (
                  <Paper
                    key={paymentWaiting.creationDate.seconds + index}
                    elevation={3}
                    sx={{ py: 2, px: 2, mt: 1 }}
                  >
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography>
                        {paymentWaitingType[paymentWaiting.type]}
                      </Typography>
                      <Typography>
                        {paymentWaiting.amount.toLocaleString()}원
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography>환불 비율</Typography>
                      <Typography>{paymentWaiting.refundPercent}%</Typography>
                    </Box>
                    {paymentWaiting.refundPoint > 0 && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography>포인트 반환</Typography>
                        <Typography>
                          {paymentWaiting.refundPoint.toLocaleString()}pt
                        </Typography>
                      </Box>
                    )}

                    <Box display={"flex"}>
                      <Typography
                        fontSize={isPC ? "0.9rem" : "0.8rem"}
                        sx={{ ml: 2 }}
                      >
                        {`- ${timestampToDayjs(
                          paymentWaiting.creationDate
                        ).format("M월 D일")}, ${paymentWaiting.reason}`}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {book.paymentInfo.isPaid === "ok" &&
            book.paymentInfo.bookState === "waiting" && (
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Typography>배차 완료되었습니다.</Typography>
                <Typography color={"primary"} sx={{ mt: 1 }}>
                  차량번호 {book.bookManager.car}
                </Typography>

                <Paper
                  elevation={1}
                  sx={{
                    mt: 1,
                    p: 2,
                    borderRadius: 4,
                  }}
                >
                  <Box display={"flex"} gap={3}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={1}
                    >
                      <Avatar
                        sx={{ width: 64, height: 64 }}
                        src={book.bookManager.photo}
                      >
                        사진
                      </Avatar>
                      <Typography sx={{ textWrap: "nowrap" }}>
                        {book.bookManager.name} 매니저
                      </Typography>
                    </Box>

                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: book.bookManager.description,
                      }}
                      sx={{ "& > figure > img": { width: "100%" } }}
                    ></Typography>
                  </Box>
                </Paper>
              </Paper>
            )}
          {book.paymentInfo.isPaid === "ok" &&
            book.paymentInfo.bookState === "waiting" && (
              <Box mt={2} display={"flex"} gap={1}>
                <Button
                  disabled={
                    book.paymentInfo.discount.couponCode.startsWith("PP") ||
                    timestampToDayjs(book.bookDrive.addrA.arrival).format(
                      "YYMMDD"
                    ) <= dayjs().format("YYMMDD")
                  }
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    navigate("../manageBooks/postpone", {
                      state: { additionalAmount },
                    })
                  }
                >
                  {book.paymentInfo.discount.couponCode.startsWith("PP")
                    ? "예약 연기는 1회만 가능"
                    : "예약 연기"}
                </Button>
                <Button
                  disabled={
                    timestampToDayjs(book.bookDrive.addrA.arrival).format(
                      "YYMMDD"
                    ) <= dayjs().format("YYMMDD")
                  }
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    navigate("../manageBooks/cancel", {
                      state: { additionalAmount },
                    })
                  }
                >
                  예약 취소
                </Button>
              </Box>
            )}
        </Box>
      )}
    </>
  );
};

const BookAbstract = ({
  book,
  paymentWaitingList, // 생성시간 역순으로 정렬되어 있음
}: {
  book: BookState;
  paymentWaitingList: PaymentWaiting[];
}) => {
  const [vbank, setVBank] = useState<VBankType>();
  const [coupon, setCoupon] = useState<Coupon>();

  const [paymentWaiting, setPaymentWaiting] = useState<PaymentWaiting>();

  useEffect(() => {
    // 신규 예약 발생 시
    if (book.paymentInfo.isPaid === "waiting") {
      if (book.paymentInfo.paymentType === "account") {
        getVBank();
      }
    }

    if (
      book.paymentInfo.bookState === "postpone-done" &&
      book.paymentInfo.discount.couponRef
    ) {
      getPostponeCoupon();
    }
  }, []);

  useEffect(() => {
    if (paymentWaitingList.length > 0) {
      setAdditionalInfo();
    }
  }, [paymentWaitingList]);

  const setAdditionalInfo = () => {
    const isPaid = book.paymentInfo.isPaid;
    if (isPaid === "modified-additional") {
      for (let item of paymentWaitingList) {
        if (item.type === "payment-additional") {
          setPaymentWaiting(item);
          break;
        }
      }
    } else if (isPaid === "modified-refund") {
      for (let item of paymentWaitingList) {
        if (item.type === "refund-part") {
          setPaymentWaiting(item);
          break;
        }
      }
    }
  };

  const getVBank = async () => {
    let merchant_uid = book.paymentInfo.merchant_uid.split("#")[0];
    const response = await getDoc(
      doc(firestore, "books-payment-vbank", merchant_uid)
    );
    if (response.exists()) {
      const data = response.data() as VBankType;
      setVBank(data);
    }
  };

  const getPostponeCoupon = async () => {
    const response = await getDoc(book.paymentInfo.discount.couponRef!);
    if (response.exists()) {
      const data = response.data() as Coupon;
      setCoupon(data);
    }
  };

  // 서비스 대기중
  if (book.paymentInfo.bookState === "waiting") {
    if (book.paymentInfo.isPaid === "waiting") {
      // 계좌이체 입금 대기
      if (book.paymentInfo.paymentType === "accountTransfer") {
        return (
          <>
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
              <Typography color={"primary"} fontWeight={"600"}>
                [예약이 확정되지 않았습니다]
              </Typography>
              <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                계좌 입금 대기 중입니다. 아래 계좌로 안내 받은 입금 시간 내에
                입금해 주세요.
              </Typography>
              <Typography
                color={"commonColor.warning"}
                sx={{ wordBreak: "keep-all" }}
              >
                시간 내에 미입금시 예약은 자동으로 취소되며, 배차가 마감된 경우
                재예약이 불가능할 수 있습니다.
              </Typography>

              <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
                <Typography width={"150px"}>은행</Typography>
                <Typography color={"primary"}>KEB하나은행</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>예금주</Typography>
                <Typography color={"primary"}>네츠모빌리티</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>계좌번호</Typography>
                <Typography color={"primary"}>151-910014-09504</Typography>
              </Box>
            </Paper>
          </>
        );
      }

      // url 결제
      else if (
        book.paymentInfo.paymentUrl &&
        book.paymentInfo.paymentUrlExpiredAt
      ) {
        return (
          <>
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
              <Typography color={"primary"} fontWeight={"600"}>
                [예약이 확정되지 않았습니다]
              </Typography>
              <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                결제 대기 중입니다. 결제 시간 내에 결제를 진행해주세요.
              </Typography>
              <Typography
                color={"commonColor.warning"}
                sx={{ wordBreak: "keep-all" }}
              >
                시간 내에 결제가 완료되지 않으면 예약은 자동으로 취소되며,
                배차가 마감된 경우 재예약이 불가능할 수 있습니다.
              </Typography>

              <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
                <Typography width={"150px"}>결제 링크</Typography>
                <Typography color={"primary"}>
                  {book.paymentInfo.paymentUrl}
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>결제 기한</Typography>
                <Typography color={"primary"}>{`${dayjs
                  .unix(book.paymentInfo.paymentUrlExpiredAt as number)
                  .format("ll LT")}까지`}</Typography>
              </Box>
            </Paper>
          </>
        );

        // 가상계좌 입금 대기
      } else if (book.paymentInfo.paymentType === "account" && vbank) {
        return (
          <>
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
              <Typography color={"primary"} fontWeight={"600"}>
                [예약이 확정되지 않았습니다]
              </Typography>
              <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                가상계좌 입금 대기중입니다. 아래 계좌로 입금 시간 내에 입금해
                주세요.
              </Typography>
              <Typography
                color={"commonColor.warning"}
                sx={{ wordBreak: "keep-all" }}
              >
                시간 내에 미입금시 예약은 자동으로 취소되며, 배차가 마감된 경우
                재예약이 불가능할 수 있습니다.
              </Typography>

              <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
                <Typography width={"150px"}>은행</Typography>
                <Typography color={"primary"}>{vbank.vbank_name}</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>예금주</Typography>
                <Typography color={"primary"}>(주)네츠모빌리티</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>계좌번호</Typography>
                <Typography color={"primary"}>{vbank.vbank_num}</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>입금 기한</Typography>
                <Typography color={"primary"}>{`${dayjs
                  .unix(vbank.vbank_date)
                  .format("ll LT")} 까지`}</Typography>
              </Box>
            </Paper>
          </>
        );
      } else {
        return <></>;
      }
    } else if (book.paymentInfo.isPaid === "modified-additional") {
      return (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
            {book.bookDrive.addrA.arrival.seconds > getToday().unix() ? (
              <>
                <Typography color={"primary"} fontWeight={"600"}>
                  [추가 결제 대기 중입니다]
                </Typography>
                <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                  요청하신 서비스 변경을 희망하시는 경우 안내 받은 시간 내에
                  결제 부탁드립니다.
                </Typography>
                <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                  결제가 진행되지 않는 경우 요청하신 서비스가 적용되지 않습니다.
                </Typography>
              </>
            ) : (
              // 서비스 중 발생한 추가요금
              <>
                <Typography color={"primary"} fontWeight={"600"}>
                  [초과 요금 결제 대기 중입니다]
                </Typography>
                <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
                  금일 이용하신 서비스 시간이 초과되어 추가 결제 대기중입니다.
                </Typography>
              </>
            )}

            <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
              <Typography width={"150px"}>은행</Typography>
              <Typography color={"primary"}>KEB하나은행</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>예금주</Typography>
              <Typography color={"primary"}>네츠모빌리티</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>계좌번호</Typography>
              <Typography color={"primary"}>151-910014-09504</Typography>
            </Box>
            {paymentWaiting && (
              <>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography width={"150px"}>결제금액</Typography>
                  <Typography color={"primary"}>
                    {paymentWaiting.amount.toLocaleString()}
                  </Typography>
                </Box>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography width={"150px"}>사유</Typography>
                  <Typography color={"primary"}>
                    {paymentWaiting.reason}
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </>
      );
    } else if (book.paymentInfo.isPaid === "modified-refund") {
      return (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
            <Typography color={"primary"} fontWeight={"600"}>
              [환불 대기중입니다]
            </Typography>
            <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
              변경된 서비스에 대한 부분 환불 처리 중입니다.
            </Typography>
            <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
              무통장입금으로 결제하신 경우 카카오톡 @네츠모빌리티 고객센터로
              계좌번호 전달부탁드립니다.
            </Typography>
            <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
              카드결제/페이 결제하신 경우 결제하신 수단으로 환불 처리
              진행됩니다.
            </Typography>

            {paymentWaiting && (
              <>
                <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
                  <Typography width={"150px"}>환불금액</Typography>
                  <Typography color={"primary"}>
                    {paymentWaiting.amount.toLocaleString()}
                  </Typography>
                </Box>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography width={"150px"}>사유</Typography>
                  <Typography color={"primary"}>
                    {paymentWaiting.reason}
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </>
      );
    } else if (book.paymentInfo.isPaid === "ok") {
      return (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
            <Typography color={"primary"}>
              {`${timestampToDayjs(book.bookDrive.addrA.arrival).format(
                "ll"
              )} 예약 내역입니다.`}
            </Typography>
          </Paper>
        </>
      );
    } else {
      return <></>;
    }
  } else if (book.paymentInfo.bookState === "cancel-request") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"} fontWeight={"600"}>
            [예약 취소 대기중입니다]
          </Typography>
          <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
            무통장입금으로 결제하신 경우 카카오톡 @네츠모빌리티 고객센터로
            계좌번호 전달부탁드립니다.
          </Typography>
          <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
            카드결제/페이 결제하신 경우 결제하신 수단으로 환불 처리 진행됩니다.
          </Typography>
        </Paper>
      </>
    );
  } else if (book.paymentInfo.bookState === "cancel-done") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"}>
            {`${timestampToDayjs(book.bookDrive.addrA.arrival).format(
              "ll"
            )} 예약된 서비스가 취소되었습니다.`}
          </Typography>
        </Paper>
      </>
    );
  } else if (book.paymentInfo.bookState === "postpone-request") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"} fontWeight={"600"}>
            [쿠폰 발급 대기 중입니다]
          </Typography>
          <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
            연기하신 예약에 대한 내용을 확인하고 있습니다.
          </Typography>
          <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
            담당자 확인 후 쿠폰 번호 전달해드릴 예정입니다.
          </Typography>
          <Typography color={"primary"} sx={{ wordBreak: "keep-all" }}>
            발급해드리는 쿠폰은 1회에 한하여 사용이 가능하며, 쿠폰을 사용한
            예약을 취소하는 경우에는 환불되지 않습니다.
          </Typography>
        </Paper>
      </>
    );
  } else if (book.paymentInfo.bookState === "postpone-done") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"}>
            {`${timestampToDayjs(book.bookDrive.addrA.arrival).format(
              "ll"
            )}에 예약된 서비스가 연기되었습니다.`}
          </Typography>
          {coupon && (
            <>
              <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
                <Typography width={"150px"}>쿠폰번호</Typography>
                <Typography color={"primary"}>{coupon.code}</Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>쿠폰금액</Typography>
                <Typography color={"primary"}>
                  {coupon.amount.toLocaleString()}원
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <Typography width={"150px"}>유효기간</Typography>
                <Typography color={"primary"}>{`${timestampToDayjs(
                  coupon.endDate
                ).format("ll LT")}까지`}</Typography>
              </Box>
            </>
          )}
        </Paper>
      </>
    );
  } else if (book.paymentInfo.bookState === "service-done") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"}>
            {`${timestampToDayjs(book.bookDrive.addrA.arrival).format(
              "ll"
            )}에 예약된 서비스가 완료되었습니다.`}
          </Typography>
        </Paper>
      </>
    );
  } else if (book.paymentInfo.bookState === "no-paid-cancel") {
    return (
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"}>
            {`${timestampToDayjs(book.bookDrive.addrA.arrival).format(
              "ll"
            )}에 예약된 서비스가 기한 내에 요금이 결제되지 않아 취소되었습니다.`}
          </Typography>
        </Paper>
      </>
    );
  } else {
    return <></>;
  }
};

export default ManageBooksDetailPage;
