import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useBookContext } from "../../../context/BookContext";
import { CollapseMessage } from "../../../components/collapseMessage";
import { useAuth } from "../../../context/AuthContext";
import { getToday } from "../../../components/function";
import { onFindCar } from "../../../apiCallFunctions/onFindCar";

export const FindCar = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();
  const { book } = useBookContext();

  const today = getToday();
  const minDate = today.hour() > 18 ? today.add(2, "day") : today.add(1, "day");
  const [day, setDay] = useState<Dayjs>(minDate);

  const minTime = today.set("h", 6).set("m", 30).set("second", 0);
  const maxTime = today.set("h", 19).set("m", 0).set("second", 59);
  const [time, setTime] = useState<Dayjs>(minTime);
  const [hour, setHour] = useState("06");
  const [min, setMin] = useState("30");

  const hourLists = new Array(14)
    .fill(0)
    .map((_, index) => String(index + 6).padStart(2, "0"));
  const minLists = new Array(60)
    .fill(0)
    .map((_, index) => String(index).padStart(2, "0"));

  const [helpDiagnosis, setHelpDiagnosis] = useState(
    book.companion.helpDiagnosis
  );

  const [loading, setLoading] = useState(false);
  const [noCar, setNoCar] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }
  }, []);

  // const isValidExp = (time: string) => {
  //   const regEx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  //   return regEx.test(time);
  // };

  const findCar = () => {
    if (helpDiagnosis === "") {
      setMessage("병원 예약 시간과 내용을 입력해주세요.");
      return;
    }

    setLoading(true);

    book.companion.helpDiagnosis = helpDiagnosis;
    const bookDate = day
      .set("hour", time.hour())
      .set("minute", time.minute())
      .format("YYMMDDHHmm");

    try {
      onFindCar(book, [bookDate], book.type).then((result) => {
        for (let item of result) {
          if (item.ok === "none") {
            setNoCar(true);
            setLoading(false);
            return;
          } else if (item.ok === "error") {
            console.log(item);
            setMessage(item.message);
            setLoading(false);
            return;
          }
        }
        setLoading(false);
        navigate("../findcarResult", { state: { result, bookDate } });
      });
    } catch (error: any) {
      setLoading(false);
      setMessage(error.response.data.message);
    }
  };

  return (
    <Box p={1}>
      {
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
            <Typography color={"primary"}>목적지 방문일시 입력</Typography>
            <Typography color={"primary"}>(3개월 이내 예약만 가능)</Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography width={isPC ? "160px" : "80px"}>방문 일자</Typography>
              <DatePicker
                value={day}
                minDate={minDate}
                maxDate={today.add(89, "day")}
                slotProps={{
                  textField: {
                    helperText: (
                      <>
                        당일 예약은 불가능합니다.
                        <br />
                        19시 이후에는 익일 예약이 불가능합니다.
                      </>
                    ),
                  },
                }}
                onChange={(value) => setDay(value || dayjs().add(1, "day"))}
                sx={{ flex: 1, ".MuiInputBase-input": { px: 2, py: 1.5 } }}
              />
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography
                width={isPC ? "160px" : "80px"}
                sx={{ wordBreak: "keep-all" }}
              >
                목적지 차량 도착시간
              </Typography>

              <Box display={"flex"} alignItems={"center"} gap={1}>
                <FormControl>
                  <Select
                    value={hour}
                    sx={{ ".MuiInputBase-input": { px: 2, py: 1 } }}
                    onChange={(event) => {
                      const h = event.target.value as unknown as string;
                      const t = h + min;
                      if (t < minTime.format("HHmm")) {
                        setHour("06");
                        setMin("30");
                        setTime(minTime);
                      } else if (t > maxTime.format("HHmm")) {
                        setHour("19");
                        setMin("00");
                        setTime(maxTime);
                      } else {
                        setHour(h);
                        setTime(time.set("h", Number(h)));
                      }
                    }}
                  >
                    {hourLists.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                시
              </Box>

              <Box display={"flex"} alignItems={"center"} gap={1}>
                <FormControl>
                  <Select
                    value={min}
                    sx={{ ".MuiInputBase-input": { px: 2, py: 1 } }}
                    onChange={(event) => {
                      const m = event.target.value as unknown as string;
                      const t = hour + m;
                      if (t < minTime.format("HHmm")) {
                        setHour("06");
                        setMin("30");
                        setTime(minTime);
                      } else if (t > maxTime.format("HHmm")) {
                        setHour("19");
                        setMin("00");
                        setTime(maxTime);
                      } else {
                        setMin(m);
                        setTime(time.set("m", Number(m)));
                      }
                    }}
                  >
                    {minLists.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        disabled={
                          (hour <= "06" && item < "30") ||
                          (hour >= "19" && item !== "00")
                        }
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                분
              </Box>
            </Box>
            <Typography
              sx={{
                mt: 1,
                ml: 0,
                fontWeight: 600,
                fontSize: "0.9rem",
                color: "commonColor.warning",
              }}
            >
              ※ 병원 예약시간보다 여유롭게 설정해주세요.
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>병원 예약 시간과 내용</Typography>
            <Box display={"flex"} ml={1} mt={1}>
              <TextField
                variant="standard"
                value={helpDiagnosis}
                placeholder={"예) 14:00 예약 내용"}
                onChange={(event) => setHelpDiagnosis(event.target.value)}
                sx={{ flex: 1 }}
              />
            </Box>
          </Paper>

          {book.bookDrive.type === "round-trip" && (
            <>
              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Typography
                    width={isPC ? "160px" : "80px"}
                    sx={{ wordBreak: "keep-all" }}
                  >
                    목적지에서 머무는 시간
                  </Typography>

                  <Typography
                    sx={{ ml: 2 }}
                  >{`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}</Typography>
                </Box>
              </Paper>

              <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Typography
                    width={isPC ? "160px" : "80px"}
                    sx={{ wordBreak: "keep-all" }}
                  >
                    차량 출발시간
                  </Typography>

                  <Typography sx={{ ml: 2 }}>{`${time
                    .add(book.companion.stayHour, "h")
                    .add(book.companion.stayMin, "m")
                    .format("HH:mm")}`}</Typography>
                </Box>
              </Paper>
            </>
          )}

          <CollapseMessage
            type="info"
            message={message || ""}
            open={message !== undefined}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 2 }}
          />

          <Button
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{ mt: 2, fontWeight: "600", fontSize: "1.1rem" }}
            // onClick={() => onTemp()}
            onClick={() => findCar()}
          >
            {loading ? "배차 검색 중" : "배차 가능 여부 확인"}
          </Button>

          {loading && <LinearProgress sx={{ mt: -0.5, borderRadius: 24 }} />}
        </>
      }
      {!loading && noCar && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"commonColor.alert"}>
            해당 시간에는 배차 가능한 차량이 없습니다.
          </Typography>
          <Typography color={"commonColor.alert"}>
            다른 시간을 검색하시거나, 고객센터(070-5088-2000)로 문의주세요
          </Typography>
        </Paper>
      )}

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={4}
        nextButton={
          <Button size="small" disabled={true} onClick={() => {}}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => navigate("../checkinfo", { replace: true })}
          >
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
