import { useEffect } from "react";

export const usePreventRefresh = () => {
  useEffect(() => {
    (() => {
      window.addEventListener("beforeunload", preventRefresh);
    })();

    return () => {
      window.removeEventListener("beforeunload", preventRefresh);
    };
  }, []);

  const preventRefresh = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    console.log("refresh");
    // e.returnValue = ''
  };
};
