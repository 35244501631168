import { Box } from "@mui/material";

export const InfoTermsPage = () => {
  return (
    <Box sx={{ "& > img": { maxWidth: "100%" } }}>
      <img src={"/images/terms_1.jpg"} alt="네츠 서비스 정책" />
      <img src={"/images/terms_2.jpg"} alt="네츠 서비스 정책" />
    </Box>
  );
};
