import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import "dayjs/locale/ko";
import AppRouter from "./router";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <AppRouter />
    </LocalizationProvider>
  );
}

export default App;
