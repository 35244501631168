import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
  useState,
} from "react";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { dayjsToTimestamp } from "../components/dispatchFunctions";
import { getToday } from "../components/function";

export type PaymentCancelType = {
  bookUid: string;
  userUid: string;
  name: string;
  refundPercent: number; // 환불 비율
  refundAmount: number; // 환불 금액(현금)
  refundPoint: number; // 환불 금액(포인트)
  refundCoupon?: DocumentReference; // 반환될 쿠폰 정보
  refundType: PaymentType; // 결제 방법
  refundBank: string; // (가상계좌용) 환불받을 은행
  refundBankAccount: string; // (가상계좌용) 환불받을 계좌
  refundBankHolder: string; // (가상계좌용) 환불받을 계좌 예금주
  reason: string;
  creationDate: Timestamp;
};

export type VBankType = {
  vbank_date: number;
  vbank_num: string;
  vbank_name: string;
};

export const helpOptions = [
  { value: "병원 내 휠체어 이동", checked: false },
  { value: "진료실 동행", checked: false },
  { value: "지정한 보호자에게 진료 내용 전달", checked: false },
  { value: "지정한 보호자에게 서류 촬영 전달", checked: false },
  { value: "약국 동행", checked: false },
];

export type CheckboxOption = { value: string; checked: boolean };
export type FloorOption = { index: number; label: string; value: number };
export const floorOptions: FloorOption[] = [
  { index: 0, label: "1 ~ 5층", value: 0 },
  { index: 1, label: "6 ~ 10층", value: 11000 },
  { index: 2, label: "11 ~ 15층", value: 22000 },
  { index: 3, label: "16층 이상 (관리자 문의: 070-5088-2000)", value: 0 },
];

export type FallsOption = { value: string; checked: boolean };
export const fallsOptions: FallsOption[] = [
  { value: "최근 6개월 이내 낙상 경험이 있다", checked: false },
  { value: "급박 또는 빈번한 실금이 있다", checked: false },
  {
    value:
      "해당 약물을 복용중이다 (자가통증조절/마약제, 항경련제, 항고혈압제, 이뇨제, 수면제, 완하제, 진정제, 향정신약물)",
    checked: false,
  },
];

export type NetsType = "wheel-plus" | "wheel" | "move";
export const netsTypes = {
  "wheel-plus": {
    "one-way": 99000,
    "round-trip": 132000,
    label: "네츠 휠체어플러스",
  },
  wheel: {
    "one-way": 88000,
    "round-trip": 110000,
    label: "네츠 휠체어",
  },
  move: {
    fee: 66000,
    label: "네츠 무브",
  },
};

export type UseLiftAt = "begin" | "end";

export type NetsService = {
  type: NetsType;
  elevator: boolean;
  useLiftAt: UseLiftAt;
  floor: FloorOption;
  bedwheel: boolean;
  netsFee: number;
  surCharge: boolean;
};

export type GeolocationType = {
  addr: string;
  addrDetail: string;
  nickname: string;
  arrival: Timestamp;
  latitude: number;
  longitude: number;
  distanceToNext: number;
  durationToNext: number;
  feeToNext: number;
};

export type BookDriveType = "one-way" | "round-trip";
export type BookDrive = {
  type: BookDriveType;
  sameReturn: boolean;
  addrA: GeolocationType;
  addrB: GeolocationType;
  addrC: GeolocationType;
  serviceDone: Timestamp;
  addrNext: GeolocationType;
  addrPrev: GeolocationType;
  prevUid: string;
  nextUid: string;
};

export type Companion = {
  stayHour: number;
  stayMin: number;
  companionFee: number;
  needHelp: boolean;
  helpOptions: string[];
  helpExtra: string;
  helpDiagnosis: string;
};

export type BookStateType =
  | "waiting"
  | "service-done"
  | "cancel-request"
  | "cancel-done"
  | "postpone-request"
  | "postpone-done"
  | "no-paid-cancel";

export const bookStateTypes = {
  waiting: "서비스 대기",
  "service-done": "서비스 완료",
  "cancel-request": "취소 요청",
  "cancel-done": "취소 완료",
  "postpone-request": "연기 요청",
  "postpone-done": "연기 완료",
  "no-paid-cancel": "미입금 취소",
};

export type PatientType = "over65" | "disabled" | "limited";
export type PatientSubmitType = "kakao" | "email";
export type Patient = {
  name: string;
  nickname: string;
  phone: string;
  type: PatientType;
  birthday: Timestamp;
  howToSubmit: PatientSubmitType;
  fallsOptions: string[];
  fallsExtra: string;
};

export type Guardian = {
  name: string; // 예약자 이름
  phone: string; // 예약자 연락처
  goWith: boolean;
  goWithName: string; // 동행할 보호자 이름
  goWithPhone: string; // 동행할 보호자 연락처
};

export type Discount = {
  couponCode: string;
  couponDescription: string;
  couponRef?: DocumentReference;
  coupon: number; // 쿠폰으로 할인된 금액
  point: number;
};

export type PaymentWaiting = {
  uid: string; // 예약 id
  name?: string; // 해당 금액변경 데이터를 만든 사람 (고객, 관리자)
  type: PaymentWaitingType; // 종류
  paymentType?: PaymentType; // 결제 방법
  amount: number; // 금액 (절대값)
  refundPercent: number; // 환불 비율
  refundPoint: number; // 환불 금액(포인트)
  refundCoupon?: DocumentReference; // 반환될 쿠폰 정보
  reason: string; // 발생 사유
  isDone: boolean; // 해당 건이 처리가 완료되었는지 여부
  bankCode: string; // (환불시) 은행 종류
  bankAccountHolder: string; // (환불시) 예금주
  bankAccount: string; // (환불시) 계좌번호
  creationDate: Timestamp; // 발생일시
};

export type PaymentWaitingType =
  | "payment-new"
  | "payment-additional"
  | "book-modified"
  | "refund-all"
  | "refund-all-additional"
  | "refund-part"
  | "refund-coupon";

export const paymentWaitingType = {
  "payment-new": "신규 결제",
  "payment-additional": "추가 결제",
  "book-modified": "단순 변경",
  "refund-all": "예약 취소 환불",
  "refund-all-additional": "추가 결제 환불",
  "refund-part": "부분 환불",
  "refund-coupon": "예약 연기",
};

export type IsPaidType =
  | "waiting"
  | "ok"
  | "modified-additional"
  | "modified-refund"
  | "refund-request"
  | "refund-done"
  | "postpone-request"
  | "postponed"
  | "no-paid";

export const isPaidTypes = {
  waiting: "신규결제 대기", // 신규 예약이었을 경우에만 waiting으로 들어감
  ok: "결제 완료",
  "modified-additional": "예약변경-결제중", // 예약 변경으로 인한 금액 변동(추가결제). 결제 완료시 ok로 변경
  "modified-refund": "예약변경-환불중", // 예약 변경으로 인한 금액 변동(환불). 환불 완료시 ok로 변경
  "refund-request": "환불 대기",
  "refund-done": "환불 완료",
  "postpone-request": "연기 요청",
  postponed: "연기 완료",
  "no-paid": "미입금 취소",
};

export type PaymentInfo = {
  totalFee: number;
  imp_uid: string;
  merchant_uid: string;
  discount: Discount;
  fee: number;
  paymentType: PaymentType;
  isTemp: boolean;
  isPaid: IsPaidType;
  cashReceiptsDone: boolean;
  cashReceiptsNumber?: string;
  bookState: BookStateType;
  paymentUrl?: string;
  paymentUrlExpiredAt?: number; // unix time 초
};

export type BookPaymentRedirect = PaymentInfo & {
  book_uid: string;
};

export type BookManager = {
  uid: string;
  email: string;
  name: string;
  phone: string;
  car: string;
  photo: string;
  description: string;
  addr: string;
  addrDetail: string;
  latitude: number;
  longitude: number;
};

export type ManagerWorks = {
  uid: string;
  numOfWork: number;
  minutesOfWork: number;
  numOfBreak: number;
  minutesOfBreak: number;
  [month: number]: {
    numOfWork: number;
    minutesOfWork: number;
    numOfBreak: number;
    minutesOfBreak: number;
    [date: number]: {
      numOfWork: number;
      minutesOfWork: number;
      numOfBreak: number;
      minutesOfBreak: number;
    };
  };
};

export type PaymentType = "kakaopay" | "card" | "account" | "accountTransfer";
export type Uid = {
  date: string;
  seq: number;
  id: string;
  bookUser: string;
  multiId?: string;
};
export type BookType = "single" | "multi";
export type BookState = {
  uid: Uid;
  type: BookType;
  nets: NetsService;
  bookDrive: BookDrive;
  companion: Companion;
  patient: Patient;
  guardian: Guardian;
  paymentInfo: PaymentInfo;
  bookManager: BookManager;
  memoToManager: string;
  isBreakTime: boolean;
  isCancelled: boolean;
  creationDate: Timestamp;
};

type ContextType = {
  testMode: boolean;
  book: BookState;
  multiBooks: BookState[];
  multiBookUids: string[];
  // holidays: string[];
  updateBook: (book: BookState) => void;
  updateNets: (nets: NetsService) => void;
  updateBookDrive: (bookDrive: BookDrive) => void;
  updateCompanion: (companion: Companion) => void;
  updatePatient: (patient: Patient) => void;
  updateGuardian: (guardian: Guardian) => void;
  updatePaymentInfo: (paymentInfo: PaymentInfo, uid: Uid) => void;
  updateBookManager: (BookManager: BookManager) => void;
  updateMultiBookUids: (uids: string[], books: BookState[]) => void;
};

const testMode = false;
// const tomorrow = dayjsToTimestamp(dayjs().add(1, "day"));
const today = getToday();
const tomorrow =
  today.hour() > 18
    ? dayjsToTimestamp(today.add(2, "day"))
    : dayjsToTimestamp(today.add(1, "day"));

export const initBookAddr = {
  addr: "",
  addrDetail: "",
  nickname: "",
  arrival: tomorrow,
  latitude: 0.0,
  longitude: 0.0,
  distanceToNext: 0,
  durationToNext: 86400,
  feeToNext: 0,
};

export const testInit: BookState = {
  uid: { date: "", seq: 0, id: "", bookUser: "" },
  type: "single",
  nets: {
    type: "wheel-plus",
    elevator: true,
    useLiftAt: "begin",
    floor: floorOptions[2],
    bedwheel: true,
    netsFee: 187000,
    surCharge: true,
  },
  bookDrive: {
    type: "round-trip",
    prevUid: "",
    nextUid: "",
    sameReturn: true,
    addrA: {
      addr: "경기도 수원시 팔달구 덕영대로 924",
      addrDetail: "수원역 4번 출구",
      nickname: "수원역 4번 출구",
      arrival: tomorrow,
      latitude: 37.2656768667795,
      longitude: 127.000096053009,
      distanceToNext: 11353,
      durationToNext: 898,
      feeToNext: 11000,
    },
    addrB: {
      addr: "경기도 수원시 영통구 동탄원천로881번길 35",
      addrDetail: "504동 401호",
      nickname: "우리집",
      arrival: tomorrow,
      latitude: 37.2550097156813,
      longitude: 127.041506632289,
      distanceToNext: 11105,
      durationToNext: 978,
      feeToNext: 11000,
    },

    addrC: {
      addr: "경기도 수원시 팔달구 덕영대로 924",
      addrDetail: "수원역 4번 출구",
      nickname: "수원역 4번 출구",
      arrival: tomorrow,
      latitude: 37.2656768667795,
      longitude: 127.000096053009,
      distanceToNext: 0,
      durationToNext: 0,
      feeToNext: 0,
    },
    serviceDone: tomorrow,
    addrNext: { ...initBookAddr },
    addrPrev: { ...initBookAddr },
  },
  companion: {
    stayHour: 1,
    stayMin: 40,
    companionFee: 16000,
    needHelp: false,
    helpOptions: [],
    helpExtra: "",
    helpDiagnosis: "",
  },
  patient: {
    name: "",
    nickname: "",
    phone: "",
    birthday: Timestamp.now(),
    type: "over65",
    howToSubmit: "kakao",
    fallsOptions: [],
    fallsExtra: "",
  },
  guardian: {
    name: "",
    phone: "",
    goWith: true,
    goWithName: "",
    goWithPhone: "",
  },
  paymentInfo: {
    isTemp: true,
    isPaid: "waiting",
    bookState: "waiting",
    cashReceiptsDone: false,
    imp_uid: "",
    merchant_uid: "",
    totalFee: 289200,
    discount: {
      couponCode: "ABC123",
      couponDescription: "",
      coupon: 10000,
      point: 10000,
    },
    fee: 269200,
    paymentType: "card",
  },
  bookManager: {
    uid: "",
    email: "",
    name: "",
    phone: "",
    car: "",
    photo: "",
    description: "",
    addr: "",
    addrDetail: "",
    latitude: 0.0,
    longitude: 0.0,
  },
  memoToManager: "",
  isBreakTime: false,
  isCancelled: false,
  creationDate: Timestamp.now(),
};

export const initBook: BookState = {
  uid: { date: "", seq: 0, id: "", bookUser: "" },
  type: "single",
  nets: {
    type: "wheel-plus",
    elevator: true,
    useLiftAt: "begin",
    floor: floorOptions[0],
    bedwheel: false,
    netsFee: 0,
    surCharge: false,
  },
  bookDrive: {
    type: "round-trip",
    prevUid: "",
    nextUid: "",
    sameReturn: true,
    addrA: { ...initBookAddr },
    addrB: { ...initBookAddr },
    addrC: { ...initBookAddr },
    serviceDone: tomorrow,
    addrNext: { ...initBookAddr },
    addrPrev: { ...initBookAddr },
  },
  companion: {
    stayHour: 1,
    stayMin: 0,
    companionFee: 0,
    needHelp: false,
    helpOptions: [],
    helpExtra: "",
    helpDiagnosis: "",
  },
  patient: {
    name: "",
    nickname: "",
    phone: "",
    birthday: Timestamp.now(),
    type: "over65",
    howToSubmit: "kakao",
    fallsOptions: [],
    fallsExtra: "",
  },
  guardian: {
    name: "",
    phone: "",
    goWith: true,
    goWithName: "",
    goWithPhone: "",
  },
  paymentInfo: {
    imp_uid: "",
    merchant_uid: "",
    totalFee: 0,
    discount: {
      couponCode: "",
      couponDescription: "",
      coupon: 0,
      point: 0,
    },
    fee: 0,
    paymentType: "card",
    isTemp: true,
    isPaid: "waiting",
    bookState: "waiting",
    cashReceiptsDone: false,
  },
  bookManager: {
    uid: "",
    email: "",
    name: "",
    car: "",
    phone: "",
    photo: "",
    description: "",
    addr: "",
    addrDetail: "",
    latitude: 0.0,
    longitude: 0.0,
  },
  memoToManager: "",
  isBreakTime: false,
  isCancelled: false,
  creationDate: Timestamp.now(),
};

const BookContext = createContext<ContextType>({
  testMode: testMode,
  book: initBook,
  multiBooks: [],
  multiBookUids: [],
  // holidays: [],
  updateBook: () => {},
  updateNets: () => {},
  updateBookDrive: () => {},
  updateCompanion: () => {},
  updatePatient: () => {},
  updateGuardian: () => {},
  updatePaymentInfo: () => {},
  updateBookManager: () => {},
  updateMultiBookUids: () => {},
});

export const useBookContext = () => useContext(BookContext);

type ActionType =
  | { type: "UPDATE-BOOK"; book: BookState }
  | { type: "UPDATE-NETS"; nets: NetsService }
  | { type: "UPDATE-ADDR"; address: BookDrive }
  | { type: "UPDATE-COMPANION"; companion: Companion }
  | { type: "UPDATE-PATIENT"; patient: Patient }
  | { type: "UPDATE-GUARDIAN"; guardian: Guardian }
  | { type: "UPDATE-PAYMENT-INFO"; paymentInfo: PaymentInfo; uid: Uid }
  | { type: "UPDATE-BOOK-MANAGER"; bookManager: BookManager };

const reducer = (book: BookState, action: ActionType): BookState => {
  switch (action.type) {
    case "UPDATE-BOOK":
      return { ...action.book };
    case "UPDATE-NETS":
      return { ...book, nets: action.nets };
    case "UPDATE-ADDR":
      return { ...book, bookDrive: action.address };
    case "UPDATE-COMPANION":
      return { ...book, companion: action.companion };
    case "UPDATE-PATIENT":
      return { ...book, patient: action.patient };
    case "UPDATE-GUARDIAN":
      return { ...book, guardian: action.guardian };
    case "UPDATE-PAYMENT-INFO":
      return {
        ...book,
        paymentInfo: action.paymentInfo,
        uid: action.uid,
      };
    case "UPDATE-BOOK-MANAGER":
      return {
        ...book,
        bookManager: action.bookManager,
      };

    default:
      return book;
  }
};

export const BookProvider = ({ children }: PropsWithChildren) => {
  const [book, dispatch] = useReducer(reducer, testMode ? testInit : initBook);
  // const [holidays, setHolidays] = useState<string[]>([]);
  const [multiBooks, setMultiBooks] = useState<BookState[]>([]);
  const [multiBookUids, setMultiBookUids] = useState<string[]>([]);

  // useEffect(() => {
  // getHolidays();
  // }, []);

  // const getHolidays = () => {
  //   const apiKey = `KakaoAK ${process.env.REACT_APP_KAKAO_ADMIN_KEY}`;

  //   const today = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
  //   let holidayList: string[] = [];

  //   for (let i = 0; i < 3; i++) {
  //     let startDate = today
  //       .add(30 * i + 1, "day")
  //       .format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     let endDate = today
  //       .add(30 * (i + 1), "day")
  //       .format("YYYY-MM-DDTHH:mm:ss[Z]");

  //     let url = `https://kapi.kakao.com/v2/api/calendar/holidays?from=${startDate}&to=${endDate}`;
  //     axios
  //       .get(url, { headers: { Authorization: apiKey } })
  //       .then((response) => {
  //         const data = response.data.events;
  //         const datas: string[] = data
  //           .filter((item: RawData) => item.holiday)
  //           .map((item: RawData) => dayjs(item.time.start_at).format("MMDD"));

  //         holidayList.push(...datas);
  //       });
  //   }

  //   setHolidays(holidayList);
  // };

  const updateBook = (book: BookState) => {
    dispatch({ type: "UPDATE-BOOK", book });
  };

  const updateNets = (nets: NetsService) => {
    dispatch({ type: "UPDATE-NETS", nets });
  };

  const updateAddr = (address: BookDrive) => {
    dispatch({ type: "UPDATE-ADDR", address });
  };

  const updateCompanion = (companion: Companion) => {
    dispatch({ type: "UPDATE-COMPANION", companion });
  };

  const updatePatient = (patient: Patient) => {
    dispatch({ type: "UPDATE-PATIENT", patient });
  };

  const updateGuardian = (guardian: Guardian) => {
    dispatch({ type: "UPDATE-GUARDIAN", guardian });
  };

  const updatePaymentInfo = (paymentInfo: PaymentInfo, uid: Uid) => {
    dispatch({ type: "UPDATE-PAYMENT-INFO", paymentInfo, uid });
  };

  const updateBookManager = (bookManager: BookManager) => {
    dispatch({ type: "UPDATE-BOOK-MANAGER", bookManager });
  };

  const updateMultiBookUids = (uids: string[], books: BookState[]) => {
    setMultiBooks(books);
    setMultiBookUids(uids);
  };

  // console.log(book);
  // useEffect(() => {
  //   console.log(book);
  // }, [book]);

  return (
    <BookContext.Provider
      value={{
        testMode: testMode,
        book: book,
        multiBooks,
        multiBookUids,
        // holidays,
        updateBook,
        updateNets,
        updateBookDrive: updateAddr,
        updateCompanion,
        updatePatient,
        updateGuardian,
        updatePaymentInfo,
        updateBookManager,
        updateMultiBookUids,
      }}
    >
      {children}
    </BookContext.Provider>
  );
};
