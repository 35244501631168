import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";

const MyPage = () => {
  const {
    isPC,
    state: { state },
  } = useAuth();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleChange = (index: number) => {
    setIndex(index);
    switch (index) {
      case 0:
        navigate("userInfo");
        break;
      case 1:
        navigate("patientInfo");
        break;
      case 2:
        navigate("addressBook");
        break;
      case 3:
        navigate("coupon");
        break;

      default:
        navigate("userInfo");
        break;
    }
  };

  useEffect(() => {
    if (state !== "signed-in") navigate("/auth", { replace: true });
  }, [state]);

  return (
    <>
      {state === "signed-in" && (
        <>
          <Box my={1} ml={1}>
            <Tabs
              value={index}
              visibleScrollbar={true}
              sx={{
                "& .MuiTabs-flexContainer": {
                  whiteSpace: "nowrap",
                  overflowX: "auto",
                  scrollbarWidth: "none",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label="회원 정보" onClick={() => handleChange(0)} />
              <Tab label="이용자 정보" onClick={() => handleChange(1)} />
              <Tab label="주소록 정보" onClick={() => handleChange(2)} />
              <Tab label="쿠폰 목록" onClick={() => handleChange(3)} />
            </Tabs>
          </Box>
          <Outlet />
        </>
      )}
    </>
  );
};

export default MyPage;
