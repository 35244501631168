import { Box, Typography, Button } from "@mui/material";
import { CollapseMessage } from "../../components/collapseMessage";
import { useForm } from "react-hook-form";
import { Input } from "../../components/muiInput";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { Loading } from "../../components/loading";

export const ReAuthPage = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>();
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<{ password: string }>({
    defaultValues: {
      password: "",
    },
  });

  const checkPassword = async ({ password }: { password: string }) => {
    setMessage(undefined);

    if (password.length < 6) {
      setMessage("비밀번호는 6자 이상 입력해주세요.");
      return;
    }

    const auth = getAuth();
    if (auth.currentUser && auth.currentUser.email) {
      const authCredential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      // 로그인이 오래되었을 경우 auth 재인증을 해야 함
      await reauthenticateWithCredential(auth.currentUser, authCredential)
        .then(() => {
          if (auth.currentUser) {
            navigate("/cancelMembership", { replace: true });
          } else {
            setMessage("비밀번호가 틀렸습니다.");
          }
        })
        .catch((error: FirebaseError) => {
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case "auth/user-not-found":
                setMessage("등록되지 않은 아이디입니다.");
                break;
              case "auth/wrong-password":
              case "auth/requires-recent-login": // 여기서 auth 재인증이 떴다는건 입력한 기존 비밀번호가 틀렸다는 것
                setMessage("비밀번호가 틀렸습니다.");
                break;
              case "auth/invalid-email":
                setMessage("잘못된 이메일 형식입니다.");
                break;
              case "auth/network-request-failed":
                setMessage("네트워크 연결에 실패 하였습니다.");
                break;
              case "auth/internal-error":
                setMessage("잘못된 요청입니다.");
                break;

              default:
                setMessage("비밀번호가 틀렸습니다.");
            }
          }
        });
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"90vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Loading open={isSubmitting} />
      <form onSubmit={handleSubmit(checkPassword)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>
            회원 인증을 위해 비밀번호를 한번 더 입력해주세요
          </Typography>
          <Input
            name={"password"}
            control={control}
            textFieldProps={{
              type: "password",
              label: "비밀번호",
              sx: { width: "20rem" },
            }}
            rules={{
              required: { value: true, message: "비밀번호를 입력해주세요." },
              minLength: {
                value: 6,
                message: "비밀번호를 입력해주세요",
              },
            }}
          />

          <CollapseMessage
            open={message !== undefined}
            type={"warning"}
            message={message || ""}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 1, mb: 2, width: "20rem" }}
          />

          <Button
            type="submit"
            size="large"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            sx={{ width: "20rem" }}
          >
            회원 인증
          </Button>
        </Box>
      </form>
    </Box>
  );
};
