import axios from "axios";
import { RefundType } from "../model/refundType";
import { urls } from "../model/constants";

export const onGetCancelFee = async (uid: string) => {
  return new Promise<RefundType>((resolve, reject) => {
    axios({
      url: urls.onGetCancelFee,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          if (data.status !== "success") throw "error";

          resolve(data.fee as RefundType);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
