import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TypographyProps,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { MouseEvent, useState } from "react";

export const Header = () => {
  const {
    isPC,
    state: { state },
    signOut,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuEl);

  const menuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const menuClose = () => {
    setMenuEl(null);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={isPC ? "center" : "flex-start"}
      alignItems={"center"}
      gap={isPC ? 4 : 1}
      p={1}
      top={0}
      bgcolor={"white"}
      zIndex={999}
      position={"sticky"}
      borderBottom={"1px solid #eee"}
    >
      {isPC ? (
        <Box
          minWidth={isPC ? "691px" : "90%"}
          width={isPC ? "60%" : "90%"}
          maxWidth={isPC ? "960px" : "690px"}
          display={"flex"}
          alignItems={"center"}
          gap={4}
        >
          <img
            src={"/images/header_logo.png"}
            alt="logo"
            onClick={() => navigate("/")}
          />
          <Box
            display={"flex"}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              "& > p, & > div > p": {
                textDecoration: "none",
                // color: "#43464d",
                fontSize: "1rem",
                fontFamily: '"Noto Sans KR", sans-serif',
                fontWeight: "500",
                cursor: "pointer",
                ":hover": { color: "commonColor.main" },
                ":active": { color: "#1e7589" },
              },
            }}
          >
            <Box display={"flex"} gap={3}>
              <HighlightedText
                highlight={location.pathname === "/"}
                onClick={() => navigate("/")}
              >
                서비스 소개
              </HighlightedText>
              <HighlightedText
                highlight={location.pathname.includes("/infos")}
                onClick={() => navigate("/infos/price")}
              >
                이용 안내
              </HighlightedText>

              <HighlightedText
                highlight={
                  location.pathname.includes("/bookRouter") ||
                  location.pathname.includes("/findService") ||
                  location.pathname.includes("/reservation") ||
                  location.pathname.includes("/manageBooks")
                }
                onClick={() =>
                  navigate(state === "signed-in" ? "/bookRouter" : "/auth")
                }
              >
                예약
              </HighlightedText>

              <HighlightedText
                highlight={location.pathname.includes("/board")}
                onClick={() => navigate("/board")}
              >
                고객의 소리
              </HighlightedText>
              <HighlightedText
                highlight={location.pathname === "/family"}
                onClick={() => navigate("/family")}
              >
                패밀리사이트
              </HighlightedText>
            </Box>
            {state === "signed-in" ? (
              <>
                <IconButton onClick={menuClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={menuEl}
                  open={menuOpen}
                  onClose={menuClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  sx={{
                    "& .MuiPaper-root": {
                      minWidth: 150,
                      alignItems: "end",
                    },
                    "& .MuiPaper-root > ul > li": {
                      justifyContent: "end",
                      fontFamily: '"Noto Sans KR", sans-serif',
                      fontWeight: "500",
                      cursor: "pointer",
                      ":hover": { color: "#2faec9" },
                      ":active": { color: "#1e7589" },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setMenuEl(null);
                      navigate("/mypage/userInfo");
                    }}
                  >
                    마이페이지
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMenuEl(null);
                      signOut();
                      navigate("/", { replace: true });
                    }}
                  >
                    로그아웃
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Typography onClick={() => navigate("/auth")}>로그인</Typography>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <IconButton onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Box
            display={"flex"}
            gap={3}
            whiteSpace={"nowrap"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <HighlightedText
              highlight={location.pathname === "/"}
              onClick={() => navigate("/")}
            >
              서비스 소개
            </HighlightedText>
            <HighlightedText
              highlight={location.pathname.includes("/infos")}
              onClick={() => navigate("/infos/price")}
            >
              이용 안내
            </HighlightedText>

            <HighlightedText
              highlight={
                location.pathname.includes("/bookRouter") ||
                location.pathname.includes("/findService") ||
                location.pathname.includes("/reservation") ||
                location.pathname.includes("/manageBooks")
              }
              onClick={() =>
                navigate(state === "signed-in" ? "/bookRouter" : "/auth")
              }
            >
              예약
            </HighlightedText>

            <HighlightedText
              highlight={location.pathname === "/board"}
              onClick={() => navigate("/board")}
            >
              고객의 소리
            </HighlightedText>
            <HighlightedText
              highlight={location.pathname === "/family"}
              onClick={() => navigate("/family")}
              sx={{ mr: 2 }}
            >
              패밀리사이트
            </HighlightedText>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiPaper-root": { minWidth: 150, alignItems: "end" },
              "& .MuiPaper-root > ul > li": {
                justifyContent: "end",
                fontFamily: '"Noto Sans KR", sans-serif',
                fontWeight: "500",
                cursor: "pointer",
                ":hover": { color: "#2faec9" },
                ":active": { color: "#1e7589" },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/");
              }}
            >
              서비스 소개
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/infos");
              }}
            >
              이용 안내
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate(state === "signed-in" ? "/bookRouter" : "/auth");
              }}
            >
              예약
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/board");
              }}
            >
              고객의 소리
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/family");
              }}
            >
              패밀리 사이트
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/mypage/userInfo");
              }}
            >
              마이페이지
            </MenuItem>
            {state === "signed-in" ? (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  signOut();
                  navigate("/", { replace: true });
                }}
              >
                로그아웃
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/auth");
                }}
              >
                로그인
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};

type HighlightedTextProps = TypographyProps & {
  highlight: boolean;
};
const HighlightedText = ({ highlight, ...props }: HighlightedTextProps) => {
  return (
    <Typography color={highlight ? "commonColor.main" : "#43464d"} {...props} />
  );
};
