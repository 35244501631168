import { Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./404";
import {
  MainContainer,
  InfoContainer,
  InfoNoticeContainer,
  BookContainer,
  VocContainer,
} from "./components/container";
import { AuthProvider } from "./context/AuthContext";
import { BookProvider } from "./context/BookContext";
import {
  SignIn,
  SignUp,
  ForgotPassword,
  ForgotSendEamil,
  Verify,
} from "./pages/auth";
import { FindService, Menu, PrevBookLists } from "./pages/book";
import BookRouter from "./pages/book/bookRouter";
import {
  FindCarMulti,
  FindCarMultiResultPage,
  CheckReservationMulti,
  ResultMulti,
} from "./pages/book/multi";
import {
  Nets,
  Address,
  Companion,
  CheckInfo,
  FindCar,
  FindCarResultPage,
  PatientPage,
  Guardian,
  CheckReservation,
  Result,
} from "./pages/book/single";
import VerifyPage from "./pages/book/verify";
import VerifyWrongPage from "./pages/book/verifyWrong";
import FamilySitePage from "./pages/familySite";
import {
  InfoPricePage,
  InfoTermsPage,
  InfoNoticeListPage,
  InfoNoticeDetailPage,
} from "./pages/infos";
import IntroducePage from "./pages/introduce";
import {
  UserInfoPage,
  UserInfoModifyPage,
  CouponListPage,
  ManageBookPostponePage,
  ManageBookPostponeDonePage,
  ManageBookCancelPage,
  ManageBookCancelledPage,
  ManageBookCancelWaitingPage,
  ReAuthPage,
  CancelMembership,
} from "./pages/mypage";
import AddressBookPage from "./pages/mypage/addressBook";
import AddressBookModifyPage from "./pages/mypage/addressBookModify";
import ChangePasswordPage from "./pages/mypage/changePassword";
import ChangePasswordDonePage from "./pages/mypage/changePasswordDone";
import ManageBooksPage from "./pages/mypage/manageBooks";
import ManageBooksDetailPage from "./pages/mypage/manageBooksDetail";
import MyPage from "./pages/mypage/myPage";
import PatientInfoPage from "./pages/mypage/patientInfo";
import PatientInfoModifyPage from "./pages/mypage/patientInfoModify";
import { VocReadPage, VocWritePage } from "./pages/voc";
import VocListPage from "./pages/voc/vocList";
import { SignUpDone } from "./pages/auth/signupDone";

const Auth = () => (
  <AuthProvider>
    <BookProvider>
      <Outlet />
    </BookProvider>
  </AuthProvider>
);

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      element: <Auth />,
      errorElement: <NotFound />,
      children: [
        {
          path: "/",
          element: <MainContainer />,
          children: [
            { index: true, element: <IntroducePage /> },
            {
              path: "auth",
              children: [
                { index: true, element: <SignIn /> },
                { path: "signup", element: <SignUp /> },
                { path: "signupDone", element: <SignUpDone /> },
                { path: "forgot", element: <ForgotPassword /> },
                { path: "forgotSendEmail", element: <ForgotSendEamil /> },
                { path: "verify", element: <Verify /> },
              ],
            },
            {
              path: "infos",
              element: <InfoContainer />,
              children: [
                { path: "price", element: <InfoPricePage /> },
                { path: "terms", element: <InfoTermsPage /> },
                {
                  path: "notice",
                  element: <InfoNoticeContainer />,
                  children: [
                    { index: true, element: <InfoNoticeListPage /> },
                    { path: "detail", element: <InfoNoticeDetailPage /> },
                  ],
                },
              ],
            },
            { path: "bookRouter", element: <BookRouter /> },
            { path: "findService", element: <FindService /> },
            {
              path: "reservation",
              element: <BookContainer />,
              children: [
                // { index: true, element: <TermsOfReservation /> },
                // { path: "menu", element: <Menu /> },
                { index: true, element: <Menu /> },
                { path: "prevBooks", element: <PrevBookLists /> },
                { path: "nets", element: <Nets /> },
                { path: "address", element: <Address /> },
                { path: "companion", element: <Companion /> },
                { path: "checkinfo", element: <CheckInfo /> },
                { path: "findcar", element: <FindCar /> },
                { path: "findcarResult", element: <FindCarResultPage /> },
                { path: "findcarMulti", element: <FindCarMulti /> },
                {
                  path: "findcarMultiResult",
                  element: <FindCarMultiResultPage />,
                },
                { path: "patient", element: <PatientPage /> },
                { path: "guardian", element: <Guardian /> },
                { path: "checkReservation", element: <CheckReservation /> },
                {
                  path: "checkReservationMulti",
                  element: <CheckReservationMulti />,
                },
                { path: "verify", element: <VerifyPage /> },
                { path: "verifyWrong", element: <VerifyWrongPage /> },
                { path: "result", element: <Result /> },
                { path: "resultMulti", element: <ResultMulti /> },
              ],
            },

            {
              path: "board",
              element: <VocContainer />,
              children: [
                { index: true, element: <VocListPage /> },
                { path: "read", element: <VocReadPage /> },
                { path: "write", element: <VocWritePage /> },
              ],
            },
            {
              path: "family",
              element: <FamilySitePage />,
            },
            // {
            //   path: "test",
            //   element: <TestPage />,
            // },
            // {
            //   path: "test2",
            //   element: <TestNext />,
            // },

            {
              path: "mypage",
              element: <MyPage />,
              children: [
                { path: "userInfo", element: <UserInfoPage /> },
                { path: "userInfo/modify", element: <UserInfoModifyPage /> },
                { path: "changePassword", element: <ChangePasswordPage /> },
                { path: "patientInfo", element: <PatientInfoPage /> },
                {
                  path: "patientInfo/modify",
                  element: <PatientInfoModifyPage />,
                },
                { path: "addressBook", element: <AddressBookPage /> },
                {
                  path: "addressBook/modify",
                  element: <AddressBookModifyPage />,
                },
                {
                  path: "coupon",
                  element: <CouponListPage />,
                },
              ],
            },
            {
              path: "manageBooks",
              element: <ManageBooksPage />,
            },
            {
              path: "manageBooks/detail",
              element: <ManageBooksDetailPage />,
            },

            {
              path: "manageBooks/postpone",
              element: <ManageBookPostponePage />,
            },
            {
              path: "manageBooks/postponeDone",
              element: <ManageBookPostponeDonePage />,
            },

            {
              path: "manageBooks/cancel",
              element: <ManageBookCancelPage />,
            },
            {
              path: "manageBooks/cancelled",
              element: <ManageBookCancelledPage />,
            },
            {
              path: "manageBooks/cancelWaiting",
              element: <ManageBookCancelWaitingPage />,
            },

            {
              path: "changePasswordDone",
              element: <ChangePasswordDonePage />,
            },
            {
              path: "reauth",
              element: <ReAuthPage />,
            },
            {
              path: "cancelMembership",
              element: <CancelMembership />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
